﻿﻿/* ==========================================================================
   #MODAL
   ========================================================================== */

/**
 * A custom modal window component. Requires js method `res_modal` plugin.
 *
 * 1. Keeps the modal window above the web page, and fixed in place when the
 *    user is scrolling.
 * 2. Default transition.
 * 3. Center the modal vertically and horzontally.
 */

 .c-modal {
  position: fixed; /* [1] */
  z-index: 9999;
  top: 50%;
  left: 50%;
  width: 94%;
  border-radius: $global-unit-small;
  background-color: $color-grey5;
  box-shadow: 0 0 10px rgba($color-black, 0.4);
  opacity: 0;
  overflow: hidden;
  transition: 1ms opacity ease; /* [2] */
  @include translate(-50%, -50%); /* [3] */


  @include mq($from: tablet) {
    margin: 0 auto;
  }

  &.is-active {
    opacity: 1;
  }

}

$c-modal-close-btn-size:            24px !default;
$c-modal-close-btn-top:             $global-unit-small !default;
$c-modal-close-btn-right:           $global-unit-small !default;
$c-modal-close-btn-color:           $html-text-color !default;
$c-modal-close-btn-hover-color:     $color-black !default;
$c-modal-close-btn-hover-bgcolor:   $neutral-xlight !default;





// Modal overlay which sits on top of the web page but behind the modal.
.c-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba($color-black, 0.85);
  z-index: 9998;
  opacity: 0;
  transition: 250ms opacity ease;


  &.is-active {
    opacity: 1;
    transition: 250ms opacity ease;
  }

}



.c-modal__close {
  position: absolute;
  top:   $c-modal-close-btn-top;
  right: $c-modal-close-btn-right;
  width:  $c-modal-close-btn-size;
  height: $c-modal-close-btn-size;
  border: 0;
  background-color: transparent;
  @include appearance(none);
  z-index: 100;

  @include all-anchor-states() {
    text-decoration: none;
  }

  &:before {
    @include position-center();
    display: block;
    width:  100%;
    height: 100%;
    @include svg-bg-img($svg-close-btn, $c-modal-close-btn-size, $c-modal-close-btn-size, $c-modal-close-btn-color);
  }

  &:after {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
    z-index: -1;
    bottom:  0;
    left:    0;
    right:   0;
    top:     0;
    background: none;
    border-radius: 50%;
    box-sizing: border-box;
    transform: scale(0);
    transition-property: transform,opacity;
  }


  @include hocus() {
    cursor: pointer;

    &:before {
        @include svg-bg-img($svg-close-btn, $c-modal-close-btn-size, $c-modal-close-btn-size, $c-modal-close-btn-hover-color);
    }
    &:after {
      background-color: $c-modal-close-btn-hover-bgcolor;
      border: none;
      box-shadow: none;
      opacity: 1;
      transform: scale(1);
    }
  }

}




/**
 * Scrollable content area of the modal window.
 *
 * 1. Add space between the body and teh modal window to make room for
 *    the close button.
 * 2. Set a max-height so that scrolling can occur.
 * 3. Show vertical scrollbar when content exceeds max-height.
 */
.c-modal__body {
  margin: 0;
  margin-top: $global-unit-large; /* [1] */
  margin-bottom: 0; //$global-unit;
  padding-right: $global-unit;
  padding-left: $global-unit;
  //max-height: 440px; __<<ngThemingMigrationEscapedComment7>>__
  overflow-y: auto; /* [3] */

  @include mq($until: tablet) {
    @include font-size(14px);
  }

  //@include mq($from: $bp-375) {
  //  max-height: 544px; __<<ngThemingMigrationEscapedComment9>>__
  //}
  //
  //@include mq($from: tablet) {
  //  max-height: 720px; __<<ngThemingMigrationEscapedComment10>>__
  //}

  > :last-child {
    margin-bottom: 0;
  }

}



// Modal header - optional setting in res_modal()
.c-modal__header {
  margin: 0;
  margin-bottom: $global-unit;
  padding: $global-unit-large $global-unit-large $global-unit $global-unit;

  + .c-modal__body {
    margin-top: 0;
  }

}

  .c-modal__heading {
    position: relative;
    margin-bottom: 0;
    @include font-size(24px, 1.25);
    font-weight: $font-weight-light;

    .c-svg {
      top: 0.125em;
      margin-right: $global-unit-small;
      @include font-size(24px, 0);

      @include mq($from: tablet) {
        margin-right: $global-unit;
      }

    }

    &.has-icon {
      padding-left: 36px;

      .c-svg {
        position: absolute;
        left: 0;
      }
    }
  }



// Modal footer - optional setting in res_modal()
.c-modal__footer {
  padding: $global-unit-large $global-unit $global-unit;
  margin-bottom: $global-unit;
}




/* Style variants
   ========================================================================== */
.c-modal,
.c-modal-material {

  &.c-modal--confirm {

    @include mq($from: tablet) {
      .c-modal__footer {
        text-align: right;

        > .o-layout > .o-layout__item {
          margin-bottom: 0;
        }

      }
    }

  }


  &.c-modal--anchored {

    &.is-active {
      top: 20px !important;
      @include translate(-50%, 0);
    }

  }

}



// 1. Body content is only an image and we want the image to fill the width of the window.
.c-modal--img-only {

    .c-modal__body {
        padding-bottom: $global-unit;
        padding-right: 0; /* [1] */
        padding-left: 0; /* [1] */
        text-align: center;
        max-height: 440px;

        @include mq($from: $bp-375) {
          max-height: 544px;
        }

        @include mq($from: tablet) {
          max-height: 720px;
        }


        > img {
            display: inline-block;
            max-width: 100%;
            height: auto;
            line-height: 0;
            margin: 0 auto;
            padding: 0;
        }

    }

}




// Using with Material dialog component
.c-modal-material {
  position: relative;
  // max-width: 600px;

  @include mq($from: tablet) {
    min-width: 280px;
  }

  .c-modal__close {
    top:   -36px;
    right: -12px;
  }

  .c-modal__body {
    // max-height: 86vh;
    margin-top: $global-unit;
    padding-right: 0;
    padding-left:  0;
  }

  .c-modal__header {
    padding: 0;

    + .c-modal__body {
      margin-top: 0;
    }

  }

  .c-modal__footer {
    padding: $global-unit-large 0 0;
    margin-bottom: 0;
  }

}



/* Animation variants
   ========================================================================== */
/**
 * Default animation
 *
 * 1. Hide the modal window from view.
 * 2. Overlay background color and opacity setting.
 * 3. Allows us to control vertical alignments. This way we can have the
 *    `c-modal__window` vertically centered on the screen without positioning.
 * 4. Keeps the modal window above the web page, and fixed in place when the
 *    user is scrolling.
 * 5. Fade in and reveal the modal window.
 * 6. Fade out and hide the modal window.
 */

.c-modal--fade-in-top {

  &.c-modal {
    top: 40%;
    opacity: 0;
    display: block;
    transition:  250ms top ease, 250ms opacity ease;

    &.is-active {
      top: 50%;
      opacity: 1;
      transition: 500ms top 500ms ease, 500ms opacity 500ms ease;
    }

  }

}


// Pop the modal windwo in from the center
.c-modal--pop-in {

  &.c-modal {
    @include transform(scale(1) translate(-50%, -50%));
    @include transform-origin(0% 0%);
    transition: 250ms transform ease, 250ms opacity ease;

    &.is-active {
      opacity: 1;
      @include animation(aniPopIn 250ms);
      will-change: transform;
    }

  }

}




// Overriding inline style block for terms/privacy/cookie content
// .c-modal-material,
#privacynotice,
#cookieNotice,
#termsOfServiceLegal {

  h1,
  h2,
  h3 {

    &,
    a,
    span {
      font-family: $font-family-cern !important;
      margin: 0 !important;
      margin-bottom: $global-unit !important;
      color: $html-text-color !important;
      font-weight: $font-weight-bold !important;
    }

    // This content used to be an expander, but we can't support that in this new set up.
    // So we need to hide the `+` which is hardcoded in the content and force the exanpder
    // container to be open.
    &.plusMinusSign {
      display: block;
      // Override the the inline style of `cursor: pointer;` hardcoded in the content.
      cursor: default !important;

      // "Remove" the space between the `+` and the the actual first letter of the heading.
      // May not align exactly everywhere.
      text-indent: -5px; // rough estimate of the width of a space.

      // Hiding the `+` sign that is hardcoded in the content.
      &::first-letter {
        font-size: 0;
      }

    }
  }

  h1 {
    &,
    a,
    span {
      @include font-size(24px, $important: true);

      @include mq($from: tablet) {
        @include font-size(32px, $important: true);
      }
    }
  }

  h2 {
    &,
    a,
    span {
      @include font-size(20px, $important: true);

      @include mq($from: tablet) {
        @include font-size(24px, $important: true);
      }
    }
  }

  h3 {
    &,
    a,
    span {
      @include font-size(18px, $important: true);

      @include mq($from: tablet) {
        @include font-size(20px, $important: true);
      }
    }
  }

  // Force expander container to be visible since we cannot support
  // this legacy expander functionality.
  .expandableParagraph {
    display: block !important;
  }

}

#privacynotice table td {
  border: 1px solid #000;
}
