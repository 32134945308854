@import 'global-styles/settings/settings.typography';

.dark-mode .mat-dialog-container {
    background: #000000;
}

  /* REBRANDING 2025 */
body {
    font-family: $html-font-family;
  }
  
  /* REBRANDING 2025 */
  h1 {
    font-family: $h1-font-family;
    font-weight: $h1-font-weight;
  }
  

@media  (min-width: 866px) {
    .pta-dialog .mat-dialog-container {
        position: relative;
        min-width: 865px;
        padding: 0px;
      }
}

@media  (max-width: 865px) {
    .pta-dialog .mat-dialog-container {
        position: relative;
        padding: 0px;
        max-width: 400px;
      }


}

.flex {
    display: flex;
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
}

.center {
    justify-content: center;
    align-items: center;
}
