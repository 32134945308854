/* ==========================================================================
   #LOGOS
   ========================================================================== */

/**
 * A Place to keep and size all your logos according to where they are being
 * displayed.
 */

.c-logo {
  display: inline-block;

  img,
  .c-svg {
    width: 100%;
    height: 100%;
  }

}


.c-logo--resmed {
  width:  65px;
  height: 49px;
  
  @include mq($from: tablet) {
    width: 91px;
    height: 68px;
  }

  .page-footer & {
    width:  52px;
    height: 40px;
  }

  .page-head & {
    margin-bottom: 3px;

    @include mq($from: tablet) {
      margin-bottom: 6px;
    }
  }

}

.c-logo--myair {
  width: 60px; //51px;
  height: 24px; //19px;

  @include mq($from: tablet) {
      width: 85px; //65px;
      height: 32px; //24px
  }

  svg {
      width: 100%;
      height: 100%;
  }

}