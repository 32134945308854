﻿/* ==========================================================================
   #HOME
   ========================================================================== */
// Home page components


/* Login form
   ========================================================================== */
.c-login-form {
  display: block;

  .c-btn {
    margin-bottom: $global-unit-small;
  }

  .c-form-field {
    margin-bottom: $global-unit;
  }

}


.c-login-form_header {
  text-align: center;

  @include mq($from: tablet) {
    text-align: left;
  }

  a {
    display: block;

    &:visited {
      color: $a-link-color !important;
    }

    @include mq($from: tablet) {
      display: inline-block;
    }
  }

}







/* myAir Features section of the home page
   ========================================================================== */
/**
 * 1. So stupid IE won't add extra space to the `flexbody__page` because it 
 *    can't deal with flexbody and 100% width images.
 */
.c-features {
  display: block;
  margin: 0 auto;

  @include mq($from: desktop) {
    width: 80%;
  }

}

.c-features__item {
  text-align: center;

  .c-heading-main {
    margin-bottom: $global-unit-small;
    @include font-size(24px, 1.1, true);

    @include mq($from: desktop) {
      @include font-size(28px, 1.1, true);
    }
  }

}


.c-features__img {
  position: relative;
  margin-bottom: $global-unit-small;
  line-height: 0;

  img {
    width: 100%;
    max-width: 400px; /* [1] */
    height: auto;
  }

}






.c-features__demo-dial {
  @include position-center();
  width:  278px;
  height: 278px;
  z-index: 10;

  @include mq($from: tablet) {
    width:  186px;
    height: 186px;
  }

  @include mq($from: wide) {
    width:  278px;
    height: 278px;
  }

  .demo__myair,
  .demo__points {
    position: absolute;
    left: 0;
    width: 100%;
    @include font-size(16px);
    text-align: center;

    @include mq($from: tablet, $until: wide) {
      @include font-size(12px);
    }

  }

  .demo__myair {
    top: 48px;
    margin-bottom: 0;
    color: $primary-black;

    @include mq($from: tablet, $until: wide) {
      top: 30px;
    }

  }

  .demo__points {
    top: 200px;
    padding-top: $global-unit-tiny;
    margin-bottom: 0;
    color: $neutral-heavy;

    @include mq($from: tablet, $until: wide) {
      top: 128px;
    }

    &:before {
      @include position-x-center();
      width: 125px;
      border-top: 1px solid $neutral-heavy;
      content: "";
      height: 1px;

      @include mq($from: tablet, $until: wide) {
        width: 73px;
      }

    }

  }

}




.c-features__text {
  text-align: center;
}




/* MISC
   ========================================================================== */
// Misc bits
.myair-usage-disclaimer--non-us {
  margin-top: $global-unit-large;
  text-align: center;
  @include font-size(11px);

  &,
  > :last-child {
    margin-bottom: 0;
  }

}


.c-invite-text {

  @include mq($from: tablet) {
    text-align: center;

    ol {
      width: 300px;
      margin: 0 auto $global-unit-large auto;
      text-align: left;
    }

  }

}