/* ==========================================================================
   #FLEXBODY
   ========================================================================== */

/**
 * Use flex method for sticky footer.

  <html>
    <body class="o-flexbody__body">

      <div\form class="o-flexbody__body ">

        <header></header>

        <div class="o-flexbody__page">

            [ PAGE CONTENT GOES HERE ]

        </div>

        <footer></footer>

      </div\form>

    </body>

  </html>

 * Dependecies:
 * Body tag needs `o-flexbody__body` class.

 * Outer wrapper tag needs `o-flexbody__body` class. Some platforms require that all
 * the site content gets inserted into another tag, like a form tag in .NET or
 * and `app-root` tag in Angular2 for example, so we need to double up on our
 * `o-flexbody__body` object class.

 * IE10 requires Modernizr.addTest('flexboxtweener') method to be enabled
*/


.o-flexbody__body {
    position: relative;
    min-height: 100vh;
    //@include display-flex;
    /* Ye Olde Flexbox for Webkit */
    display: -webkit-box;
    /* Tweener flexbox */
    display: -ms-flexbox;
    /* Prefixed "new flexbox" */
    display: -webkit-flex;
    /* unprefixed standard "new flexbox" version */
    display: flex;
    z-index: 1;
    width: 100%;
    /* Your grandparents flexbox for Webkit */
    -webkit-box-orient: vertical;
    /* Prefixed new syntax: */
    -webkit-flex-direction: column;
    /* Same syntax for weird IE tweener */
    -ms-flex-direction: column;
    /* unprefixed new syntax */
    flex-direction: column;
    margin: 0;
    padding: 0;

    .page-head,
    .page-footer,
    .c-notification-pusher,
    .c-notifications-wrapper {
        -webkit-box-flex: 0;
        -webkit-flex: none;
        -ms-flex: none;
        flex: none;
    }
}


.o-flexbody__page {
  position: relative;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  // margin-bottom: $global-unit-huge;
  @include clearfix();
}


// IE10 requires Modernizr.addTest('flexboxtweener') method to be enabled
// in script.js
.flexboxtweener {

  &,
  > body,
  .o-flexbody__body {
    height: 100%;
    min-height: 100%;
  }

}


/* Aligner */
.o-flex-aligner {

  @include mq($from: tablet) {
    display: flex;
    align-items: center;
    min-height: 60vh;
    justify-content: center;

    &.o-flexbody__page--full-height {
      height: 100vh;
    }

    .o-flex-aligner__item {
      flex: 1;
    }

    .o-flex-aligner__item--top {
      align-self: flex-start;
    }

    .o-flex-aligner__item--bottom {
      align-self: flex-end;
    }

    .o-flex-aligner__item--fixed {
      flex: none;
      max-width: 50%;
    }

    /**
    * Fixes the min-height bug in IE10-11.
    * This class should be added to a container element as described in
    * Flexbug #3. See http://bit.ly/1gy8OJS for details.
    */
    .u-ieMinHeightBugFix {
      display: flex;
      flex-direction: column;
    }

  }

}
