/* ==========================================================================
   #PAGE
   ========================================================================== */

$html-bg:   $color-white !default;

/**
 * Related files:
 * `inuitcss/elements/elements.page.scss`
 */

html {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  overflow-x: hidden;
  overflow: -moz-scrollbars-vertical;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba($color-black, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}


body {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;

  // Reposistion the sass-mq show-breakpoint element
  // so it appears in a layer above the page-head.
  &:before {
    z-index: 1099;
  }
}



// Add animation CSS files if we want to use this stuff
/*
.body__page-loading {
  display: none;
}

.body__page-isLoaded {
  @include animation-name(stayHidden, fadeIn);
  @include animation-duration(250ms, 250ms);
  @include animation-delay(0s, 250ms);
  @include animation-timing-function(linear, ease-out);
  @include animation-fill-mode(forwards);
  @include animation-iteration-count(1);
}

*/
