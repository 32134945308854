﻿/* ==========================================================================
   #POPMENU
   ========================================================================== */
//
.c-popmenu-wrapper {
    position: relative;
    @include inuit-clearfix();
}


.c-popmenu {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    @include clearfix();
}


.c-popmenu__item {
    position: relative;

    &:hover {

        .c-popmenu__list {
            opacity: 1;
            visibility: visible;
            will-change: opacity;
        }

        .c-popmenu--icon & {

            .c-svg {
    
                &.icon-default {
                    visibility: hidden;
                    opacity: 0;
                }
        
                &.icon-hover {
                    visibility: visible;
                    opacity: 1;
                }
                
            }

        }
    }

}


.c-popmenu__target {
    position: relative;

    &,
    &:link,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

}


.c-popmenu__list {
    list-style: none;
    margin: 0;

    position: absolute;
    top: 0;
    left: 50%;
    right: auto;
    bottom: auto;
    z-index: 1;
    min-width: 300px;
    padding: $global-unit-small;
    text-align: left;
    background-color: $color-white;
    border-radius: $global-unit-small;
    border: 1px solid $neutral-medium;
    box-shadow: 0px 15px 16px -7px rgba(0, 0, 0, 0.3);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    @include translate(-50%, -90%);
    
    
    @include mq($from: tablet) {
        min-width: 100%;
        padding: $global-unit;
        white-space: nowrap;
    }


    // directional arrows, two overlaid arrows to create outline.
    &:before,
    &:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        top: 100%;
        right: auto;
        bottom: auto;
        left: 50%;
        content: "";
        @include translate(-50%, 0px);
    }
    
    &:before {
        border-top:    10px $color-white solid !important; 
	    border-left:   6px transparent solid !important; 
	    border-right:  6px transparent solid !important;
	    border-bottom: 0; 
        z-index: 51;
    }

    &:after {
        border-top:    12px $neutral-medium solid !important; 
	    border-left:   8px transparent solid !important; 
        border-right:  8px transparent solid !important;
        border-bottom: 0;
        @include translate(-50%, 1px);
        z-index: 50;
    }

    .c-popmenu__item:hover & {
        @include translate(-50%, 102%);
    }

}


.c-popmenu__list-item {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    @include font-size(16px, 1.1);

    p:last-child {
      margin-bottom: 0;
    }

}




/* Popmenu variants
   ========================================================================== */
.c-popmenu--icon {
    display: inline-block;

    .c-popmenu__target {
        position: relative;
        display: block;
        width:  32px;
        height: 32px;
        line-height: 0;
        
        // @include mq($from: tablet) {
        //     width:  42px;
        //     height: 42px;
        // }

    }

}


.c-popmenu-wrapper--score {

    .c-popmenu {

        position: absolute;
        top: -$global-unit-large;
        right: 0;
        left: auto;
        bottom: auto;

        @include mq($from: tablet) {
            top: $global-unit-small;
        }

    }

    .c-popmenu--left {

        .c-popmenu__list {
            width: 300px;

            @include mq($from: tablet) {
                width: 360px;
                left: -360px;
                @include translate(0, -50%);

            }

        }

        .c-popmenu__item:hover .c-popmenu__list {
            @include mq($from: tablet) {
                @include translate(-5px, -50%);
            }
        }

    }

}



/* Directional variants
   ========================================================================== */
// Top (default)
.c-popmenu--top {

    .c-popmenu__list {
        position: absolute;
        top: 0;
        left: 50%;
        right: auto;
        bottom: auto;
        @include translate(-50%, -90%);
        
        // Directional arrows
        &:before,
        &:after {
            top: 100%;
            right: auto;
            bottom: auto;
            left: 50%;
            @include translate(-50%, 0);
        }

        &:before {
            border-top:    10px $color-white solid !important; 
            border-left:   6px transparent solid !important; 
            border-right:  6px transparent solid !important;
            border-bottom: 0 !important;
        }
    
        &:after {
            border-top:    11px $neutral-medium solid !important; 
            border-left:   7px transparent solid !important; 
            border-right:  7px transparent solid !important;
            border-bottom: 0 !important;
            @include translate(-50%, 1px);
        }
    }

    .c-popmenu__item:hover .c-popmenu__list {
        @include translate(-50%, -102%);
    }


}


.c-popmenu--bottom {

    .c-popmenu__list {
        position: absolute;
        top: 100%;
        left: 50%;
        @include translate(-50%, -10%);
        
        // Directional arrows
        &:before,
        &:after {
            top: 0;
            right: auto;
            bottom: auto;
            left: 50%;
            @include translate(-50%, -10px);
        }

        &:before {
            border-bottom: 10px $color-white solid !important; 
            border-left:   6px transparent solid !important; 
            border-right:  6px transparent solid !important;
            border-top:    0 !important;
        }
    
        &:after {
            border-bottom: 11px $neutral-medium solid !important; 
            border-left:   7px transparent solid !important; 
            border-right:  7px transparent solid !important;
            border-top:    0 !important;
            @include translate(-50%, -11px);
        }

    }

    .c-popmenu__item:hover .c-popmenu__list {
        @include translate(-50%, 2%);
    }

}


.c-popmenu--left {

    .c-popmenu__list {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 0;
        @include translate(-95%, -50%);


        // directional arrows
        &:before,
        &:after {
            top: 50%;
            right: 0;
            bottom: auto;
            left: auto;
            @include translate(16px, -50%);
        }

        &:before {
            border-bottom: 6px transparent solid !important;
            border-right:  6px transparent solid !important;
            border-left:   10px $color-white solid !important;
            border-top:    6px transparent solid !important;
        }
    
        &:after {
            border-bottom: 7px transparent solid !important;
            border-right:  7px transparent solid !important;
            border-left:   11px $neutral-medium solid !important;
            border-top:    7px transparent solid !important;
            @include translate(18px, -50%);
        }
        
    }

    .c-popmenu__item:hover .c-popmenu__list {
        @include translate(-101%, -50%);
    }

}


.c-popmenu--right {

    .c-popmenu__list {
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        @include translate(95%, -50%);


        // directional arrows
        &:before,
        &:after {
            top: 50%;
            right: auto;
            bottom: auto;
            left: 0;
            @include translate(-16px, -50%);
        }

        &:before {
            border-bottom: 6px transparent solid !important;
            border-right:  10px $color-white solid !important;
            border-left:   6px transparent solid !important;
            border-top:    6px transparent solid !important;
        }
    
        &:after {
            border-bottom: 7px transparent solid !important;
            border-right:  11px $neutral-medium solid !important;
            border-left:   7px transparent solid !important;
            border-top:    7px transparent solid !important;
            @include translate(-18px, -50%);
        }
        
    }

    .c-popmenu__item:hover .c-popmenu__list {
        @include translate(101%, -50%);
    }

}