/* ==========================================================================
   #BUTTONS
   ========================================================================== */

$c-btn-height:                          $input-field-height !default;
$c-btn-icon-size:                       $global-icon-size !default;
$c-btn-font-size:                       $global-font-size !default;
$c-btn-font-weight:                     $font-weight-bold !default;
$c-btn-letter-spacing:                  1px !default;
$c-btn-radius:                          $button-pill-radius !default; // make button pill shaped;
$c-btn-opacity-disabled:                $opacity-disabled !default;

$c-btn-focus-outline-style:             solid !default;
$c-btn-focus-outline-size:              3px !default;
$c-btn-focus-outline-opacity:           0.28 !default;

$c-btn-short:                           120px !default;
$c-btn-wide:                            240px !default;
$c-btn-min-width:                       $button-min-width !default;
$c-btn-max-width-mobile:                480px !default;

$c-btn-padding-y-mobile:                9px !default;
$c-btn-padding-x-mobile:                $global-unit !default;
$c-btn-padding-y:                       0 !default;
$c-btn-padding-x:                       $global-unit-large !default;
$c-btn-padding-icon:                    $c-btn-padding-x + $c-btn-icon-size + $global-unit-small !default;

$c-btn-primary-text-color:              $color-white !default;
$c-btn-primary-bgcolor:                 $primary-blue !default;
$c-btn-primary-hover-bgcolor:           $primary-blue-hover !default;
$c-btn-primary-active-bgcolor:          $primary-blue-active !default;
$c-btn-primary-inverse-text-color:      $c-btn-primary-bgcolor !default;
$c-btn-primary-inverse-hover-color:     $c-btn-primary-hover-bgcolor !default;
$c-btn-primary-inverse-active-color:    $c-btn-primary-active-bgcolor !default;
$c-btn-primary-inverse-bgcolor:         $color-white !default;
$c-btn-primary-inverse-hover-bgcolor:   $neutral-xlight !default;

$c-btn-secondary-text-color:            $primary-blue !default;
$c-btn-secondary-border-width:          1px !default;
$c-btn-secondary-border-style:          solid !default;
$c-btn-secondary-border-color:          $primary-blue !default;
$c-btn-secondary-hover-border-width:    2px !default;

$c-btn-secondary-bgcolor:               transparent !default;
$c-btn-secondary-hover-bgcolor:         transparent !default;
$c-btn-secondary-active-bgcolor:        transparent !default;
$c-btn-secondary-inverse-text-color:    $color-white !default;
$c-btn-secondary-inverse-border-color:  $color-white !default;

$c-btn-tertiary-text-color:             $primary-black !default;
$c-btn-tertiary-border-width:           1px !default;
$c-btn-tertiary-border-style:           solid !default;
$c-btn-tertiary-border-color:           $primary-black !default;
$c-btn-tertiary-hover-border-width:     2px !default;
$c-btn-tertiary-bgcolor:                transparent !default;
$c-btn-tertiary-hover-bgcolor:          transparent !default;
$c-btn-tertiary-active-bgcolor:         transparent !default;
$c-btn-tertiary-inverse-text-color:     $color-white !default;
$c-btn-tertiary-inverse-border-color:   $color-white !default;

$c-btn-text-text-color:                 $a-link-color !default;
$c-btn-text-hover-color:                $a-active-color !default;
$c-btn-text-icon-size:                  $global-icon-size !default;
$c-btn-text-inverse-text-color:         $color-white !default;

$button-shadow-size:                        3px !default;
$button-shadow-opacity:                     0.3 !default;
$button-shadow-focus:                       0 0 1px $button-shadow-size rgba($c-btn-primary-bgcolor, $button-shadow-opacity) !default;
$button-outline-inset-shadow-hover:         0 0 0 1px $c-btn-primary-hover-bgcolor inset !default;

$button-neutral-shadow:                     0 0 1px $button-shadow-size rgba($primary-black, $button-shadow-opacity) !default;
$button-outline-neutral-inset-shadow-hover: 0 0 0 1px $primary-black inset !default;

// $button-inverse-shadow:                     0 0 1px $button-shadow-size rgba($button-inverse-color-background, $button-shadow-opacity) !default;
// $button-outline-inverse-inset-shadow-hover: 0 0 1px 1px $button-inverse-color-background-hover inset !default;

$text-color-transition:         color 250ms ease-out !default;
$background-color-transition:   background-color 250ms ease-out !default;
$box-shadow-transition:         box-shadow 250ms ease-out !default;

$button-transition:             $text-color-transition, $background-color-transition, $box-shadow-transition !default;



@mixin btn-inner-border($border-width: 2px, $border-color: $primary-blue) {
  &::before {
    content: '';
    position: absolute;
    top:   -(calc($border-width / 2));
    left:  -(calc($border-width / 2));
    width:  100%;
    height: 100%;
    padding: calc($border-width/2);
    border-radius: $c-btn-radius;
    box-shadow: inset 0 0 0 $border-width $border-color;
    opacity: 0;
    @include scale(1);
    transition: $opacity-hover-trans, transform 250ms ease-out;
    box-sizing: content-box;
    pointer-events: none;
    will-change: opacity;
  }
}

/**
 * HTML

  Anchor:
    <a href="#0" class="c-btn c-btn--primary" role="button">Link</a>

  Button:
    <button class="c-btn c-btn--primary">Button</button>

  Input:
    <input type="submit" value="Submit" class="c-btn c-btn--primary" />

 *
 */

/**
 * 1. Mobile first - make our buttons block elements below tablet size screens,
 *    center align them, and make them full width up to a set max-width.
 * 2. Allow us to style box model properties.
 * 3. Line different sized buttons up a little nicer.
 * 4. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 5. Reset/normalize some styles.
 * 6. Force all button-styled elements to appear clickable.
 * 7. Give the user a nice visual indication that the button is in focus.
 * 8. Make the button `pill` shaped.
 */
.auth-content .button,
.c-btn {
  position: relative;
  display: block; /* [1] */
  width: 100%; /* [1] */
  max-width:      $c-btn-max-width-mobile; /* [1] */
  margin-bottom:  $global-unit; /* [1] */
  margin-right:   auto; /* [1] */
  margin-left:    auto; /* [1] */
  padding-top:    $c-btn-padding-y-mobile;    /* [1] */
  padding-bottom: $c-btn-padding-y-mobile;    /* [1] */
  padding-left:   $c-btn-padding-x-mobile;  /* [1] */
  padding-right:  $c-btn-padding-x-mobile;  /* [1] */
  font: inherit; /* [4] */
  text-align: center; /* [5] */
  @include font-size($c-btn-font-size, 1.25);
  font-weight: $c-btn-font-weight;
  letter-spacing: $c-btn-letter-spacing;
  // white-space: nowrap;
  background-color: transparent;
  cursor: pointer; /* [6] */
  border: 0;
  border-radius: $c-btn-radius; /* [8] */
  transition: $button-transition;


  @include all-anchor-states() {
    text-decoration: none;
  }

  @include hocus() {
    outline: 0;
    cursor: pointer;
  }

  &:hover {
    box-shadow: none;
  }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: $button-shadow-focus;
  }

  // &:active {
  //   &::after {
  //     opacity: 0; // Don't show outline border on active
  //   }
  // }

  &:disabled,
  &.is-disabled {
    transition: none;

    @include all-anchor-states() {
      cursor: default;
      background-image: none;
      border: 0;
      opacity: $c-btn-opacity-disabled;
    }

  }

  @include mq($from: tablet) {
    display: inline-block; /* [2] */
    vertical-align: middle; /* [3] */
    margin-left:    0; /* [5] */
    margin-bottom:  0; /* [5] */
    margin-right:   0; /* [5] */
    padding-top:    $c-btn-padding-y;    /* [1] */
    padding-bottom: $c-btn-padding-y;    /* [1] */
    padding-left:   $c-btn-padding-x;  /* [1] */
    padding-right:  $c-btn-padding-x;  /* [1] */
    width: auto;
    max-width: unset;
    height: $c-btn-height;
    line-height: $c-btn-height;
    white-space: nowrap;
    // min-width: $c-btn-min-width;
  }

}


// Set up an input type="button|submit" to be styled inside a `.c-btn` container
.c-btn__input {
  display: inline-block;
  width: auto;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  background: none;
  border: none;
  cursor: pointer;
  @include appearance(none);

  &:focus {
    outline: 1px dotted rgba($color-white, 0.6);
    outline-offset: -4px;
  }

}



/* Style variants
   ========================================================================== */

.auth-content .button.button-primary,
.c-btn--primary {
  background-color: $c-btn-primary-bgcolor;

  // If a variable is define for creating a background gradient
  @if (variable-exists(c-btn-primary-bgg)) {
    @include background-image($c-btn-primary-bgg);
  }

  @include all-anchor-states() {
    color: $c-btn-primary-text-color;
  }

  @include hocus() {
    background-color: $c-btn-primary-hover-bgcolor;

    // If a variable is define for creating a background gradient
    @if (variable-exists(c-btn-primary-hover-bgg)) {
      @include background-image($c-btn-primary-hover-bgg);
    }

  }

  &:active {
    background-color: $c-btn-primary-active-bgcolor;
  }

  &:disabled,
  &.is-disabled {

    @include all-anchor-states() {
      background-color: $c-btn-primary-bgcolor;
    }

  }

  // Make an outline border when button is focused.
  // For accessibility
  &::after {
    content: '';
    position: absolute;
    top:    0;
    left:   0;
    width:  100%;
    height: 100%;
    padding: 0;
    border-radius: $c-btn-radius;
    box-shadow: 0 0 0 $c-btn-focus-outline-size rgba($c-btn-primary-bgcolor, $c-btn-focus-outline-opacity);
    opacity: 0;
    @include scale(0.8);
    transition: $opacity-hover-trans, transform 250ms ease-out;
    box-sizing: content-box;
    pointer-events: none;
    will-change: opacity;
  }

  // &:focus {
  //   &::after {
  //     opacity: 1; // Show outline border on focus
  //     @include scale(1);
  //   }
  // }

}

.o-form-button-bar.focused-input .button{
  outline: none;

  &::after {
    opacity: 1; // Show outline border on focus
    @include scale(1);
  }
}


.c-btn--secondary {
  border-width: $c-btn-secondary-border-width;
  border-style: $c-btn-secondary-border-style;
  border-color: $c-btn-secondary-border-color;
  background-color: $c-btn-secondary-bgcolor;

  // Create an inner border shown on :hover :focus :active
  @include btn-inner-border($c-btn-secondary-hover-border-width, $c-btn-secondary-border-color);


  // Change :focus border color to match button styles
  &::after {
    border-color: rgba($c-btn-secondary-text-color, $c-btn-focus-outline-opacity);
  }

  // If a variable is define for creating a background gradient
  @if (variable-exists(c-btn-secondary-bgg)) {
    @include background-image($c-btn-secondary-bgg);
  }

  @include all-anchor-states() {
    text-decoration: none;
    color: $c-btn-secondary-text-color;
  }

  @include hocus() {
    background-color: $c-btn-secondary-hover-bgcolor;

    // // Show inner border on :hover :focus
    // &::before {
    //   opacity: 1;
    // }

    @if (variable-exists(c-btn-secondary-hover-bgg)) {
      @include background-image($c-btn-secondary-hover-bgg);
    }

  }

  &:active {
    background-color: $c-btn-secondary-active-bgcolor;

    // // Show inner border on :active
    // &::before {
    //   opacity: 1;
    // }

  }

  &:hover {
    box-shadow: $button-outline-inset-shadow-hover;
  }

  &:disabled,
  &.is-disabled {
    @include all-anchor-states() {
      border-width: $c-btn-secondary-border-width;
      border-style: $c-btn-secondary-border-style;
      border-color: $c-btn-secondary-border-color;
      background-color: $c-btn-secondary-bgcolor;

      // Hide inner border
      &::before {
        display: none;
      }

    }
  }

  // Reduce vertical padding @mobile because of border
  @include mq($until: tablet) {
    padding-top:    $c-btn-padding-y-mobile - $c-btn-secondary-border-width;
    padding-bottom: $c-btn-padding-y-mobile - $c-btn-secondary-border-width;
  }

  // Reduce line-height to account for the border-width.
  @include mq($from: tablet) {
    line-height: $c-btn-height - ($c-btn-secondary-border-width * 2);
  }
}


.c-btn--tertiary {
  border-width: $c-btn-tertiary-border-width;
  border-style: $c-btn-tertiary-border-style;
  border-color: $c-btn-tertiary-border-color;
  background-color: $c-btn-tertiary-bgcolor;

  // Create an inner border shown on :hover :focus :active
  @include btn-inner-border($c-btn-tertiary-hover-border-width, $c-btn-tertiary-border-color);

  // Change :focus border color to match button styles
  &::after {
    border-color: rgba($c-btn-tertiary-text-color, $c-btn-focus-outline-opacity);
  }

  // If a variable is define for creating a background gradient
  @if (variable-exists(c-btn-tertiary-bgg)) {
    @include background-image($c-btn-tertiary-bgg);
  }

  @include all-anchor-states() {
    text-decoration: none;
    color: $c-btn-tertiary-text-color;
  }

  @include hocus() {
    background-color: $c-btn-tertiary-hover-bgcolor;

    // Show inner border on :hover :focus
    // &::before {
    //   opacity: 1;
    // }

  }

  &:active {
    background-color: $c-btn-tertiary-active-bgcolor;

    // Show inner border on :active
    // &::before {
    //   opacity: 1;
    // }

  }

  &:disabled,
  &.is-disabled {
    @include all-anchor-states() {
      border-width: $c-btn-tertiary-border-width;
      border-style: $c-btn-tertiary-border-style;
      border-color: $c-btn-tertiary-border-color;
      background-color: $c-btn-tertiary-bgcolor;

      // Hide inner border
      &::before {
        display: none;
      }

    }
  }

  &:hover {
    box-shadow: $button-outline-neutral-inset-shadow-hover;
  }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: $button-neutral-shadow;
  }

  // Reduce vertical padding @mobile because of border
  @include mq($until: tablet) {
    padding-top:    $c-btn-padding-y-mobile - $c-btn-tertiary-border-width;
    padding-bottom: $c-btn-padding-y-mobile - $c-btn-tertiary-border-width;
  }

  // Reduce line-height to account for the border-width.
  @include mq($from: tablet) {
    line-height: $c-btn-height - ($c-btn-tertiary-border-width * 2);
  }
}


// Looks like text but treated like a button
.c-btn--text {
  width: auto;
  letter-spacing: initial;
  font-weight: $font-weight-medium;
  border-radius: 0;

  // Make an underline border when button is focused.
  // For accessibility
  &::after {
    content: '';
    position: absolute;
    top:    auto;
    left:   0;
    right:  auto;
    bottom: 3px;
    width: 100%;
    border-width: 2px;
    border-style: $c-btn-focus-outline-style;
    border-color: rgba($c-btn-primary-bgcolor, $c-btn-focus-outline-opacity);
    border-radius: 0;
    opacity: 0;
    transition: $opacity-hover-trans;
    will-change: opacity;
  }

  @include all-anchor-states() {
    color: $c-btn-text-text-color;
  }

  @include hocus() {
    color: $c-btn-text-hover-color;
  }

  &.is-inline,
  &.is-back-btn {
    display: inline-block;
    margin: 0;
  }

  &.is-inline {
    height: auto;
    line-height: inherit;
  }


}



.c-btn--skip-to-content {
  @include mq($until: desktop) {
    display: none;
  }

  position: absolute;
  top: 0;
  left: 50%;
  @include font-size($c-btn-font-size);
  transform: translate(-50%, -300%);
  transition: transform 0.3s;
  z-index: 100;

  &:focus {
    transform: translate(-50%, 24px);
  }

}





/**
 * An icon only button butted up to a form input, like a search control.
 *
 * 1. Reset inherited `c-btn` styles that will mess up the combo button.
 * 2. Default button width that fits an icon.
 * 3. Match the height of input fields.
 * 4. Hide any button text that might be there for screen-readers etc.
 *    put it in a `span` tag.
 * HTML

    <div class="c-form-field--btn-combo">
      <input type="text" name="text" value="" class="c-input c-input--btn-combo" />

      <div class="c-btn c-btn--primary c-btn--input-combo">
          <svg class="c-svg c-svg--search-24">
            <use xlink:href="#c-svg--search-24"></use>
          </svg>
          <input type="submit" value="Search" class="c-btn__input" />
      </div>
    </div>

 */
.c-btn--input-combo {
  display: inline-block; /* [1] */
  margin: 0;    /* [1] */
  width: 40px;  /* [2] */
  height: $input-field-height; /* [3] */
  padding: $global-unit-tiny;
  border-top-left-radius:    0;
  border-bottom-left-radius: 0;
  border-top-right-radius:    $global-radius;
  border-bottom-right-radius: $global-radius;
  vertical-align: top;

  span {
    display: none;
    @include hide-text(); /* [4] */
  }

  .c-svg {
    @include position-center();
    width:  $c-btn-icon-size;
    height: $c-btn-icon-size;
  }

  .c-btn__input {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
  }

  &:hover {
    .c-svg {
      color: currentColor;
    }
  }

}





/* Inverse buttons
   ========================================================================== */
/**
 * Inverse buttons are used on dark backgrounds.
 */
.c-btn--inverse {

  &.c-btn--primary {

    &,
    &:link,
    &:visited {
      color: $c-btn-primary-inverse-text-color;
      background-color: $c-btn-primary-inverse-bgcolor;
    }

    @include hocus() {
      color: $c-btn-primary-inverse-hover-color;
      background-color: $c-btn-primary-inverse-hover-bgcolor;
    }

    &:active{
      color: $c-btn-primary-inverse-active-color;
      background-color: $c-btn-primary-inverse-bgcolor;
    }

    &:disabled,
    &.is-disabled {

      @include all-anchor-states() {
        background-color: $c-btn-primary-inverse-bgcolor;
      }

    }

  }

  &.c-btn--secondary {
    @include btn-inner-border($c-btn-secondary-hover-border-width, $c-btn-secondary-inverse-border-color);

    &,
    &:link,
    &:visited {
      color: $c-btn-secondary-inverse-text-color;
      border-color: $c-btn-secondary-inverse-border-color;
    }

    &:hover,
    &:active,
    &:focus {
      color: $c-btn-secondary-inverse-text-color;
      border-color: $c-btn-secondary-inverse-border-color;

      &::before {
        opacity: 1;
      }

    }

    &:disabled,
    &.is-disabled {

      @include all-anchor-states() {
        color: $c-btn-secondary-inverse-text-color;
        border-color: $c-btn-secondary-inverse-border-color;
      }

    }

  }

  &.c-btn--text {

    &,
    &:link,
    &:visited {
      color: $c-btn-text-inverse-text-color;
    }

    &:hover,
    &:active,
    &:focus {
      color: $c-btn-text-inverse-text-color;
    }

    &:disabled,
    &.is-disabled {

      @include all-anchor-states() {
        color: $c-btn-text-inverse-text-color;
      }

    }

  }

}





/* Buttons with an icon
   ========================================================================== */
/**
 * 1. Set pointer-events to none to fix issue with the parent container's
 *    :pseudo or positioned elements interfering with the submmit action of the
 *    input button.
 * 2. Reset pointer-events on submit button.
 * 3. Icons are placed on the right by default, we need to add extra padding to
 *    account for the positioning of the icon.
 * 4. We have to do some negative margin trickery so our input button looks
 *    and interacts like any other button.
 * 5. Then re-add the padding to the input element.
 */
.c-btn {

  &.is-input {

    &,
    &:hover,
    &:active,
    .c-svg {
      pointer-events: none; /* [1] */
    }
  }

  &:not(.c-btn--text) {

    // Icons sould be to the right of the text by default,
    // but sometimes you might need it on the left.
    &.has-icon,
    &.has-icon-left {

      .c-svg {
        @include position-y-center();
        width:  $c-btn-icon-size;
        height: $c-btn-icon-size;
        color: currentColor;
        background-color: transparent;
      }

      .c-btn__input {
        height: $c-btn-height;
        pointer-events: all; /* [2] */
      }

    }

    &.has-icon {
      padding-right: $c-btn-padding-icon; /* [3] */

      .c-svg {
        right: $c-btn-padding-x; /* [3] */
      }

      .c-btn__input {
        margin-left:   -$c-btn-padding-x; /* [4] */
        margin-right:  -$c-btn-padding-icon; /* [4] */
        padding-left:  $c-btn-padding-x; /* [5] */
        padding-right: $c-btn-padding-icon; /* [5] */
      }

    }

    &.has-icon-left {
      padding-left: $c-btn-padding-icon; /* [3] */

      .c-svg {
        left: $c-btn-padding-x; /* [3] */
      }

      .c-btn__input {
        margin-left:   -$c-btn-padding-icon; /* [4] */
        margin-right:  -$c-btn-padding-x;   /* [4] */
        padding-left:   $c-btn-padding-icon;  /* [5] */
        padding-right:  $c-btn-padding-x;    /* [5] */
      }

    }

  }

  &.c-btn--text {
    padding: 0;
    &.has-icon,
    &.has-icon-left {
      .c-svg {
        top: 0.125em;
        // @include font-size(24px, 1);
      }
    }

    &.has-icon {
      .c-svg {
        margin-left: 6px;
      }
    }

    &.has-icon-left {
      .c-svg {
        margin-right: 6px;
      }
    }

  }

}




/* Size variants
   ========================================================================== */
.c-btn--auto {
  width: auto;
  min-width: unset;
  max-width: unset;
}

$sm-btn-height: 34px;
.c-btn--small {
  height: $sm-btn-height;
  padding-left: $global-unit;
  padding-right: $global-unit;
  @include font-size(14px, $sm-btn-height);
}

.c-btn--large {
  padding: $global-unit $global-unit-large;
}

.c-btn--small,
.c-btn--large {

  &.c-btn--has-icon {
    padding-left: $c-btn-padding-icon;
  }

  &.c-btn--has-icon-right {
    padding-right: $c-btn-padding-icon;
  }

}


// We might want certain buttons to be of the same width, or minimum width,
// such as form submit buttons that might have text of varying length, like:
// "Save", "Submit", "Done", "Ok", you get the idea.
.c-btn--min-width {

  @include mq($from: tablet) {
    min-width: $c-btn-min-width;
  }

}

.c-btn--short {

  @include mq($from: tablet) {
    width: $c-btn-short;
  }

}

.c-btn--wide {

  @include mq($from: tablet) {
    width: $c-btn-wide;
  }

}

// Make the button be the full width of it's parent container.
.c-btn--full {
  width: 100%;
}


.c-btn--tight {
  padding-left:  $global-unit;
  padding-right: $global-unit;
}


// App Dl button app-only-countries
.c-btn-app-dl {
  display: block;
  width:  100%;
  height: 100%;
  transition: opacity 250ms ease-out;
  will-change: opacity;

  &:hover {
    opacity: 0.85;
  }

  @include mq($until: tablet) {
    margin-bottom: $global-unit;
  }


  .c-svg.c-svg--app-store-asia {
    width: 129.70071px;
    height: 40px;
  }
}
