/* ==========================================================================
   #ALIASES
   ========================================================================== */
// Reassign some inuitcss mixins to make typing easier.

// Reassign lengthy mixin names.
@mixin font-size($font-size, $line-height: auto, $modifier: 0, $important: false) {
  @include inuit-font-size($font-size, $line-height, $modifier, $important);
}



@mixin hide-from-screen() {
  @include inuit-hidden-visually();
}



// Mixin to drop micro clearfix into a selector. Further reading:
// http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
//
// .simple-usage {
//   @include clearfix();
// }
//
// .detailed-usage {
//   @include clearfix(important);
// }
@mixin clearfix() {
  @include inuit-clearfix();
}