/* ========================================================================
   #CORE
   ======================================================================== */
/**
 * Use this file to overwrite inuitcss core settings for the global baseline
 * setting and global spacing unit.
 */

/**
 * To modify inuitcss core settings, uncomment the lines below.
 * Please proceed with caution, changing the settings can mess your sh*t up.
 */

/**
 * Baseline grid lines height. Every spacing metric should be based on this.
 */
@if (variable-exists(res-global-baseline)) {
  $inuit-global-baseline: $res-global-baseline; // ( inuitcss default: 6px )
}

/**
 * How many grid lines should our spacing unit variants span?
 * Each value should be an unitless integer.
 */
@if (variable-exists(res-global-spacing-unit-factor-tiny)) {
  $inuit-global-spacing-unit-factor-tiny: $res-global-spacing-unit-factor-tiny; // ( inuitcss default: 1  = 6px )
}
@if (variable-exists(res-global-spacing-unit-factor-small)) {
  $inuit-global-spacing-unit-factor-small: $res-global-spacing-unit-factor-small; // ( inuitcss default: 2  = 12px )
}
@if (variable-exists(res-global-spacing-unit-factor)) {
  $inuit-global-spacing-unit-factor: $res-global-spacing-unit-factor; // ( inuitcss default: 4  = 24px )
}
@if (variable-exists(res-global-spacing-unit-factor-large)) {
  $inuit-global-spacing-unit-factor-large: $res-global-spacing-unit-factor-large; // ( inuitcss default: 8  = 48px )
}
@if (variable-exists(res-global-spacing-unit-factor-huge)) {
  $inuit-global-spacing-unit-factor-huge: $res-global-spacing-unit-factor-huge; // ( inuitcss default: 16 = 96px )
}

/**
 * Base typographical styles.
 */
@if (variable-exists(res-global-font-size)) {
  $inuit-global-font-size: $res-global-font-size; // ( inuitcss default: 16px )
}

@if (variable-exists(res-global-line-height)) {
  $inuit-global-line-height: $res-global-line-height; // ( inuitcss default: $inuit-global-spacing-unit = 24px )
}