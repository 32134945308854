﻿/* ==========================================================================
#CALLOUTS
========================================================================== */
// Boxes for calls to action or lures.

.o-callout {
  position: relative;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}



.o-callout__body {
  display: inline-block;
}




/* Style modifiers.
========================================================================== */
.o-callout--need-help {
  margin-top: $global-unit-xlarge;
  border-left: 4px solid $primary-blue;
  background-color: $neutral-ulight;

  > .o-callout__body {
    padding: $global-unit;
  }

  p:last-of-type {
    margin: 0;
  }

}


.o-callout--pos-right {
  margin: 0;
  margin-bottom: $global-unit;
  text-align: center;

  @include mq($from: tablet) {
    position: absolute;
    top: $global-unit-tiny;
    left: auto;
    right: 0;
    margin: 0;
  }

}


.o-callout--password {
  padding: $global-unit;
  @include font-size(14px);
  background-color: $neutral-ulight;

  ul, ol {
    margin-left: 18px;
    margin-bottom: 0;
  }

}