/* ==========================================================================
   #ICON BUTTONS
   ========================================================================== */

$c-icon-size:                 $global-icon-size !default;
$c-icon-size-large:           $global-icon-size-lg !default;
$c-icon-default-color:        $html-text-color !default;
$c-icon-hover-color:          $color-black !default;
$c-icon-disabled-opacity:     $opacity-disabled !default;

$c-close-btn-color:           $html-text-color !default;
$c-close-btn-hover-color:     $color-black !default;
$c-close-btn-hover-bgcolor:   $color-white !default;
$c-close-btn-inverse-color:   $color-white !default;

/**
 * Icon buttons are just that, an icon which acts as buttons. For example,
 * the close button on a modal window, or the play button over a video thumbnail.
 * 
 */
.c-icon-btn {
  position: relative;
  display: inline-block;
  width:  $c-icon-size;
  height: $c-icon-size;
  margin: 0;
  padding: 0;
  border: none;
  line-height: 0;
  background-color: transparent;
  cursor: pointer;
  @include appearance(none);

  @include all-anchor-states() {
    text-decoration: none;
  }

  &:disabled {
    transition: none;

    &,
    &:hover {
      cursor: default;
      background-image: none;
      border: 0;
      opacity: $c-icon-disabled-opacity;
    }

  }

  // Use `.c-svg-hover-swap` on parent element if swapping between two svg icons on hover
  .c-svg {
    &,
    &.c-svg--baseline {
      @include position-center();
      width:  100%;
      height: 100%;
    }
  }

}


// Button with icon and text
.c-icon-btn--label {
  width: auto;
  height: auto;

  @if ($use-markup-fix == false) {
    font-size: 0;
  }

  > * {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;

    @if ($use-markup-fix == false) {
      @include font-size($global-font-size, 0);
    }

  }

}

  .c-icon-btn__icon {
    position: relative;
    margin-right: $global-unit-small;
    width:  $c-icon-size-large;
    height: $c-icon-size-large;

    .c-svg {
      width: 100%;
      height: 100%;
    }
  }

  .c-icon-btn__label {
    text-align: left;
    font-weight: $font-weight-medium;
  }


// Add this class to a input/submit button wrapped in a `.c-icon-btn` container.
.c-icon-btn-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 100;
}


// Play button overlaid on video thumbnails.
.c-icon-btn--play {
  width:  80px;
  height: 80px;
  text-align: center;
  color: $color-white;
  
  .c-svg {
    width:  100%;
    height: 100%;
    cursor: pointer;
    fill: currentColor;
    transition: filter 250ms ease-out;

    &:hover {
      background-color: transparent;
      filter: url(#drop-shadow);
    }

  }

}


/**
 * 'X' type button for closing windows and overlays
 *
 * HTML

    <button class="c-icon-btn c-icon-btn--close js-close-btn">
      <svg class="c-svg c-svg--close-24"><use xlink:href="#c-svg--close-24"></use></svg>
    </button>

  Inverse - white X on dark background

    <button class="c-icon-btn c-icon-btn--close is-inverse js-close-btn">
      <svg class="c-svg c-svg--close-24"><use xlink:href="#c-svg--close-24"></use></svg>
    </button>
  
 */
.c-icon-btn--close {
  z-index: 0;

  &,
  &:link,
  &:visited {
    color: $c-close-btn-color;

    @at-root .is-inverse#{&} {
      color: $c-close-btn-inverse-color;
    }

  }
  
  &:before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
    z-index: -1;
    bottom:  0;
    left:    0;
    right:   0;
    top:     0;
    background: none;
    border-radius: 50%;
    box-sizing: border-box;
    transform: scale(0);
    transition-property: transform,opacity;
  }

  &:hover,
  &:active,
  &:focus {
    color: $c-close-btn-hover-color;

    &:before {
      background-color: $c-close-btn-hover-bgcolor;
      border: none;
      box-shadow: none;
      opacity: 1;
      transform: scale(1);
    }

  }

  &:focus {
    &,
    &:before {
      outline: none;
    }
  }

  &:active:before {
    border: 1px solid $neutral-light;
  }

}


// 'X' type button for deleting an item, table row
.c-icon-btn--delete {
  border-radius: 50%;

  &,
  &:link,
  &:visited {
    color: $primary-black;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-ui-error;
  }

  &.delete-table-row {
    z-index: 0;

    &:before {
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      transition-duration: .15s;
      transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
      z-index: -1;
      bottom: -6px;
      left:   -6px;
      right:  -6px;
      top:    -6px;
      background: none;
      border-radius: 50%;
      box-sizing: border-box;
      transform: scale(0);
      transition-property: transform,opacity;
    }

    &:hover,
    &:focus,
    &:active {

      &:before {
        background-color: rgba(32,33,36,0.059);
        border: none;
        box-shadow: none;
        opacity: 1;
        transform: scale(1);
      }

    }

    &:focus {
      &,
      &:before {
        outline: none;
      }
    }

    &:active:before {
      border: 1px solid $neutral-light;
    }

  }

}


// Ratings widget thumbs up/down
.c-icon-btn-thumbs {
  position: relative;
  width:  $c-icon-size;
  height: $c-icon-size;
  margin: 0;
  padding: 0;
  float: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}


/**
 * Back to Top - requires JS, find showBackToTop(); 
 *
 * HTML:

    <a class="c-icon-btn-top-link c-svg-hover-swap [ js-scrollToElem ]" href="#top" data-state="is-hidden" role="button">
      <svg class="c-svg c-svg--top-circle-outline-24 icon-default"><use xlink:href="#c-svg--top-circle-outline-24"></use></svg>
      <svg class="c-svg c-svg--atop-circle-fill-24 icon-hover"><use xlink:href="#c-svg--top-circle-fill-24"></use></svg>
      <span class="u-screen-reader-text">Back to top</span>
    </a>

 */
.c-icon-btn-top-link {
  // @include position-sticky(true);
  position: fixed !important;
  bottom: 160px;
  // left: 100%;
  right: $global-unit-small;
  display: inline-flex;
  margin: 0;
  padding: 0;
  width:  $c-icon-size-large;
  height: $c-icon-size-large;
  line-height: 0;
  background-color: rgba($color-white, 0.8);
  border-radius: $button-pill-radius;
  border: 1px solid rgba($color-white, 0.8);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: all .25s ease-in-out;

  @include mq($from: desktop) {
    right: $global-unit;
  }

  &.show,
  &[data-state="is-visible"] {
    visibility: visible;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    will-change: opacity;
  }

  &.hide,
  &[data-state="is-hidden"] {
    visibility: hidden;
    opacity: 0;
  }

  .c-svg {
    @include position-center();
    width:  100%;
    height: 100%;
    transition: opacity .25s ease-in-out, color .25s ease-in-out;
    will-change: color;
    color: $c-icon-default-color;

    @include hocus() {
      color: $c-icon-hover-color;
    }

  }

  // @include mq($from: x-wide) {
  //   @include translateX(54px);
  // }

  // For stupid IE - this class works with .Net
  &.sticky-top-ie {
    position: fixed;
    left: auto;
    right: 24px;
    bottom: 160px;

    // @include mq($from: x-wide) {
    //   @include translateX(0);
    // }

  }

}