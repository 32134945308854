﻿/* ==========================================================================
  #SCROLLABLE
  ========================================================================== */
// Put an element or other object inside a scrollable container

.o-scrollable {
  overflow-y: scroll;
}

.o-scrollable__body {
  padding-right: $global-unit; //for scrollbar
}

.o-scrollable--h250px {
  height: 250px;
}