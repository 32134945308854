/* ==========================================================================
   #ICONS
   ========================================================================== */

$c-icon-ddlb-arrow-size:     16px !default;
$c-icon-ddlb-arrow-color:    $primary-black !default;

/**
 * Create design patterns with icons.
 */
.c-icon {
  position: relative;
  display: inline-block;

  &:before {
    display: inline-block;
    color: inherit;
  }


  .is-disabled &,
  [data-state="is-disabled"] & {
    cursor: none !important;
    opacity: 0.3;
  }

  span {
    display: none;
    @include hide-text();
  }

}


.c-icon--drop-down-arrow {
  &:before {
    display: inline-block;
    width:  16px;
    height: 16px;
    @include svg-bg-img($svg-ddlb-arrow, $global-icon-size, $global-icon-size, $primary-black);
  }
}


.res-info  {
  position: relative;
  cursor: pointer;
  text-align: left;


  &:before {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 0 !important;
    @include font-size(11px, 20px);
    font-style: normal;
    font-weight: $font-weight-medium;
    text-align: center;
    background-color: $color-white;
    color: $color-black;
    border-width: 1px;
    border-style: solid;
    border-color: $color-black;
    border-radius: 50%;
    transition: background-color 250ms ease-out, border-color 250ms ease-out;
  }

  &:hover {

    &:before {
      background-color: $primary-blue;
      color: $color-white !important;
      border-color: $primary-blue;
    }

  }

}


.c-icon-otp-demo {
    
  @include font-size(33px);
  transition: color 250ms ease-out, color 250ms ease-out;

  &,
  &:link,
  &:visited,
  &:active {
    color: $color-black;
    text-decoration: none !important;
  }

  &:hover {
    color: $primary-blue-active;
    text-decoration: none !important;
  }

}

.c-icon-hero {
  position: relative;
  display: inline-block;
  margin: 0 auto $global-unit-large;
  @include font-size(80px);
}


.c-icon-hero--success {
  color: $secondary-green;
}

.c-icon-hero--error {
  color: $secondary-red;
}

.c-icon-display {
  width:  32px;
  height: 32px;

  &.icon-lg {
      width:  48px;
      height: 48px;
  }

  .c-svg {
      width:  100%;
      height: 100%;
      top: 0;
  }
}