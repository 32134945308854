/* ==========================================================================
  #COLOR-FILL
  ========================================================================== */
// Some classes to use our colors as fills.
$fill-brand-colors: $brand-colors !default;
$fill-greyscale-colors: $greyscale !default;

.u-fill {

  /**
  * Iterate over color maps and autogenerate helper classes.
  */
  @if ($fill-brand-colors != null) {

    @each $name, $value in $fill-brand-colors {

      &--#{$name} {
        background-color: $value !important;
      }

    }

  }

  @if ($fill-brand-colors != null) {

    @each $name, $value in $fill-greyscale-colors {

      &--#{$name} {
        background-color: $value !important;
      }

    }

  }

}