/* ==========================================================================
   #POPOVER
   ========================================================================== */

$c-popover-icon-size:             $global-icon-size !default; // width and height value
$c-popover-icon-size-lg:          $global-icon-size-lg !default; // width and height value
$c-popover-default-icon-color:    $html-text-color !default;
$c-popover-active-icon-color:     $color-black !default;

$c-popover-width:                 354px !default;
$c-popover-padding:               32px 42px !default;
$c-popover-border-radius:         6px !default;
$c-popover-bgcolor:               $color-white !default;
$c-popover-border-color:          $neutral-ulight !default;
$c-popover-pointer-size:          16px !default; // Size of the directional caret
$c-popover-offset:                13px !default; // Distance of slide-in animation

$c-popover-dark-bgcolor:          $color-dark-mode-bg !default;
$c-popover-dark-text-color:       $color-dark-mode-text !default;
$c-popover-dark-border-color:     $neutral-heavy !default;

$c-popover-wide-width:            444px !default;
$c-popover-wide-padding:          42px !default;

/**
 * Fancy tooltip bubble that stays active on hover over an icon, like Help or Info,
 * so the user can interact with it, ie: click a link.
 * HTML:

    <div class="c-popover c-popover--icon c-popover--left">
      <a href="#0" class="c-popover__target js-no-click">
        <svg class="c-svg c-svg--help-circle-border"><use xlink:href="#c-svg--help-circle-border"></use></svg>
      </a>
      <div class="c-popover__content">
        <p>Hello, here's a popover! <a href="#0">Click this link.</a></p>
      </div>
    </div>

 * 1. Reset any defaults on the elemtent used for the popover.
 * 2. Show the popover on hover. Placing the hover action on the parent
 *    container allows us to maintain the hover state as long as the mouse
 *    is over any part of the popover, a-la drop-menu in navigation.
 * 3. Default ending position of popover transition animation (top).
 */
.c-popover {
  position: relative;
  display: inline-block; /* [1] */
  margin:  0; /* [1] */
  padding: 0; /* [1] */

  &:hover {
    .c-popover__content {
      display: block;
      opacity: 1; /* [2] */
      visibility: visible; /* [2] */
      will-change: opacity; /* [2] */
      @include translate(-50%, -$c-popover-offset); /* [3] */
    }
  }

}

.c-popover-wrapper {
  position: relative;
  @include inuit-clearfix();
}

/**
 * 1. Reset any defaults which could be inherited on our target element.
 * 2. Change the cursor to a pointer when hovering over the target element.
 */
.c-popover__target {
  margin:  0; /* [1] */
  padding: 0; /* [1] */

  @include all-anchor-states() {
    color: $a-link-color;
    text-decoration: none; /* [1] */
    cursor: pointer; /* [2] */
  }

}


/** 
 * This is the `popover`.
 *
 * 1. Default position of the popover is above (top) the target element.
 *    Setting bottom to 100% aligns the bottom edge of the popover with the top
 *    edge of the target container.
 * 2. At mobile, we don't want the popover to go outside the viewport. Depending on where 
 *    it is on the screen, you can make width adjustments on a specific popover with a new
 *    modifier class.
 * 3. Keep the popover hidden by default. Display:none at mobilile to prevent the popover
 *    from pushing out the page.
 * 4. Starting position of the popover for the transition animation.
 */
.c-popover__content {
  list-style: none;
  margin: 0;
  position: absolute;
  top:    auto; /* [1] */
  left:   50%;  /* [1] */
  right:  auto; /* [1] */
  bottom: 100%; /* [1] */
  z-index: 1;
  width: auto; /* [2] */
  padding: $global-unit-small $global-unit;
  text-align: left;
  font-weight: $font-weight-medium;
  background-color: $c-popover-bgcolor;
  border-radius: $c-popover-border-radius;
  border: 1px solid $c-popover-border-color;
  box-shadow: 0px 6px 26px -6px rgba(0, 0, 0, 0.3);
  display: none; /* [3] */
  visibility: hidden; /* [3] */
  opacity: 0; /* [3] */
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  @include translate(-50%, 0); /* [4] */

  // popover arrows, two overlaid arrows to create outline.
  // popover on top by default, arrows on bottom
  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: 100%;
    right: auto;
    bottom: auto;
    left: 50%;
    content: "";
  }

  // Foreground, visible arrow
  &:before {
    border-top:    $c-popover-pointer-size $c-popover-bgcolor solid; 
    border-left:   $c-popover-pointer-size - 4 transparent solid; 
    border-right:  $c-popover-pointer-size - 4 transparent solid;
    border-bottom: 0;
    @include translate(-50%, 0px);
    z-index: 51;
  }

  // Background arrow, creates outline effect on foreground arraow
  &:after {
    border-top:    $c-popover-pointer-size + 1 $c-popover-border-color solid; 
    border-left:   $c-popover-pointer-size - 3 transparent solid; 
    border-right:  $c-popover-pointer-size - 3 transparent solid;
    border-bottom: 0;
    @include translate(-50%, 0px);
    z-index: 50;
  }

  &,
  > *:last-child {
    margin-bottom: 0;
  }
  

  @include mq($from: tablet) {
    display: block;
    width: $c-popover-width;  /* [2] */
    padding: $c-popover-padding;
  }

}




/* Type variants
   ========================================================================== */
.c-popover--icon {
  display: inline-block;

  // Use `.c-svg-hover-swap` on this element if swapping between two svg icons on hover
  .c-popover__target {
    position: relative;
    display: block;
    width:  $c-popover-icon-size;
    height: $c-popover-icon-size;
    line-height: 0;

    &:not(.c-svg-hover-swap) {
      
      .c-svg {
        width: 100%;
        height: 100%;
        top: 0;
        color: $c-popover-default-icon-color;
        transition: $color-hover-trans;

        @include hocus() {
          color: $c-popover-active-icon-color;
          cursor: default;
        }
        
      }

    }

  }

  &.icon-lg {
    .c-popover__target {
      width:  $c-popover-icon-size-lg;
      height: $c-popover-icon-size-lg;
    }
  }

}




/* Style variants
   ========================================================================== */
.c-popover--dark {

  .c-popover__content {
    background-color: $c-popover-dark-bgcolor;
    border-color: $c-popover-dark-border-color;

    &,
    > * {
      color: $c-popover-dark-text-color;
    }

    a {

      @include all-anchor-states() {
        color: $c-popover-dark-text-color;;
      }

      @include hocus() {
        text-decoration: underline;
      }

    }

    &:before {
      border-top-color: $c-popover-dark-bgcolor; 
    }

    &:after {
      border-top-color: $c-popover-dark-border-color; 
    }

  }

  &.c-popover--bottom {
    .c-popover__content {
      &:before {
        border-bottom-color: $c-popover-dark-bgcolor; 
      }
      &:after {
        border-bottom-color: $c-popover-dark-border-color; 
      }
    }
  }

  &.c-popover--left {
    .c-popover__content {
      &:before {
        border-left-color: $c-popover-dark-bgcolor; 
      }
      &:after {
        border-left-color: $c-popover-dark-border-color; 
      }
    }
  }

  &.c-popover--right {
    .c-popover__content {
      &:before {
        border-right-color: $c-popover-dark-bgcolor; 
      }
      &:after {
        border-right-color: $c-popover-dark-border-color; 
      }
    }
  }


}


.c-popover--wide {

  .c-popover__content {
    @include mq($from: tablet) {
      width:   $c-popover-wide-width;
      padding: $c-popover-wide-padding;
    }
  }

}




/* Directional variants
   ========================================================================== */
.c-popover--bottom {

  .c-popover__content {
    position: absolute;
    top: 100%;
    left: 50%;
    right: auto;
    bottom: auto;
    @include translate(-50%, 0);
    
    // arrows on top of popover
    &:before,
    &:after {
      top: 0;
      right: auto;
      bottom: auto;
      left: 50%;
    }
    
    &:before {
      border-bottom: $c-popover-pointer-size $c-popover-bgcolor solid; 
      border-left:   $c-popover-pointer-size - 4 transparent solid; 
      border-right:  $c-popover-pointer-size - 4 transparent solid;
      border-top:    0;
      @include translate(-50%, -$c-popover-pointer-size);
    }

    &:after {
      border-bottom: $c-popover-pointer-size + 1 $c-popover-border-color solid; 
      border-left:   $c-popover-pointer-size - 3 transparent solid; 
      border-right:  $c-popover-pointer-size - 3 transparent solid;
      border-top:    0;
      @include translate(-50%, -($c-popover-pointer-size + 1));
    }

  }

  &:hover .c-popover__content {
    @include translate(-50%, $c-popover-offset);
  }

}


.c-popover--left {

  .c-popover__content {
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    @include translate(0, -50%);

    // arrows on the right side of the popover
    &:before,
    &:after {
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto;
    }
    
    &:before {
      border-bottom: $c-popover-pointer-size - 4 transparent solid;
      border-right:  $c-popover-pointer-size - 4 transparent solid;
      border-left:   $c-popover-pointer-size $c-popover-bgcolor solid;
      border-top:    $c-popover-pointer-size - 4 transparent solid;
      @include translate($c-popover-pointer-size * 2 - 4, -50%);
    }

    &:after {
      border-bottom: $c-popover-pointer-size - 3 transparent solid;
      border-right:  $c-popover-pointer-size - 3 transparent solid;
      border-left:   $c-popover-pointer-size + 1 $c-popover-border-color solid;
      border-top:    $c-popover-pointer-size - 3 transparent solid;
      @include translate($c-popover-pointer-size * 2 - 2, -50%);
    }
    
  }

  &:hover .c-popover__content {
    @include translate(-$c-popover-offset, -50%);
  }

}


.c-popover--right {

  .c-popover__content {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 100%;
    @include translate(0, -50%);


    // arrows on the left side of the popover
    &:before,
    &:after {
      top: 50%;
      right: auto;
      bottom: auto;
      left: 0;
    }
    
    &:before {
      border-bottom: $c-popover-pointer-size - 4 transparent solid;
      border-right:  $c-popover-pointer-size $c-popover-bgcolor solid;
      border-left:   $c-popover-pointer-size - 4 transparent solid;
      border-top:    $c-popover-pointer-size - 4 transparent solid;
      @include translate(-($c-popover-pointer-size * 2 - 4), -50%); // 27
    }

    &:after {
      border-bottom: $c-popover-pointer-size - 3 transparent solid;
      border-right:  $c-popover-pointer-size + 1 $c-popover-border-color solid;
      border-left:   $c-popover-pointer-size - 3 transparent solid;
      border-top:    $c-popover-pointer-size - 3 transparent solid;
      @include translate(-($c-popover-pointer-size * 2 - 2), -50%); // 30
    }
      
  }

  &:hover .c-popover__content {
    @include translate($c-popover-offset, -50%);
  }

}



.c-popover-wrapper--score {

  .c-popover {
    position: absolute;
    top: -$global-unit-large;
    right: 0;
    left: auto;
    bottom: auto;

    @include mq($from: tablet) {
      top: $global-unit-small;
    }

  }

  .c-popover--left {

    .c-popover__content {

      @include mq($until: tablet) {
        width: 300px;
      }

    }

  }

}