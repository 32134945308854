/* ==========================================================================
   #ALERTS
   ========================================================================== */

$c-alert-icon-size:                 $global-icon-size !default;
$c-alert-icon-left-pos:             $global-unit !default;
$c-alert-icon-area-width:           $global-unit-large !default;
$c-alert-icon-area-height:          32px !default;

$c-alert-radius:                    $global-radius !default;
$c-alert-font-size:                 $global-font-size !default;
$c-alert-padding-y:                 $global-unit !default;
$c-alert-padding-x-mobile:          $global-unit-medium !default;
$c-alert-padding-x:                 $global-unit-huge !default;

$c-alert-mobile-close-btn-top:      $global-unit-tiny !default;
$c-alert-mobile-close-btn-right:    $global-unit-tiny !default;
$c-alert-close-btn-top:             $global-unit-tiny !default;
$c-alert-close-btn-right:           $global-unit-tiny !default;

$c-alert-message-bgcolor:           $primary-blue !default;
$c-alert-message-text-color:        $color-white !default;
$c-alert-info-icon:                 $svg-alert-info-fill !default;

$c-alert-error-icon:                $svg-alert-error-fill !default;
$c-alert-error-icon-color:          $color-white !default;
$c-alert-error-bgcolor:             $color-ui-error !default;
$c-alert-error-text-color:          $color-white !default;

$c-alert-warning-icon:              $svg-alert-warning-fill !default;
$c-alert-warning-icon-color:        $html-text-color !default;
$c-alert-warning-bgcolor:           $color-ui-warning !default;
$c-alert-warning-text-color:        $html-text-color !default;

$c-alert-success-icon:              $svg-alert-success-fill !default;
$c-alert-success-icon-color:        $color-white !default;
$c-alert-success-bgcolor:           $color-ui-success !default;
$c-alert-success-text-color:        $color-white !default;

$c-toast-top-position:              $global-unit-small !default;
$c-toast-right-position:            $global-unit-small !default;
$c-toast-width:                     330px !default;
$c-toast-font-size:                 14px !default;
$c-toast-radius:                    0px !default;

/**
 * The alerts component is used to display a short message to the user.
 *
 *
 * 1. So we can position our alert icon.
 * 2. Allows us to control vertical alignments.
 * 3. Force the object to be the full width of its parent. Combined with [2],
 *    this makes the object behave in a quasi-`display: block;` manner.
 * 4. Mobile first padding and font-size.
 * 5. Reset inherited `border-spacing` declarations.
 * 6. Hide the alert.
 * 7. Outro animation when user dismisses an alert.
 * 8. Show alert.

  HTML:
  <div class="c-alert c-alert--error js-toggle-data-state" role="alert" data-state="is-hidden">
    <div class="c-alert__body">You have no chance to survive make your time.</div>
    <div class="c-alert__close"><button class="c-icon-btn c-icon-btn--close is-inverse js-close-btn" type="button" aria-label="Close"><svg class="c-svg c-svg--close-24"><use xlink:href="#c-svg--close-24"></use></svg></button></div>
  </div>

 */
.c-alert {
  position: relative; /* [1] */
  display: table; /* [2] */
  width: 100%; /* [3] */
  margin-bottom:  $global-unit;
  padding: $c-alert-padding-y $c-alert-padding-x-mobile; /* [4] */
  @include font-size($c-alert-font-size, 1.15); /* [4] */
  font-weight: $font-weight-medium;
  border-spacing: 0; /* [5] */
  border-radius: $c-alert-radius;

  @include mq($from: tablet) {
    padding: $c-alert-padding-y $c-alert-padding-x; /* [4] */
  }

  /* [6] */
  &[data-state="is-hidden"] {
    display: none;
    overflow: hidden;
  }

  /* [7] */
  &[data-state="is-dismissed"] {
    @include animation-name(aniFadeOut, aniSlideOutUp);
    @include animation-duration(250ms, 250ms);
    @include animation-delay(250ms, 250ms);
    @include animation-timing-function(ease-out, ease-out);
    @include animation-fill-mode(forwards);
    @include animation-iteration-count(1);
  }

  /* [8] */
  &[data-state="is-active"] {
    @include animation-name(aniStayHidden, aniFadeIn);
    @include animation-duration(250ms, 250ms);
    @include animation-delay(0s, 250ms);
    @include animation-timing-function(linear, ease-out);
    @include animation-fill-mode(forwards);
    @include animation-iteration-count(1);
  }

}



/**
 * Content within an alert object. There should only ever be one.
 *
 * 1. Forces the `.alert__body` to take up all remaining space.
 * 2. Default to aligning content to their middles.
 * 3. Reset inherited `p` and `a` declarations in case some markup gets
 *    inserted into our `.alert__body`.
 * 4. Make sure the last element in the alert body does not have a margin-bottom
 *    so it doesn't mess up our padding.
 */
.c-alert__body {
  display: table-cell;
  width: 100%; /* [1] */
  vertical-align: middle; /* [2] */
  text-align: center;

  /* [3] */
  p {
    margin: 0;
    margin-bottom: $global-unit-small;

    p {
      display: inline-block;
      margin: 0;
      padding: 0;
    }
  }

  /* [3] */
  a {
    @include all-anchor-states() {
      text-decoration: underline;
      color: inherit;
      opacity: 1;
      transition: $opacity-hover-trans;
    }

    &:hover {
      opacity: $opacity-hover-amount;
    }

  }

  /* [3] */
  li {
    line-height: initial;
  }


  &,
  > :last-child {
    margin-bottom: 0; /* [4] */
  }

}


// Separate an important link from the message body.
.c-alert__view-links {
  @include mq($until: tablet) {
    display: block;
    padding-top: $global-unit-small;
  }
  @include mq($from: tablet) {
    display: inline-block;
    margin-left: 4%;
  }
}




/**
 * The container for the notification component's close button.
 */
.c-alert__close {
  position: absolute;
  top:    $c-alert-mobile-close-btn-top;
  right:  $c-alert-mobile-close-btn-right;
  cursor: pointer;
  line-height: 0;

  @include mq($from: tablet) {
    top:    $c-alert-close-btn-top;
    right:  $c-alert-close-btn-right;
  }

}



/* Alert types
   ========================================================================== */

/**
 * Set some defaults for the alert's icon as a pseudo element.
 * NOTE: `.alert--message` doesn't have an icon
 * 1. We want our icon to be placed to the left of the alert message.
 */
.c-alert {

  &.show-icon  {

    @include mq($until: tablet) {
      padding-left: $c-alert-padding-x;
    }

    .c-alert__body {
      text-align: left;
    }

    &:before {
      @include position-y-center();
      left:   $c-alert-icon-left-pos;
      width:  $c-alert-icon-area-width;
      height: $c-alert-icon-area-height;
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: rgba(white, 0.8);
      background-size: unset !important;
      background-position: 0 50%;
    }

    &.is-info:before {
      border-right-color: $c-alert-message-text-color;
      @include svg-bg-img($c-alert-info-icon, $c-alert-icon-size, $c-alert-icon-size, $c-alert-message-text-color); /* [2] */
    }

    &.c-alert--error:before {
      border-right-color: $c-alert-error-text-color;
      @include svg-bg-img($c-alert-error-icon, $c-alert-icon-size, $c-alert-icon-size, $c-alert-error-text-color); /* [1] */
    }

    &.c-alert--warning:before {
      border-right-color: $c-alert-warning-text-color;
      @include svg-bg-img($c-alert-warning-icon, $c-alert-icon-size, $c-alert-icon-size, $c-alert-warning-text-color); /* [1] */
    }

    &.c-alert--success:before {
      border-right-color: $c-alert-success-text-color;
      @include svg-bg-img($c-alert-success-icon, $c-alert-icon-size, $c-alert-icon-size, $c-alert-success-text-color); /* [1] */
    }

  }

}


/**
 * 1. Background color of the alert box.
 */
.c-alert--message {
  background-color: $c-alert-message-bgcolor; /* [1] */

  .c-alert__body {

    &,
    > * {
      color: $c-alert-message-text-color;
    }

  }

}

.c-alert--error {
  background-color: $c-alert-error-bgcolor; /* [1] */

  .c-alert__body {

    &,
    > * {
      color: $c-alert-error-text-color;
    }


  }
}

.c-alert--warning {
  background-color: $c-alert-warning-bgcolor;

  // .c-alert__close-btn .c-svg {
  //   color: $c-alert-warning-text-color;
  // }
  // .c-alert__close .c-icon-btn--close {
  //   &,
  //   &:link,
  //   &:visited {
  //     color: $c-alert-warning-text-color;
  //   }
  // }

  .c-alert__body {

    &,
    > * {
      color: $c-alert-warning-text-color;
    }

  }

}

.c-alert--success {
  background-color: $c-alert-success-bgcolor;

  .c-alert__body {

    &,
    > * {
      color: $c-alert-success-text-color;
    }

  }

}




/* Type variants.
  ========================================================================== */
/* Different types of alert notifications */
.c-alert--toast {
  position: absolute;
  top:   $c-toast-top-position;
  right: $c-toast-right-position;
  margin: 0;
  padding-left:  $global-unit;
  padding-right: $global-unit;
  width: $c-toast-width;
  border-radius: $c-toast-radius;
  @include font-size($c-toast-font-size);
  z-index: 100;

  .c-alert__body {
    text-align: left;
  }

  &[data-state="is-active"] {
    @include animation-name(aniStayHidden, aniFadeInDown);
    @include animation-duration(250ms, 250ms);
    @include animation-delay(0s, 250ms);
    @include animation-timing-function(linear, ease-out);
    @include animation-fill-mode(forwards);
    @include animation-iteration-count(1);
  }

}

.c-alert--toast-bottom-right {
  top: auto;
  left: auto;
  bottom: $global-unit;
}

.c-alert--toast-bottom-left {
  top: auto;
  bottom: $global-unit;
  left: $global-unit;
  right: auto;
}

.c-alert--toast-bottom-right,
.c-alert--toast-bottom-left {
  &[data-state="is-active"] {
    @include animation-name(aniStayHidden, aniFadeInUp);
    @include animation-duration(250ms, 250ms);
    @include animation-delay(0s, 250ms);
    @include animation-timing-function(linear, ease-out);
    @include animation-fill-mode(forwards);
    @include animation-iteration-count(1);
  }
  &[data-state="is-dismissed"] {
    @include animation-name(aniFadeOut, aniSlideOutDown);
    @include animation-duration(250ms, 250ms);
    @include animation-delay(250ms, 250ms);
    @include animation-timing-function(ease-out, ease-out);
    @include animation-fill-mode(forwards);
    @include animation-iteration-count(1);
  }
}



/* Alignment variants.
   ========================================================================== */

/**
 * Vertically align the body-content. Defaults to middle.
 * This will only have an effect if there is a min-height on your `c-alert`
 * component or an element in the `c-alert__body`, like an image, with a height
 * greater than the content.
 */

.c-alert--top {

  > .c-alert__body {
    vertical-align: top;
  }

}

.c-alert--bottom {

  > .c-alert__body {
    vertical-align: bottom;
  }

}




/* Size variants
   ========================================================================== */
/**
 * Change the amount of space around our alert message, between the icon and
 * the alert message, and the size of the icon. Applied @tablet size and above.
 * We probably want to keep the alerts at a standard, smaller, size for mobile.
 */
.c-alert--small {
  padding-top: $global-unit-small;
  padding-bottom: $global-unit-small;
  padding-right: $global-unit;

  &:not(.show-icon) {
    padding-left:  $global-unit;

  }

}

.c-alert--large {
  padding-top: $global-unit-large;
  padding-bottom: $global-unit-large;
  padding-right: $global-unit-large;

  &:not(.show-icon) {
    padding-left:  $global-unit-large;
  }

}




/* Position variants
   ========================================================================== */
/**
 * Set a global header style notification banner. This notification appears
 * at the very top of the page, above the site header, and is sticky.
 *
 * 1. The notifications, or alerts, are placed or loaded dynamically into the
 *    wrapper container which is fixed to the top of the viewport. The wrapper
 *    ensures proper positioning of one or more notifications.
 * 2. Remove any border-radius from the alert so it can fill in the corners of
 *    the viewport.
 * 3. Remove vertical pipe next to icon and reset text alignment to be centered
 *    on global notifications.
 */

.c-notifications-wrapper { /* [1] */

  .c-alert {
    margin-bottom:  $global-unit-micro;
    border-radius: 0; /* [2] */

    &.show-icon {
      @include mq($from: tablet) {
        &::before {
          border-right: 0; /* [3] */
        }
        .c-alert__body {
          text-align: center; /* [3] */
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &[data-state="is-active"] {
      @include translate3d(0,-100%,0);
      @include animation-name(aniSlideInDown);
      @include animation-duration(250ms);
      @include animation-delay(0ms);
      @include animation-timing-function(ease-out);
      @include animation-fill-mode(forwards);
      @include animation-iteration-count(1);
    }

  }
  .smartbanner-show & {
    top: 80px;
  }
}
