/* ========================================================================
   #COLOR FUNCTIONS
   ======================================================================== */

// Slightly lighten a color
// @access public
// @param {Color} $color - color to tint
// @param {Number} $percentage - percentage of `$color` in returned color
// @return {Color}
@function lighten($color, $percentage) {
  @return mix(white, $color, $percentage);
}

// Slightly darken a color
// @access public
// @param {Color} $color - color to shade
// @param {Number} $percentage - percentage of `$color` in returned color
// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}