/* ==========================================================================
   #PROPERTIES
   ========================================================================== */
/**
 * Webkit font-smoothing
 * Values: none, antialiased (default), subpixel-antialiased
 */
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}


// When a list is not a list
@mixin no-list() {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}


/**
 * Make `a` selector a block element and make it fill the width and height of
 * its parent container
 */
@mixin a-block() {
  display: block;
  width: 100%;
  height: 100%;
}


// Generate `:hover` and `:focus` styles in one go.
@mixin hocus() {
  &:hover,
  &:focus {
    @content;
  }
}

// Generate all anchor states in one go.
@mixin all-anchor-states() {
  &,
  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}



/* Use ellipsis to truncate text that overflows its container
 * .simple-usage {
 *   @include truncate();
 * }
 *
 * .detailed-usage {
 *   @include truncate(<value>);
 * }
 */
@mixin ellipsis($width: 100%) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@mixin display-flex() {
  display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance:    $value;
  appearance:         $value;
}


/**
 * Create a text highlighting style
 *
 *   @include selection {
 *     background-color: #value;
 *     color: #value;
 *     text-shadow: none;
 *   }
 */
@mixin selection {
  ::-moz-selection {
    @content;
  }
  ::selection {
    @content;
  }
}


// Calc mixin
@mixin calc($property, $variable, $operation, $value, $fallback) {
  #{$property}: $fallback; //for browsers that don't support calc function
  #{$property}: -mox-calc(#{$variable} #{$operation} #{$value});
  #{$property}: -o-calc(#{$variable} #{$operation} #{$value});
  #{$property}: -webkit-calc(#{$variable} #{$operation} #{$value});
  #{$property}: calc(#{$variable} #{$operation} #{$value});
}


// clip-path
@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}


/**
 * Force wrap a long string of text that breaks the layout, like a URL. 
 * Can also use utility classes `.u-text-wrap` and `.u-text-wrap-nohyphen`
 * in your markup.
 */
@mixin word-wrap($hyphen: auto) {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: $hyphen;
  -moz-hyphens: $hyphen;
  -webkit-hyphens: $hyphen;
  hyphens: $hyphen;
}