/* ==========================================================================
   #CAROUSELS
   ========================================================================== */

// Overriding some settings from the slick-theme.scss
$hero-slick-dot-color:           $color-white !default;
$hero-slick-dot-color-active:    $primary-blue-hover !default;

/**
 * Extending and modifying the Flexslider carousel layout.
 *
 * Related files:
 * `/vendors/slick.scss`, `/vendors/slick-theme.scss`, `/js/slick.min.js`
 *
 *
 * Some standard style overrides you may want to apply to all your
 * `o-carousel` components.
 */
.o-carousel {
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition: $opacity-hover-trans;
  will-change: opacity;

  &.slick-initialized {
    visibility: visible;
    opacity: 1;
  }

}



// Put this on the `li` in your `ul.slides` list.
.o-carousel__item {
  position: relative;
  &:focus {
    outline: none;
  }
}




/* Carousel variants
   ========================================================================== */
/**
 * Hero area carousel - example
 *
 * 1. Move the paging control up into the carousel area.
 * 2. Larger and different color paging nav dots than our default `o-carousel`
 *    overrides above.
 */
.o-carousel--hero {
    height: 480px;
    max-height: 480px;
    overflow: hidden;


    @include mq($from: x-wide) {
      height: 540px;
      max-height: 540px;
    }


    &.slick-dotted.slick-slider {
      margin-bottom: 0px;
    }

    .slick-dots {
      bottom: 8px;
    }


    .o-carousel__item {
      //display: none;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .slick-dots {

      li {

        button {

          &:before {
            @include svg-bg-img($svg-slick-dot, $global-icon-size, $global-icon-size, $hero-slick-dot-color);
          }

        }

        &.slick-active button:before {
          @include svg-bg-img($svg-slick-dot, $global-icon-size, $global-icon-size, $hero-slick-dot-color-active);
        }

      }

    }

}





/**
 * A thumbnail style carousel, where you see more then one slide
 * at a time and page through the "groups" of slides.
 */
.o-carousel--thumbs {
  width: auto;
  margin: 0;
  margin-left:  -$global-unit;
  margin-right: -$global-unit;
  opacity: 0;
  visibility: hidden;
  transition: $opacity-hover-trans;
  will-change: opacity;

  .o-wrapper--tips-history &,
  &.slick-initialized {
    visibility: visible;
    opacity: 1;
  }

  @include mq($from: tablet) {
    margin-left: 0;
    margin-right: 0;
  }


  .slick-list {
    @include mq($from: tablet) {
      margin-left: -$global-unit;
    }

    @if ($inuit-use-markup-fix == false) {
      font-size: 0;
    }

    .o-section--tips & {
      @include mq($from: tablet) {
        overflow: visible;
      }
    }
  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }


  .c-form--has-validation & {
    margin-left: 0;
    margin-right: 0;
  }

  .o-carousel__item {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    
    @include mq($from: tablet) {
      padding-left: $global-unit;
    }

    @if ($inuit-use-markup-fix == false) {
      font-size: $inuit-global-font-size;
      font-size: 1rem;
      line-height: initial;
    }

    // .o-section--coaching & {

    //     @include mq($from: tablet) {
    //       width: 33.33333%;
    //     }

    //     @include mq($from: desktop) {
    //       width: 25%;
    //     }

    // }

  }

  .slick-dots {
    bottom: -47px;
  }

  .slick-prev,
  .slick-next {
    top: auto;
    bottom: -55px;
    height: 32px;
    width:  32px;
    @include translate(0, 0%);
    z-index: 100;
    
    // &:before {
    //   @include position-center();
    //   width:  100%;
    //   height: 100%;
    // }

    @include mq($from: desktop) {
      top: 50%;
      bottom: auto;
      height: 42px;
      width:  42px;
      @include translate(0, -70%);
    }

  }

  .slick-prev {
    @include mq($until: desktop) {
      left: $global-unit-small;

      .c-form--has-validation & {
        left: 0;
      }

    }

    @include mq($from: desktop) {
      left: -72px;
    }

  }

  .slick-next {
    @include mq($until: desktop) {
      right: $global-unit-small;

      .c-form--has-validation & {
        right: 0;
      }

    }

    @include mq($from: desktop) {
      right: -72px;
    }

  }

}


.o-carousel--trends {
  width: auto;
  margin: 0 auto;

  opacity: 0;
  visibility: hidden;
  transition: $opacity-hover-trans;
  will-change: opacity;

  &.slick-initialized {
    visibility: visible;
    opacity: 1;    
  }

  .slick-prev,
  .slick-next {
    top: auto;
    bottom: -55px;
    height: 32px;
    width:  32px;
    @include translate(0, 0%);
    z-index: 100;

    &:before {
      @include position-center();
      width:  100%;
      height: 100%;
    }

    @include mq($from: desktop) {
      top: 50%;
      bottom: auto;
      height: 42px;
      width:  42px;
      @include translate(0, -70%);
    }

  }

  .slick-prev {
    @include mq($until: desktop) {
      left: $global-unit-small;
    }

    @include mq($from: desktop) {
      left: -72px;
    }

  }

  .slick-next {
    @include mq($until: desktop) {
      right: $global-unit-small;
    }

    @include mq($from: desktop) {
      right: -72px;
    }

  }

}