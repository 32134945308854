/* ==========================================================================
  #TYPOGRAPHY
  ========================================================================== */

// Set the default fonts to be used on specific elements;
// Font variables found in settings.typography

// Page
html {
  font-family: $html-font-family;
  font-weight: $html-font-weight;
  color: $html-text-color;
}



/* Headings
   ========================================================================== */

/* stylelint-disable selector-list-comma-newline-after */

h1, h2, h3, h4, h5, h6 {

  &, a, span {
    font-family: $h-headings-font-family;
    font-weight: $h-headings-font-weight;
    color: $h-headings-color;
    text-rendering: optimizeLegibility;
    color: currentColor;
  }

}

/* stylelint-enable selector-list-comma-newline-after */

h1 {

  &, a, span {
    font-weight: $h1-font-weight;
  }
 
}

h2 {

  &, a, span {
    font-weight: $h2-font-weight;
  }
 
}

h3 {

  &, a, span {
    font-weight: $h3-font-weight;
  }
 
}

h4 {

  &, a, span {
    font-weight: $h4-font-weight;
  }

}

h5 {

  &, a, span {
    font-weight: $h5-font-weight;
  }

}


h6 {

  &, a, span {
    font-weight: $h5-font-weight;
  }

}




/* Text elements
   ========================================================================== */

b,
strong {
  font-weight: $font-weight-bold;
}


a {
  transition: $a-hover-trans;
  text-decoration: none;

  &:link {
    color: $a-link-color;
  }

  &:visited {
    color: $a-visited-color;
  }

  &:hover {
    color: $a-hover-color;
    text-decoration: underline;
  }

  &:active {
    color: $a-active-color;
  }

  &:hover,
  &:active {
    cursor: pointer;
  }

}


cite {
  display: inline-block;
  margin: $global-unit-small 0;
  padding: $global-unit-tiny;
  border-radius: 3px;
  color: $color-grey90;
  @include font-size(11px, 1);
  font-weight: $font-weight-light;
  font-style: italic;
  background-color: $color-grey5;
}

// In-browser text highlighting
@include selection {
  background-color: $secondary-yellow; 
  color: $color-black; 
  text-shadow: none;
}

// Override /inuitcss/generic/_generic.shared.scss and user-agent defaults
address,
fieldset {
  font: inherit;
  margin-bottom: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

code {
  font-family: $font-family-monospace;
}