﻿/* ==========================================================================
#SECTIONS
========================================================================== */
// Define sections of content on a page
.o-section {
  position: relative;
  padding-top: $global-unit;
  padding-bottom: $global-unit-large;
  background-color: $color-white;
  @include clearfix();


  @include mq($from: tablet) {
    padding-top: $global-unit-large + $global-unit-small;
    padding-bottom: $global-unit-large + $global-unit-small;
  }

}






/* Size variations
========================================================================== */
.o-section--flush {
  padding: 0; // no top and bottom padding
}


.o-section--flush-top {
  padding-top: 0; // no top and bottom padding
}


.o-section--flush-bottom {
  padding-bottom: 0; // no top and bottom padding
}


.o-section--small-top {
  padding-top: $global-unit-small;
}

.o-section--small {
  padding-top: $global-unit-small;
  padding-bottom: $global-unit-small;
}

.o-section--large {
  padding-top: $global-unit-large;
  padding-bottom: $global-unit-large;
}



// Place a vertical divider in the middle of the section
.o-section--column-divider {

  .o-wrapper {
    position: relative;

    @include mq($from: tablet) {

      &:before {
        display: block;
        position: absolute;
        top: 0;
        @include calc(left, 50%, "-", 1px, 50%);
        width: 0;
        height: 100%;
        content: "";
        border-right-style: solid;
        border-right-color: $neutral-light;
        border-right-width: 1px;
      }

    }

  }

}




// Put a top border on a section at mobile screen sizes
.o-section\@mobile {
  border-top: 1px solid $neutral-light;

  @include mq($from: tablet) {
    border: none;
  }

}




/* Background colors
========================================================================== */

.o-section--lightgrey {
  background-color: $neutral-ulight;
}



/* Section variants
========================================================================== */
.o-section--sleep-score {
  padding-top: 60px;
  padding-bottom: $global-unit-small;
  background-color: $neutral-ulight;

  @include mq($from: desktop) {
    padding-top: $global-unit-large;
    padding-bottom: $global-unit-large;
  }

  .o-wrapper {
    @include mq($until: tablet) {
      padding-left: $global-unit-small;
      padding-right: $global-unit-small;
    }
  }
}

.o-section--history-nav {
  padding-top: $global-unit;
  padding-bottom: $global-unit-large;

  @include mq($from: tablet) {
    padding-top: $global-unit-large + $global-unit-small;
  }

}


.o-section--trends {
  background-color: $neutral-ulight;
  overflow: hidden;

  > .o-wrapper {
    padding-left:  0;
    padding-right: 0;

    @include mq($from: tablet) {
      padding-left:  0;
      padding-right: 0;
    }
  }
}


.o-section--tips {

  .c-heading-main.u-h1 {
    @include font-size(24px, 1, true);
  }

}

.o-section--my-equipment {
  @include mq($until: desktop) {
    padding-top: 0;
  }
}

.o-section--countries {
  padding-top: $global-unit-large;
  padding-bottom: $global-unit-large;
  background-color: transparent;

  .o-wrapper {

    @include mq($until: tablet) {
      width: 100% !important;
      max-width: 100% !important;
      padding-right: 0 !important;
      padding-left:  0 !important;
    }

  }


  .o-section--countries__item {
    border-bottom: 1px solid $neutral-light;

    &:first-child {
      border-top: 1px solid $neutral-light;
    }

    @include mq($from: tablet) {

      &,
      &:first-child {
        border: none;
      }

    }

  }

}


.o-section-repeater {

  .o-section-repeater__heading {
    vertical-align: baseline;

    @include mq($until: tablet) {
      text-align: center;
    }

    .c-heading-section {
      margin-bottom: 0;
    }

  }

  .o-section-repeater__link {
    vertical-align: baseline;

    @include mq($from: tablet) {
      text-align: right;
    }

    .c-btn--text {
      margin-bottom: 0;
    }

  }

}

.o-section--feedback {

  .o-widget--ratings {
    margin-top: $global-unit-large;
    margin-left: auto;
    margin-right: auto;

    .o-widget__body {
      display: none;
    }

    .o-widget__btn {
      padding: 0 24px;
    }

    .c-icon-btn-thumbs {
      width:  80px;
      height: 80px;
    }
  }

  .o-widget--error {
    margin-bottom: $global-unit-large;
  }

  .c-heading-sub,
  .o-widget--form {
    text-align: center;
    width: 100% !important;
  }

}

.o-section--rmd-nav {
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #232323;
}

.o-section--full-page-back-btn {
  padding: $global-unit;

  @include mq($from: tablet) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}




/* Content dividers
========================================================================== */
.o-section--faq-divider {
  display: block;
  margin: 0;
  margin-bottom: $global-unit-large;
  padding: 0;
  padding-top: $global-unit;
  width: 100%;
  height: 0;

  &:first-of-type {
    border-bottom: 1px solid $global-border-color;
  }
}



/**
 * Turn off scrolling on the body that so that the site doesn't scroll in the
 * background when the date calendar overlay is active on a mobile screen.
 */
 .no-scroll {

  @include mq($until: tablet) {
    overflow: hidden;
    height: 100vh;
  }

}

.no-scroll--video {
  overflow: hidden;
  height: 100vh;
}

.o-page--countrySelection {

  @include mq($from: tablet) {
    background-color: rgba($color-white, 0.5);
    background-image: url(/assets/images/countryMap.png);
    background-repeat: no-repeat;
    background-position: 50% 55vh;
  }

  // @include mq($from: wide) {
  //   background-position: 50% 540px;
  // }


  form,
  mpp-root {

    @include mq($from: tablet) {
      background-image: linear-gradient(rgba($color-white, 0), rgba($color-white, 0.9));
    }

    @include mq($from: wide) {
      background-image: linear-gradient(rgba($color-white, 0), rgba($color-white, 0), rgba($color-white, 0.9));
    }

  }

}

// Fix for static HTML coaching pages
.is-coaching-html {
  .o-section {
    padding-top: 0;
    padding-bottom: 0;

    .o-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
