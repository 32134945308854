/* ==========================================================================
   #TRANSFORMS
   ========================================================================== */
// Transform in a transition
@mixin transformsition($transforms) {
  transition: -webkit-transform $transforms;
  transition: -moz-transform $transforms;
  transition: -ms-transform $transforms;
  transition: transform $transforms;
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

// Rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin rotateZ($deg) {
  @include transform(rotateZ(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// ScaleX
@mixin scaleX($scale) {
  @include transform(scaleX($scale));
}

// ScaleY
@mixin scaleY($scale) {
  @include transform(scaleY($scale));
}

// Scale3d
@mixin scale3d($x, $y, $z) {
  @include transform(scale3d($x, $y, $z));
}

// Translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// TranslateY
@mixin translateY($y) {
  @include transform(translateY($y));
}

// TranslateX
@mixin translateX($x) {
  @include transform(translateX($x));
}

// TranslateZ
@mixin translateZ($z) {
  @include transform(translateZ($z));
}

// Translate 3D
@mixin translate3d($x, $y, $z) {
  @include transform(translate3d($x, $y, $z));
}

// Skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}
