/* ========================================================================
   #COLORS
   ======================================================================== */
// A pallette color variables.



/* Product colors
   ========================================================================== */
$primary-black:             #424243 !default;

$primary-blue:              #1788c3 !default;
$primary-blue-hover:        #3392CD !default;
$primary-blue-active:       #0077c0 !default;
$secondary-blue:            #ebf7fd !default;

$primary-red:               #ed1b2f !default;
$secondary-red:             #da1f3d !default;
$secondary-red-hover:       #d12244 !default;

$secondary-green:           #6cb454 !default;
$secondary-purple:          #6f2c91 !default;
$secondary-yellow:          #ffde55 !default;
$secondary-amber:           #ffc344 !default;
$secondary-white:           white !default;




/* Neutral and greyscale
   ========================================================================== */
// Neutrals
$neutral-xheavy:            #787878 !default;
$neutral-heavy:             #6d6d6d !default;
$neutral-medium:            #b7b7b8 !default;
$neutral-light:             #d9d8d5 !default;
$neutral-xlight:            #e6e6e7 !default;
$neutral-ulight:            #f6f6f6 !default;

// Greyscale
$color-black:               hsl(0, 0%, 0%);    // #000
$color-grey90:              hsl(0, 0%, 10%);   // #1a1a1a
$color-grey80:              hsl(0, 0%, 20%);   // #333
$color-grey70:              hsl(0, 0%, 30%);   // #4d4d4d
$color-grey60:              hsl(0, 0%, 40%);   // #666
$color-grey50:              hsl(0, 0%, 50%);   // #808080
$color-grey40:              hsl(0, 0%, 60%);   // #999
$color-grey30:              hsl(0, 0%, 70%);   // #b3b3b3
$color-grey20:              hsl(0, 0%, 80%);   // #ccc
$color-grey10:              hsl(0, 0%, 90%);   // #e6e6e6
$color-grey5:               hsl(0, 0%, 95%);   // #f2f2f2
$color-white:               hsl(0, 0%, 100%);  // #fff



/* UI colors
   ========================================================================== */
// Reassign your literal color variables to meaninful ones for use with 
// UI components
$color-ui-success:          $secondary-green !default;
$color-ui-error:            $secondary-red !default;
$color-ui-warning:          $secondary-amber !default;

$color-dark-mode-bg:        #212935 !default;
$color-dark-mode-text:      $neutral-light !default;


// Status colors - used with tags and status indicator dot components
$status-primary:            #2062AF !default;
$status-primary-bg:         #d1e7f3 !default;

$status-secondary:          $neutral-ulight !default;
$status-secondary-bg:       $neutral-xheavy !default;

$status-success:            #026700 !default;
$status-success-bg:         #D1F3DA !default;

$status-warning:            #DB490A !default;
$status-warning-bg:         #FFE2CE !default;

$status-danger:             #C9234A !default;
$status-danger-bg:          #FBD1D5 !default;

$status-dark:               $neutral-xlight !default;
$status-dark-bg:            $color-grey80 !default;

$status-light:              $primary-black !default;
$status-light-bg:           $neutral-xlight !default;



/* Color maps
   ========================================================================== */
// Color maps are useful for creating class names dynamically

// Brand colors
$brand-colors: (
  'primary-black':           $primary-black,
  'secondary-white':         $secondary-white,
  'primary-blue':            $primary-blue,
  'primary-blue-hover':      $primary-blue-hover,
  'primary-blue-active':     $primary-blue-active,
  'secondary-blue':          $secondary-blue,
  'primary-red':             $primary-red,
  'secondary-red':           $secondary-red,
  'secondary-red-hover':     $secondary-red-hover,
  'secondary-green':         $secondary-green,
  'secondary-purple':        $secondary-purple,
  'secondary-yellow':        $secondary-yellow,
  'secondary-amber':         $secondary-amber,
  'neutral-heavy':           $neutral-heavy,
  'neutral-medium':          $neutral-medium,
  'neutral-light':           $neutral-light,
  'neutral-xlight':          $neutral-xlight,
  'neutral-ulight':          $neutral-ulight
) !default;


// Greyscale colors
$greyscale: (
  'black':                   $color-black,
  'grey90':                  $color-grey90,
  'grey80':                  $color-grey80,
  'grey70':                  $color-grey70,
  'grey60':                  $color-grey60,
  'grey50':                  $color-grey50,
  'grey40':                  $color-grey40,
  'grey30':                  $color-grey30,
  'grey20':                  $color-grey20,
  'grey10':                  $color-grey10,
  'grey5':                   $color-grey5,
  'white':                   $color-white
) !default;


// Material design color map
$material-colors: (
  'red':                    #f44336,
  'pink':                   #e91e63,
  'purple':                 #9c27b0,
  'deep-purple':            #673ab7,
  'indigo':                 #3f51b5,
  'blue':                   #2196f3,
  'light-blue':             #03a9f4,
  'cyan':                   #00bcd4,
  'teal':                   #009688,
  'green':                  #4caf50,
  'light-green':            #8bc34a,
  'lime':                   #cddc39,
  'yellow':                 #ffeb3b,
  'amber':                  #ffc107,
  'orange':                 #ff9800,
  'deep-orange':            #ff5722,
  'blue-grey':              #607d8b
) !default;
