﻿/* ==========================================================================
   #SLEEP NIGHT PICKER
   ========================================================================== */
/**
 * Date picker control on the dashboard. Changes the sleep score dial and score
 * metrics.
 */
 #sleepSection {
  text-align: center;
 }

.c-sleep-night {
  position: relative;
  display: inline-block;
  width: auto;
  margin-top: 0px;
  margin-left: auto;
  margin-bottom: $global-unit-small;
  margin-right: auto;
  padding: 0;
  text-align: center;

  @include mq($from: tablet) {
    @include animation(aniStayHidden 1s, aniFadeInDown 250ms 1s 1 ease-out);
  }

}


.c-sleep-night__heading {
  margin-bottom: $global-unit-tiny;
  @include font-size(14px, 1);
  text-align: center;
}

/* 1. Needed as buffer for mousing to the date picker without it disappearing */
.c-sleep-night__menu {
  position: relative;
  display: inline-block;
  width: 280px;
  margin: 0 auto;
  border-top: 6px solid transparent; /* [1] */
  border-bottom: 6px solid transparent; /* [1] */

  @include clearfix();

  @include mq($from: desktop) {
    width: 286px;
  }

  @include mq($from: wide) {
    width: 312px;
  }

  // menu icon
  > .c-svg {
    position: absolute;
    top: 50%;
    right: $global-unit-small;
    @include font-size(16px, 1);
    color: $html-text-color;
    transition: color 250ms ease-out;
    @include translateY(-50%);
  }

  &:hover,
  &.is-active {

    > .c-svg {
      color: $primary-blue;
      cursor: pointer;
    }

  }

}



.c-sleep-night__menu-current-date {
  display: block;
  padding: $global-unit-small $global-unit-large $global-unit-small $global-unit;
  font-family: $font-family_sans-serif;
  @include font-size(14px, 1);
  font-weight: $font-weight-bold;
  color: $html-text-color;
  cursor: pointer;
  text-align: left;
  border: 2px solid $html-text-color;
  transition: border-color 250ms ease-out;
  border-radius: 420px;

  &:hover {
    border-color: $primary-blue;
  }

  @include mq($from: wide) {
    @include font-size(16px, 1);;
  }

}


/**
 * Default position of bubble is below target
 *
 * 1. Place the bubble above and center of the target area.
 */
.c-sleep-night-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  border: none;
  background-color: $primary-blue;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;
  @include clearfix();

  @include mq($from: tablet) {
    position: absolute;
    top: 100%; /* [1] */
    left: 50%; /* [1] */
    bottom: auto;
    right: auto;
    // width: 431px;
    width: auto;
    margin-top: $global-unit-tiny;
    padding: $global-unit-tiny;
    color: $neutral-ulight;
    text-align: left;
    box-shadow: 0px 15px 16px -7px rgba(0, 0, 0, 0.3);
    @include translate(-50%, -10px);
    transition: $global-transition;
    border-radius: $global-radius;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 100%;
    }

    &:before {
      border: 12px solid transparent;
      border-bottom-color: $color-white;
      margin-left: -12px;
    }

    &:after {
      border: 11px solid transparent;
      border-bottom-color: $primary-blue;
      margin-left: -11px;
    }

  }

  // @include mq($from: desktop) {
  //   width: 363px;
  // }

  // @include mq($from: wide) {
  //   width: 431px;
  // }

  .c-sleep-night__menu.is-active & {
    display: block;
    opacity: 1;
    visibility: visible;
    will-change: opacity;

    @include mq($from: tablet) {
      @include translate(-50%, 0px);
    }

  }

}


.c-sleep-night-popup__header {
  position: relative;
  display: block;
  margin: 0;
  padding: $global-unit-small $global-unit-medium;
  border-bottom: 1px solid $color-white;

  @include mq($from: tablet) {
    display: none;
  }
}

.c-sleep-night-popup__heading {
  color: $color-white;
  margin-bottom: 0;
  font-weight: $font-weight-light;
}


.c-sleep-night-popup__scrollable {

  @include mq($until: tablet) {
    position: static;
    height: 100vh;
    margin: 0;
    padding-bottom: 100px;
    backface-visibility: hidden;
    overflow-y: scroll;
    will-change: overflow;
  }

}



.c-sleep-night-popup__close {
  position: absolute;
  top: 50%;
  right: 6px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  @include translateY(-50%);
  transition: background-color 250ms ease-out;
  z-index: 201;

  .c-svg {
    width: 100%;
    height: 100%;
    transition: color 250ms ease-out;
  }

  &,
  &:link,
  &:visited,
  &:active,
  &:focus,
  .c-svg {
    color: $color-white !important;
  }

  &:hover {
    background-color: $color-white;
    text-decoration: none;

    .c-svg {
      color: $color-grey70 !important;
      text-decoration: none;
    }
  }
}


.c-sleep-night-scores {
  list-style: none;
  margin: 0;
  padding: 0;
  @include display-flex();
  flex-flow: column;

  @include mq($from: tablet) {
    max-width: 444px;
    width: max-content;
    align-content: flex-start;
    flex-flow: row wrap;
  }

  @include mq($from: desktop, $until: laptop) {
    max-width: 376px;
  }

}

mpp-score-date-picker-item {
  display: inline-block;
  border-bottom: 1px solid rgba($color-white, 0.4);

  @include mq($from: tablet) {
    max-width: 74px;
    flex: 0 1 16.6666666667%;
    border-bottom: 0;
  }

  @include mq($from: desktop, $until: laptop) {
    max-width: 62px;
  }

}


// mpp-score-date-picker-item {
//   @include mq($from: tablet) {
//     width: 16.666666666666667%;
//   }
// }

.c-sleep-night-scores__item {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.c-sleep-night-score-tile {
  @include display-flex();
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 0;
  margin: 0;
  padding: $global-unit-small;
  color: $color-white;

  @include transform($bgcolor-hover-trans);
  transition: $global-transition;
  @include clearfix();

  @include mq($from: tablet) {
    flex-direction: column;
    padding: $global-unit-tiny;
    background-color: transparent;
    border: 2px solid $primary-blue;
    border-radius: $global-radius;
  }

  .c-sleep-night-scores__item.is-active & {
    border-color: $color-white;
    background-color: rgba($color-white, 0.2);
  }

  &:hover {
    background-color: rgba($color-white, 0.2);
    text-decoration: none;
  }

}


.c-sleep-score-dial {
  position: relative;
  display: block;
  padding: 0;
  width: 36%;
  color: $color-white;

  @include mq($from: tablet) {
    width:  58px;
    height: 42px;
  }

  .c-sleep-night-scores__item.has-data & {
    height: 42px;

    &:before {
      @include position-center();
      display: block;
      width:  42px;
      height: 42px;
      color: $color-black;
      text-align: center;
      @include font-size(16px, 35px);
      font-weight: $font-weight-medium;
      // letter-spacing: -0.8px;
      background-color: $color-white;
      content: attr(data-score);
      border-radius: 50%;
      border: 4px solid #6dcff6;
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.4);
    }
  }

  .c-sleep-night-scores__item.no-data & {

    @include mq($from: tablet) {
      @include display-flex();
      align-items: center;
    }

    .no-data-msg {
      display: block;
    }

  }

}



.c-sleep-score-date {
  flex-grow: 1;
  padding-left: 12px;
  text-align: left;
  color: $color-white;
  @include font-size(14px, 1.15);
  font-weight: $font-weight-medium;

  @include mq($from: tablet) {
    padding-left: 0;
    padding-top: 6px;
    @include font-size(12px, 1);
    text-align: center;
  }


  .c-sleep-score-date-full {
    display: inherit;

    @include mq($from: tablet) {
      display: none !important;
    }

  }

  .c-sleep-score-date-short {
    display: none !important;

    @include mq($from: tablet) {
      display: inherit !important;
    }

  }

}


.no-data-msg {
  display: none;
  @include font-size(14px, 1.15);
  font-weight: $font-weight-medium;
  text-align: center;

  @include mq($from: tablet) {
    @include font-size(10px, 1.15);
  }
}
