/* ==========================================================================
   #CONFIG
   ========================================================================== */

// Config-level settings (environment switches, debugging modes, etc.).

$config: (
  env: dev,
  healthcheck: false,
  debug: false,
);

@function config($key) {
  @return map-get($config, $key);
}
