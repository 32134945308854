﻿/* ==========================================================================
   #LANGUAGE MENU
   ========================================================================== */
// Language selection dropdown on Home page

.c-language-menu {
  // position: relative;
  // padding: 0 $global-unit-small $global-unit-tiny $global-unit-small;
  z-index: 9999;
  @include clearfix();

  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;

  // @include mq($from: tablet) {
      position: absolute;
      top: 6px;
      right: 24px;
      padding: 0;
  // }

  .lang-menu-active & {
    z-index: 100000;
  }

}

// Trigger for the off-canvas language menu
.c-language-menu__target {
  position: fixed;
  top: 0;
  left: 0;

  &:target+.c-language-menu__sub-menu {
    @include transform(none);
    opacity: 1;
    visibility: visible;
    will-change: opacity;
  }

}


.c-language-menu__close {
  display: inline-block;
  padding: $global-unit-small;
  line-height: 1;

  @include mq($from: tablet) {
    display: none;
  }

  .c-svg {
    top: 0;
    width:  24px;
    height: 24px;
    color: $color-white;
  }

}


.c-language-menu__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  min-width: 106px;
  @include font-size(11px, 2.1818181818);
  float: right;
}



.c-language-menu__item {
  list-style-type: none;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  // border-bottom: 1px solid rgba($color-white, 0.3);
}



.c-language-menu__link {
  @include a-block();
  padding: $global-unit-small $global-unit;
  @include font-size(18px);
  font-weight: $font-weight-medium;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  transition: $bgcolor-hover-trans;
  will-change: background-color, color;
  @include clearfix();
  
  &,
  &:link,
  &:visited {
    color: $color-white;
  }

  @include mq($from: tablet) {
    padding: $global-unit-small $global-unit-small $global-unit-tiny;
    @include font-size(11px);
    text-align: left;
  }

  @include mq($from: desktop) {
    padding: 2px $global-unit-tiny;
  }


  &:hover,
  &:active {
    color: $primary-blue;
    background-color: $secondary-blue !important;
    text-decoration: none;
  }

}



.c-language-menu__current {
  position: relative;
  display: block;
  padding: 0 $global-unit 0 $global-unit-tiny;
  border: 1px solid $neutral-ulight;
  background-color: $neutral-ulight;  

  &,
  &:link,
  &:visited {
    color: $neutral-heavy;
  }


  &:before { // arrow icon
    position: absolute;
    top: 50%;
    right: $global-unit-micro;
    transition: background-image 250ms ease-out;
    @include translateY(-50%);
  }


  &:hover,
  &:focus,
  &:active,
  .c-language-menu__item:hover & {
    color: $primary-blue;
    text-decoration: none;
    cursor: pointer;

    &.c-icon--drop-down-arrow:before {
      @include svg-bg-img($svg-ddlb-arrow, $global-icon-size, $global-icon-size, $primary-blue);
    }

  }

}



.c-language-menu__sub-menu {
  list-style: none;
  margin: 0;
  padding: 0;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  white-space: nowrap;
  background-color: $primary-blue-hover;
  border: 1px solid $primary-blue-hover;
  box-shadow: 0px 15px 16px -7px rgba(0, 0, 0, 0.3);
  @include translateY(-100%);
  transition: $global-transition;
  will-change: transform;
  z-index: 2;
  visibility: hidden;
  opacity: 0;

  // Angular mobile nav trigger
  &.show-mobile-language {
    @include mq($until: tablet) {
      @include transform(none);
      opacity: 1;
      visibility: visible;
      will-change: opacity;
    }
  }

  @include mq($until: tablet) {
    overflow-x: scroll;
  }


  @include mq($from: tablet) {
    position: absolute;
    top: 100%;
    height: auto;
    left: 0;
    z-index: 1;
    width: 100%;
    min-width: 100%;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    border-radius: 0 0 $global-radius $global-radius;
    @include translateY(-10px);

    .c-language-menu__item {
      border: none;
    }

    .c-language-menu__item:hover & {
      opacity: 1;
      visibility: visible;
      will-change: opacity;
      @include translateY(0);
    }

  }

}




.c-language-menu__flag {
  display: block;
  width: 25px;
  height: 24px;
  float: right;
  margin: 0 10px 0 0;
  overflow: hidden;

  @include mq($from: tablet) {
    height: 28px;
  }

  span {
    position: relative;
    display: block;
    @include hide-text();
    width: 100%;
    height: 100%;

    &:before {
      top: 50%;
      @include translateY(-50%);
    }

  }

}