/* ==========================================================================
  #LAYOUT
  ========================================================================== */
// Extending the inuitcss `o-layout` object.


/* Inline group.
   ========================================================================== */

/**
 * Allows us to use the `o-layout` object to group any number of items together
 * inline, such as buttons, without using the grid or column-style structure of
 * the `o-layout` object.
 *
 * 1. Remove the default width of 100% from the `.o-layout__item` class.
 * 2. Mobile-first bottom margin for each item since they will likely be stacked
 *    at mobile screen sizes.
 */
.o-layout--inline-group-always {

  > .o-layout__item {
    width: auto; /* [1] */
    margin-bottom: $global-unit-small; /* [2] */

    @include mq($from: tablet) {
      width: auto; /* [1] */
      margin-bottom: $global-unit;
    }

  }

}




/* Gutter size modifiers.
   ========================================================================== */

// Extra tiny gutter width between items
.o-layout--micro {
  margin-left: -$global-unit-micro;

  > .o-layout__item {
    padding-left: $global-unit-micro;
  }

}

.o-layout--login {

  @include mq($from: tablet) {
    margin-left: -$global-unit-large;

    > .o-layout__item {
      padding-left: $global-unit-large;
    }

  }

  @include mq($from: desktop) {
    margin-left: -$global-unit-huge;

    > .o-layout__item {
      padding-left: $global-unit-huge;
    }   

  }

}



/* Dividers
   ========================================================================== */

// Section divider with horizontal border
.o-layout--section {
  margin-bottom: $global-unit-large;
  padding-bottom: $global-unit;
  margin-right: -$global-unit;
  padding-right: $global-unit;
  border-bottom: 1px solid $global-border-color;

  .o-section & {

    &.o-section-repeater {

        &:last-of-type:not(:only-of-type) {
          border: none;
          margin-bottom:  0;
          padding-bottom: 0;
        }

    }

  }

}

.o-layout--tips-equipment {
  @include mq($from: desktop) {
    margin-left: -$global-unit-large;
  }

  @include mq($from: x-wide) {
    margin-left: -$global-unit-huge;
  }

  > .o-layout__item {
    @include mq($from: desktop) {
      padding-left: $global-unit-large;
    }

    @include mq($from: x-wide) {
      padding-left: $global-unit-huge;
    }

  }

}


.o-layout--my-equipment {
  @include mq($from: desktop) {
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      left:0;
      content: "";
      width: 1px;
      height: 100%;
      border-left: 1px solid $neutral-medium;
    }

  }

  @include mq($from: x-wide) {
    &:before {
      left: -$global-unit;
    }
  }

}


// Section divider with just spacing
.o-layout--component {
  margin-bottom: $global-unit-large;
  padding-bottom: $global-unit;
}



.o-layout--action-btns {
  @include mq($from: tablet) {
    text-align: right;
  }

  .c-btn {
    margin-bottom: $global-unit;
  }
}

.o-layout--sticky-left-nav {
  @include mq($from: desktop) {
    @include position-sticky();
    top: $global-unit;
  }
}

.o-layout--page-nav {
  margin-bottom: $global-unit-large;
  background-color: shade($color-white, 2%);
}

// Taking the inuitcss class and making it at tablet and above.
.o-layout--auto\@tablet {

  > .o-layout__item {

    @include mq($from: tablet) {
      width: auto;
    }
    
  }

}