@use 'sass:math';
/* ==========================================================================
   #SVG
   ========================================================================== */
/**
 *  SVG as background-image
 *  Example usage:
 *  1. Create a variable in `settings.global.scss` of just the svg's data: 
 *  2. Use that variable in the mixin, provide the width and height of the SVG's 
 *     viewbox, and define the color in HEX format without the #.
 *  
 *  $my-svg: "M7.035 15.931l16.533-15.931 1.398 1.466-15.067 14.534 15.067 14.534-1.398 1.466-16.533-16.069z";
 *
 *  @include svg-bg-img($my-svg, $global-icon-size, $global-icon-size, 'ffffff');
 *
 */

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return ($number);
}

/// Encode # in hex value
/// @param {String} $string - Color HEX value
/// @return {String} - HEX value with # encode to '%23'
@function encodecolor($string) {
	@if type-of($string) == 'color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string:unquote("#{$hex}");
  }
  $string: '%23' + $string;
	@return $string;
}


@mixin svg-bg-img($svg, $width, $height, $color) {
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg width='#{strip-unit($width)}' height='#{strip-unit($height)}' viewBox='0 0 #{strip-unit($width)} #{strip-unit($height)}' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='#{encodecolor($color)}' d='#{$svg}'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover
}

// SVG with two data elements
@mixin svg2-bg-img($svg1, $svg2, $width, $height, $color) {
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg width='#{strip-unit($width)}' height='#{strip-unit($height)}' viewBox='0 0 #{strip-unit($width)} #{strip-unit($height)}' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='#{encodecolor($color)}' d='#{$svg1}'/%3E %3E%3Cpath fill='%23#{$color}' d='#{$svg2}'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover
}