/* ==========================================================================
   #ANCHORS
   ========================================================================== */

/**
 * The anchors object is a design pattern similar to the media object, however
 * it is used for creating links or in-page anchors, with or without icons.
 * For example, social media links, an `+ Add new` or `X Close` link, or button-y
 * style in-page anchors.
 *
    A link with an icon:

    <a href="{url}" class="o-anchor">
        <i class="o-anchor__icon my-icon--class"></i>
        <span class="o-anchor__text">Link text</span>
    </a>
 *
 *
    A button-y still anchor, could also have an icon:

    <a href="#my-target" class="o-anchor o-anchor--btn">
        <span class="o-anchor__text">Section One</span>
    </a>

 */

.o-anchor {
  position: relative;
  display: inline-block;
  transition: $opacity-hover-trans;
  opacity: 1;
  @include clearfix();

  &,
  &:link,
  &:visited {
    color: $color-grey70;
    font-weight: $font-weight-medium;
  }

  &:hover {
    opacity: $opacity-hover-amount;
    text-decoration: none;
  }

}

  .o-anchor__icon {
    float: left;
    margin-right: $global-unit-tiny;

    &:before {
      display: block;
      width: 24px;
      height: 24px;
      color: currentColor;
      @include font-size(20px);
    }

  }


  .o-anchor__text {
    overflow: hidden;
    display: block;

    &,
    > :last-child {
      margin-bottom: 0;
    }
  }





/* Group multiple anchors inline
   ========================================================================== */

/**
 * Place a bunch of `o.anchor` objects inside a group wrapper to display them
 * inline together.
 */

.o-anchor-group {

  .o-anchor {
    margin-left: $global-unit;
  }

}


.o-anchor-group-micro {

  .o-anchor {
    margin-left: $global-unit-micro;
  }

}


.o-anchor-group-tiny {

  .o-anchor {
    margin-left: $global-unit-tiny;
  }

}


.o-anchor-group-small {

  .o-anchor {
    margin-left: $global-unit-small;
  }

}


.o-anchor-group-large {

  .o-anchor {
    margin-left: $global-unit-large;
  }

}


.o-anchor-group-huge {

  .o-anchor {
    margin-left: $global-unit-huge;
  }

}


[class*='o-anchor-group'] {
  margin-top: $global-unit-large;

  @if ($inuit-use-markup-fix == false) {
    font-size: 0;
  }

  .o-anchor {
    &:first-child {
      margin-left: 0;
    }

    @if ($inuit-use-markup-fix == false) {
      font-size: $inuit-global-font-size; /* [6] */
      font-size: 1rem;
    }
  }

}





/* Make the icon `knocked-out` on a solid background
   ========================================================================== */

.o-anchor__icon--ko {

  &:before {
    border-radius: 50%;
    background-color: $primary-blue;
    color: $color-white;
    @include font-size(13px, 25px, true);
    text-align: center;
  }

}





/* Button-y anchors
   ========================================================================== */

.o-anchor--btn {
  transition: none;

  .o-anchor__text {
    color: $color-black;
    padding: calc($global-unit-tiny/2) $global-unit-small;
    background-color: $color-grey10;
    border-radius: $global-radius;
    transition: $bgcolor-hover-trans;
  }

  &:hover {
    opacity: 1;

    .o-anchor__text {
      color: $color-white;
      background-color: $primary-blue;
    }

  }

}





    .o-anchor--top {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: $color-black;
        background-color: $color-white;
        transition: $bgcolor-hover-trans;

        span {
            display: block;
            @include hide-text();
        }

        [class^="res-"]:before,
        [class*=" res-"]:before {
            display: block;
            position: absolute;
            top: 0%;
            left: 0%;
            margin: 0;
            width: 30px;
            height: 30px;
            @include font-size(20px);
            text-align: center;
            text-indent: 1px;
            color: $color-black;
        }

        &:hover {
            opacity: 1;
            background-color: $color-grey5;
        }

    }



/* Size variants
   ========================================================================== */

/**
 * Modify the amount of space between our image and our icon. We also have
 * reversible options for all available sizes.
 */

.o-anchor--small {

  > .o-anchor__icon {
    margin-right: $global-unit-small;
  }

  &.o-anchor--reverse {

    > .o-anchor__icon {
      margin-right: 0;
      margin-left:  $global-unit-small;
    }

  }

}


.o-anchor--medium {

  > .o-anchor__icon {
    margin-right: $global-unit;
  }

  &.o-anchor--reverse {

    > .o-anchor__icon {
      margin-right: 0;
      margin-left:  $global-unit;
    }

  }

}


.o-anchor--large {

  > .o-anchor__icon {
    margin-right: $global-unit-large;
  }

  &.o-anchor--reverse {

    > .o-anchor__icon {
      margin-right: 0;
      margin-left:  $global-unit-large;
    }

  }

}


.o-anchor--huge {

  > .o-anchor__icon {
    margin-right: $global-unit-huge;
  }

  &.o-anchor--reverse {

    > .o-anchor__icon {
      margin-right: 0;
      margin-left:  $global-unit-huge;
    }

  }

}





/* Reversed anchor objects
   ========================================================================== */

.o-anchor--reverse {

  > .o-anchor__icon {
    float: right;
    margin-right: 0;
    margin-left: $global-unit-tiny;
  }

}
