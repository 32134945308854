﻿/* ==========================================================================
   #SURVEY
   ========================================================================== */
// Custom modal for surveys.

.c-survey {
  position: relative;
}


.c-survey--overlay {

  &,
  &[data-state="is-hidden"] {
    display: block;
    position: absolute;
    top: -99999em;
    left: -99999em;
    width: 0%;
    height: 0%;
    background-color: rgba($color-black, 0.85);
    z-index: 99999;
    overflow: hidden;
    opacity: 0;
    transition: 250ms opacity ease;
  }

  &[data-state="is-active"] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: 250ms opacity ease;
    will-change: opacity;
  }


  &[data-state="is-dismissed"] {
    display: block;
    opacity: 0;
    transition: 250ms opacity ease;
  }

  &.c-survey--help {
    &,
    &[data-state="is-hidden"] {
      background-color: rgba($color-black, 0.15);
    }
  }

}

// .c-survey__table {
//     display: table;
//     width: 100%;
//     height: 100%;
// }

// .c-survey__pos {
//     display: table-cell;
//     width: 100%;
//     height: 100%;
//     vertical-align: middle;
// }


.c-survey__wrapper {
  @include position-center();
  @include display-flex();
  flex-direction: column;
  width: 94%;
  max-width: 600px;
  max-height: 94%;
  margin: 0 auto;
  border-radius: $global-unit-small;
  background-color: $neutral-ulight;
  box-shadow: 0 0 10px rgba($color-black, 0.4);

  .c-form-field__label:not(.has-instructions) {
    margin-bottom: $global-unit;
    @include font-size(18px);
  }

  .c-form-field__instruction {
    margin-bottom: $global-unit;
  }

  [data-form-field-status="show-error"] {
    padding-top: 32px;
  }

  .c-form-field__error {
    position: absolute;
    top: -32px;
    left: 0;
    display: block;
    width: 100%;
    margin-top: 0;
    margin-bottom: $global-unit-small;
    padding: 4px 0 4px 30px;
    @include font-size(16px, 1);

    &:before {
      position: absolute;
      top: 50%;
      left: $global-unit-tiny;
      display: block;
      width: 20px;
      height: 20px;
      @include svg-bg-img($svg-alert-error, $global-icon-size, $global-icon-size, $color-ui-error);
      @include translateY(-50%);
      border: none !important;
    }

  }

}



.c-survey__close {
  position: absolute;
  top:   $global-unit-small;
  right: $global-unit-small;
  width:  24px;
  height: 24px;
  z-index: 99999;
  border-radius: 50%;
  background-color: transparent;
  transition: $bgcolor-hover-trans;

  &:before {
    @include position-center();
    display: block;
    width:  14px;
    height: 14px;
    @include svg-bg-img($svg-close-btn, $global-icon-size, $global-icon-size, $neutral-medium);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $primary-blue;

    &:before {
      @include svg-bg-img($svg-close-btn, $global-icon-size, $global-icon-size, $color-white);
    }
  }

}



/**
 * Scrollable content area of the modal window.
 *
 * 1. Add space between the body and teh modal window to make room for
 *    the close button.
 * 2. Set a max-height so that scrolling can occur.
 * 3. Show vertical scrollbar when content exceeds max-height.
 */
.c-survey__body {
  @include display-flex();
  margin: 0;
  padding: $global-unit;
  padding-top: 0;
  max-height: 440px; /* [2] */
  overflow-y: auto; /* [3] */

  @include mq($from: tablet) {
  max-height: 600px;
  }

  > :last-child {
    margin-bottom: 0;
  }

  &.no-scrolling {
    max-height: unset !important;
    overflow-y: unset !important;
  }

  &[data-form-field-status="error"] {
    padding-top: 32px;
  }

  /* Bigger animated radion button */
  .ani-radio {
    margin-bottom: $global-unit;


    + .c-form-field--nested {
      padding-left: $global-unit + $global-unit-small;
    }

    input[type="radio"] {

      + label {
        padding-left: $global-unit + $global-unit-small;

        &:before {
          width: 24px;
          height: 24px;
        }

        &:after {
          left: 6px;
          width: 14px;
          height: 14px;
        }

      }

    }

  }

  .ani-checkbox {
    margin-bottom: $global-unit;
    @include clearfix();

    + .c-form-field--nested {
        padding-left: $global-unit-small;
    }

    [type="checkbox"] {
      width: 24px;
      height: 24px;
      float: left;
    }

    label {
      padding-left: $global-unit-small;
    }

  }


  .c-form-img-picker__item {
    .c-form-img-picker__label {
      display: block;
      padding-top: $global-unit-tiny;
      background-color: $neutral-ulight;
      line-height: 1.1;
    }
  }

}


// Modal header - optional setting in res_modal()
.c-survey__header {
  margin: 0;
  margin-bottom: $global-unit;
  padding: $global-unit-large $global-unit-large $global-unit $global-unit;
  align-items: flex-start;
  // color: $color-white;
  // background-color: $primary-blue;

  + .c-survey__body {
    margin-top: 0;
  }

}

.c-survey__heading {
  margin-bottom: 0;
  @include font-size(24px, 1.15);
  font-weight: $font-weight-light;

  @include mq($from: tablet) {
    @include font-size(28px, 1.25);
  }

  .c-svg {
    top: 0.125em;
    margin-right: $global-unit-small;
    @include font-size(24px, 0);

    @include mq($from: tablet) {
      margin-right: $global-unit;
      @include font-size(28px, 0);
    }

  }

}

.c-survey__footer {
  padding: $global-unit $global-unit $global-unit-large;
  margin-bottom: 0;
  align-items: flex-end;

  .c-form-field--buttons {
    margin-top: 0;
  }

}


// If the modal has a carousel in it, you need to fix some widths
.c-survey__carousel {
  margin: 0 auto;
  width: 200px;

  @include mq($from: tablet) {
    width: 552px;
  }

}





/* Survey progress stepper
   ========================================================================== */
.c-survey__stepper {
  position: relative;
  display: block;
  margin: 0;
  margin-bottom: $global-unit-large;
  margin-left: -$global-unit-small;
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 0;
}

.c-survey__step-indicator {
  box-sizing: border-box;
  display: inline-block;
  width: 32px;
  height: $global-unit-tiny;
  margin: 0;
  margin-left: $global-unit-small;
  padding: 0;
  background-color: $color-grey20;
  border-radius: 2em;

  font-size: $global-font-size;
  font-size: 1rem;


  &[data-state="is-active"] {
    background-color: $primary-blue;
  }

}