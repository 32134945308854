/* ==========================================================================
   #POSITIONING
   ========================================================================== */
// Helper classes to position elements.
$positions: static, relative, absolute, fixed !default; // , sticky;

@if ($positions != null) {

  @each $position in $positions {
    .u-position-#{$position} { position: $position !important; }
  }

}



/* Top positions
   ========================================================================== */
.u-position-top-left {
  @include position-top-left();
}

.u-position-top-center {
  @include position-x-center();
  top: 0;
}

.u-position-top-right {
  @include position-top-right();
}




/* Middle positions
   ========================================================================== */
.u-position-center {
  @include position-center();
}

.u-position-y-center {
  @include position-y-center();
}

.u-position-y-center-right {
  @include position-y-center();
  right: 0;
}

.u-position-x-center {
  @include position-x-center();
}





/* Bottom positions
   ========================================================================== */
.u-position-bottom-left {
  @include position-bottom-left();
}

.u-position-bottom-center {
  @include position-x-center();
  bottom: 0;
}

.u-position-bottom-right {
  @include position-bottom-right();
}




/* Misc positions
   ========================================================================== */
.u-position-sticky-top {
  @include position-sticky();
  top: 0;
}

.u-position-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}

.u-position-fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}