/* ==========================================================================
   #COMPASS
   ========================================================================== */
// Some mixins to support SCSS code written when this was still compiled
// using the Compass framework. http://compass-style.org/

/**
 * Indicates the direction you prefer to move your text when hiding it.
 * Left is more robust, especially in older browsers. right seems have
 * better runtime performance.
 */
$hide-text-direction: left;


 /**
  * Hides text in an element so you can see the background.
  * The direction indicates how the text should be moved out of view.
  */
 @mixin hide-text($direction: $hide-text-direction) {
 
   @if $direction == left {
     $approximate-em-value: calc(12px / 1em);
     $wider-than-any-screen: -9999em;
     text-indent: $wider-than-any-screen * $approximate-em-value;
     overflow: hidden;
     text-align: left;
   }
 
   @else {
     // slightly wider than the box prevents issues with inline-block elements
     text-indent: 110%;
     white-space: nowrap;
     overflow: hidden;
   }
 
 }
 
 
 
 /**
  * Hides html text and replaces it with an image. If you use this on an inline
  * element, you will need to change the display to block or inline-block.
  * Also, if the size of the image differs significantly from the font size,
  * you'll need to set the width and/or height.
  */
@mixin replace-text($img, $x: 50%, $y: 50%) {

  @include hide-text;

  @if is-url($img) {
    background-image: url($img);
  }

  @else {
    background-image: image-url($img);
  }

  background-repeat: no-repeat;
  background-position: $x $y;
}