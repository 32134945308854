/* ==========================================================================
   #MYAIR THEME
   ========================================================================== */
/**
 * Apply the myAir UI styles.
 *
 * Each section below corresponds to the file structure of the SASS folder. 
 */



/******************************************************************************
 * SETTINGS
 ******************************************************************************/


// UTILITIES
// Edit the `sass/settings/_settings.utilities.scss` file directly to enable any
// dynamically generated utility classes you will need.



/* Core
   ========================================================================== */
/**
 * Modify inuitcss core settings.
 * Please proceed with caution, changing the settings can mess your sh*t up.
 */

/**
 * PROBABLY DON'T CHANGE ANY OF THESE SETTINGS
 * Uncomment to change - but seriously, don't change them.
 * see `sass/settings/_settings.core.scss` for more details.
 */
// $res-global-baseline:                   6px;  // ( inuitcss default: 6px )
// $res-global-spacing-unit-factor-tiny:   1;    // ( inuitcss default: 1  = 6px )
// $res-global-spacing-unit-factor-small:  2;    // ( inuitcss default: 2  = 12px )
// $res-global-spacing-unit-factor:        4;    // ( inuitcss default: 4  = 24px )
// $res-global-spacing-unit-factor-large:  8;    // ( inuitcss default: 8  = 48px )
// $res-global-spacing-unit-factor-huge:   16;   // ( inuitcss default: 16 = 96px )


// THIS YOU CAN CHANGE
$res-global-font-size: 16px; // ( inuitcss default: 16px )

// MAYBE CHANGE THIS
// depending on the global font size, but probably you should just leave it alone
// $res-global-line-height:  24px; // ( inuitcss default: $inuit-global-spacing-unit = 24px )



/* Breakpoints
   ========================================================================== */
/**
 * If you need different breakpoints than the default, uncomment and update 
 * the map below.
 */
//$mq-breakpoints: (
//  mobile:   $bp-320,    // default, mobile-portrait
//  tablet:   $bp-768,    // tablet-portrait
//  desktop:  $bp-992,    // tablet-landscape
//  wide:     $bp-1200,   // laptops
//  x-wide:   $bp-1600    // desktop displays
//);

/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
// $mq-show-breakpoints: (mobile, tablet, desktop, wide, x-wide);


/**
 * To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
// Provider sites are typically not responsive
// $mq-responsive: false;

/**
 * Define the breakpoint from the $mq-breakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mq-responsive is set to 'false').
 */
$mq-static-breakpoint: desktop;


@import "../settings/settings.core";


/* Color pallette
   ========================================================================== */
// See the color settings file for other available colors, but this is the 
// main color palette you'll need to change for your design/branding.
// Add new color variables here if needed.

// Uncomment if you need to use lighten() or shade() color functions
// @import "../tools/tools.color.functions";

$primary-black:             #424243;

$primary-blue:              #1788c3;
$primary-blue-hover:        #3392CD;
$primary-blue-active:       #0077c0;
$secondary-blue:            #ebf7fd;

$primary-red:               #ed1b2f;
$secondary-red:             #da1f3d;
$secondary-red-hover:       #d12244;

$secondary-green:           #6cb454;
$secondary-purple:          #6f2c91;
$secondary-yellow:          #ffde55;
$secondary-amber:           #ffc344;
$secondary-white:           white;


// Neutrals
$neutral-xheavy:            #787878;
$neutral-heavy:             #6d6d6d;
$neutral-medium:            #b7b7b8;
$neutral-light:             #d9d8d5;
$neutral-xlight:            #e6e6e7;
$neutral-ulight:            #f6f6f6;

// UI components
$color-rmd-nav-active:      #B02443;  // Active item in ResMed top nav
$color-mask-leak:           #6e308f;  // Mask leak color
$color-mask-onoff:          #ed6937;  // Mask on/off color


@import "../settings/settings.colors";


/* TYPOGRAPHY
   ======================================================================== */
// Font weights
// Adjust these based on the font(s) being used
$font-weight-thin:          100; // Cern Ultra Light
$font-weight-light:         300; // Cern Light
$font-weight-normal:        400;
$font-weight-medium:        600; // Cern Medium or normal - default
$font-weight-bold:          700; // Cern Bold
$font-weight-extra:         800;
$font-weight-black:         900;

$html-font-family:          $font-family-cern;
$html-font-weight:          $font-weight-light;
$html-text-color:           $primary-black;

$inuit-font-size-h1:        36px; // ( default: 36px )
$inuit-font-size-h2:        28px; // ( default: 28px )
$inuit-font-size-h3:        24px; // ( default: 24px )
$inuit-font-size-h4:        20px; // ( default: 20px )
$inuit-font-size-h5:        18px; // ( default: 18px )
$inuit-font-size-h6:        16px; // ( default: 16px )

$h-headings-color:          $html-text-color;
$h-headings-font-family:    $font-family-cern;
$h-headings-font-weight:    $font-weight-light;

$h1-font-weight:            $font-weight-medium;
$h2-font-weight:            $font-weight-medium;
$h3-font-weight:            $font-weight-bold;
$h4-font-weight:            $font-weight-bold;
$h5-font-weight:            $font-weight-medium;
$h6-font-weight:            $font-weight-normal;

$a-link-color:              $primary-blue;
$a-visited-color:           #4156a6 ;
$a-hover-color:             $primary-blue-hover;
$a-active-color:            shade($primary-blue-hover, 12%);
$a-hover-trans:             color 250ms ease-out;

@import "../settings/settings.typography";


/* Global
 ========================================================================== */
/**
 * Set the site's max-width. This setting applies to the `.o-wrapper` element 
 * which should encapsulate any elements that need to be constrained to the 
 * page width.
 *
 * 1366px corresponds to a typical HD laptop which might be used by providers.
 * 1440px (default)
 */
$res-wrapper-width:           1440px;
// If the site is not responsive, set the wrapper element's min-width to the
// static breakpoint.
$res-wrapper-min-width:       map-get($mq-breakpoints, $mq-static-breakpoint);


 
// Standardize some UI elements.
$global-disabled-text-color:  $neutral-medium;
$global-icon-size:            24px; // Size the SVG icons are designed at
$global-icon-size-lg:         32px;
$global-radius:               $global-unit-micro;
$global-transition:           calc(1/3) + s;
$global-border-color:         $neutral-xlight;
$global-box-shadow:           0px 2px 5px $neutral-xlight;
$global-box-shadow-hover:     0px 4px 15px $neutral-medium;


$opacity-hover-trans:         opacity 250ms ease-out;
$opacity-hover-amount:        0.8;
$opacity-disabled:            0.3;
$border-hover-trans:          border-color 250ms ease-out, color 250ms ease-out;
$bgcolor-hover-trans:         background-color 250ms ease-out, color 250ms ease-out;
$color-hover-trans:           color 250ms ease-out;

$button-min-width:            186px;
$button-pill-radius:          420px;
$input-field-height:          42px;


@import "../settings/settings.global";


/******************************************************************************
 * ELEMENTS
 ******************************************************************************/

/* FORMS
   ========================================================================== */
$form-placeholder-text-color:   $neutral-light;
$form-input-text-color:         $color-black;
$form-font-size:                $inuit-global-font-size;
$form-font-family:              $font-family-sans-serif;
$form-font-weight:              $font-weight-normal; 




/* PAGE
   ========================================================================== */
$html-bgcolor:    $color-white;




/******************************************************************************
 * COMPONENTS
 ******************************************************************************/

/* ALERTS
   ========================================================================== */
$c-alert-mobile-icon-top:           6px;
$c-alert-mobile-icon-left:          6px;
$c-alert-icon-top:                  21px;
$c-alert-icon-left:                 12px;
$c-alert-icon-size:                 $global-icon-size;
$c-alert-radius:                    $global-radius;
$c-alert-font-size:                 $global-font-size;

$c-alert-mobile-close-btn-top:      6px;
$c-alert-mobile-close-btn-right:    6px;
$c-alert-close-btn-top:             21px;
$c-alert-close-btn-right:           12px;

$c-alert-message-bgcolor:           $primary-blue;
$c-alert-message-text-color:        $color-white;

$c-alert-error-icon:                $svg-alert-error-fill;
$c-alert-error-icon-color:          $color-white;
$c-alert-error-bgcolor:             $color-ui-error;
$c-alert-error-text-color:          $color-white;

$c-alert-warning-icon:              $svg-alert-warning-fill;
$c-alert-warning-icon-color:        $html-text-color;
$c-alert-warning-bgcolor:           $color-ui-warning;
$c-alert-warning-text-color:        $html-text-color;

$c-alert-success-icon:              $svg-alert-success-fill;
$c-alert-success-icon-color:        $color-white;
$c-alert-success-bgcolor:           $color-ui-success;
$c-alert-success-text-color:        $color-white;

$c-toast-top-position:              $global-unit;
$c-toast-right-position:            $global-unit;
$c-toast-width:                     330px;
$c-toast-font-size:                 14px;
$c-toast-radius:                    0px;


/* BUTTONS
   ========================================================================== */
$c-btn-icon-size:                       $global-icon-size;
$c-btn-font-size:                       $global-font-size;
$c-btn-font-weight:                     $font-weight-bold;
$c-btn-letter-spacing:                  1px;
$c-btn-radius:                          $button-pill-radius;
$c-btn-opacity-disabled:                $opacity-disabled;

$c-btn-short:                           120px;
$c-btn-wide:                            240px;
$c-btn-min-width:                       $button-min-width;
$c-btn-max-width-mobile:                480px;

$c-btn-padding-vertical:                $global-unit-small;
$c-btn-padding-horizontal:              $global-unit-large;
$c-btn-padding-icon:                    $c-btn-padding-horizontal + $c-btn-icon-size + $global-unit-small;

$c-btn-primary-text-color:              $color-white;
$c-btn-primary-bgcolor:                 $primary-blue;
$c-btn-primary-hover-bgcolor:           $primary-blue-hover;
$c-btn-primary-active-bgcolor:          $primary-blue-active;
$c-btn-primary-inverse-text-color:      $c-btn-primary-bgcolor;
$c-btn-primary-inverse-hover-color:     $c-btn-primary-hover-bgcolor;
$c-btn-primary-inverse-active-color:    $c-btn-primary-active-bgcolor;
$c-btn-primary-inverse-bgcolor:         $color-white;
$c-btn-primary-inverse-hover-bgcolor:   $neutral-xlight;

$c-btn-secondary-text-color:            $primary-blue;
$c-btn-secondary-border:                1px solid $primary-blue;
$c-btn-secondary-bgcolor:               transparent;
$c-btn-secondary-hover-bgcolor:         transparent;
$c-btn-secondary-active-bgcolor:        transparent;
$c-btn-secondary-inverse-text-color:    $color-white;
$c-btn-secondary-inverse-border-color:  $color-white;

$c-btn-tertiary-text-color:             $primary-black;
$c-btn-tertiary-border:                 1px solid $primary-black;
$c-btn-tertiary-bgcolor:                transparent;
$c-btn-tertiary-hover-bgcolor:          transparent;
$c-btn-tertiary-active-bgcolor:         transparent;
$c-btn-tertiary-inverse-text-color:     $color-white;
$c-btn-tertiary-inverse-border-color:   $color-white;

$c-btn-text-text-color:                 $a-link-color;
$c-btn-text-hover-color:                $a-active-color;
$c-btn-text-icon-size:                  $global-icon-size;
$c-btn-text-inverse-text-color:         $color-white;





/* CARDS
   ========================================================================== */
$c-card-bgcolor:          $color-white;
$c-card-border-radius:    $global-unit-tiny;

$c-card-heading-color:    $html-text-color;
$c-card-heading-size:     20px;
$c-card-heading-weight:   $font-weight-bold;

$c-card-text-color:       $neutral-xheavy;
$c-card-text-font-size:   $global-font-size;

$c-card-svg-icon-size:    $global-icon-size-lg;
$c-card-svg-icon-color:   $neutral-light;
$c-card-svg-icon-lg:      50px;




/* DIRECTIONAL NAV
   ========================================================================== */
$c-dnav-label-font-size:        24px;
$c-dnav-label-line-height:      46px;
$c-dnav-label-font-weight:      $font-weight-medium;
$c-dnav-arrow-icon-size:        $global-icon-size-lg;
$c-dnav-arrow-color:            $primary-blue;
$c-dnav-arrow-disabled-color:   $global-disabled-text-color;




/* DROPDOWN
   ========================================================================== */
$c-dropdown-menu-max-width:           140px;
$c-dropdown-menu-bgcolor:             $color-white;
$c-dropdown-menu-radius:              $global-radius;
$c-dropdown-menu-border:              1px solid $neutral-ulight;
$c-dropdown-menu-shadow:              0 1px 16px -3px rgba(0,0,0,0.3);
$c-dropdown-link-hover-bgcolor:       $neutral-xlight;

$c-dropmenu-ddlb-min-width:           160px;
$c-dropmenu-ddlb-max-width:           222px;
$c-dropmenu-ddlb-height:              $input-field-height;
$c-dropmenu-ddlb-font-size:           $global-font-size;
$c-dropmenu-ddlb-text-color:          $html-text-color;
$c-dropmenu-ddlb-text-hover-color:    $primary-blue-hover;
$c-dropmenu-ddlb-font-weight:         $font-weight-medium;
$c-dropmenu-ddlb-line-height:         27px;
$c-dropmenu-ddlb-border-color:        $neutral-medium;
$c-dropmenu-ddlb-radius:              $global-radius;

$c-dropmenu-ddlb-arrow-size:          16px;
$c-dropmenu-ddlb-arrow-color:         $primary-blue;




/* EXPANDER
   ========================================================================== */
$c-expander-arrow-size:                       16px;
$c-expander-label-color:                      $primary-black;
$c-expander-label-color-active:               $primary-blue;
$c-expander-display-nav-label-above-mobile:   block ; // [ none | block ]

$c-expander-nav-mobile-bgcolor:               $neutral-xlight;
$c-expander-nav-label-mobile-font-size:       16px;
$c-expander-nav-label-font-size:              18px;
$c-expander-nav-label-text-color:             $html-text-color;
$c-expander-nav-label-font-weight:            $font-weight-bold;

$c-expander-group-label-font-size:            24px;
$c-expander-group-label-font-weight:          $font-weight-normal;




/* FOOTER
   ========================================================================== */
$page-footer-text-color:        $neutral-light;
$page-footer-bgcolor:           $primary-black;
$page-footer-font-size:         14px;
$page-footer-m-top-padding:     $global-unit;
$page-footer-m-bottom-padding:  $global-unit;
$page-footer-top-padding:       $global-unit-large;
$page-footer-bottom-padding:    0;




/* FORMS
   ========================================================================== */
// Form input labels
$c-form-field-label-font-size:        16px;
$c-form-field-label-line-height:      1.25;
$c-form-field-label-font-weight:      $font-weight-medium;
$c-form-field-label-color:            $neutral-heavy;

// Hint text for fields
$c-form-field-hint-font-size:         14px;
$c-form-field-hint-line-height:       1.25;
$c-form-field-hint-color:             $neutral-medium;
$c-form-field-error-color:            $color-ui-error;

// Inline error messages
$c-form-field-error-font-size:        14px;
$c-form-field-error-line-height:      1.125;
$c-form-field-error-color:            $color-ui-error;

// Form input fields
$c-input-max-width:                   376px;
$c-input-height:                      $input-field-height;
$c-input-bgcolor:                     transparent;
$c-input-text-color:                  $primary-black;
$c-input-font-size:                   18px;
$c-input-font-size-tablet:            21px; // Adjust size at landsacpe screen sizes
$c-input-padding:                     $global-unit-tiny $global-unit-small;
$c-input-border-width:                1px;
$c-input-border-color:                $neutral-medium;
$c-input-border-color-focus:          $primary-blue;
$c-input-border-radius:               0;
$c-input-opacity-disabled:            $opacity-disabled;

$c-input-number-max-width:            120px;
$c-input-textarea-min-height:         120px;
$c-input-textarea-border-width:       1px;
$c-input-textarea-width-tablet:       600px;
$c-input-textarea-bgcolor:            $color-white;

$c-input-select-bgcolor:              $color-white;
$c-input-select-arrow-size:           16px;
$c-input-radiocheck-bgcolor:          $secondary-blue;

$c-input-num-spinner-btn-width:       32px;
$c-input-num-spinner-btn-height:      $c-input-height;
$c-input-num-spinner-btn-icon-size:   24px;
$c-input-num-spinner-color:           $primary-black;
$c-input-num-spinner-active-color:    $primary-blue;
$c-input-num-spinner-input-width:     50px;



/* ICON BUTTONS
   ========================================================================== */
$c-icon-size:                 $global-icon-size;
$c-icon-size-large:           $global-icon-size-lg;
$c-icon-default-color:        $html-text-color;
$c-icon-hover-color:          $color-black;
$c-icon-disabled-opacity:     $opacity-disabled;

$c-close-btn-color:           $html-text-color;
$c-close-btn-hover-color:     $color-black;
$c-close-btn-hover-bgcolor:   $color-white;
$c-close-btn-inverse-color:   $color-white;



/* PAGE HEAD
   ========================================================================== */
$page-head-y-alignment:         flex-end; // [ flex-start, flex-end, center ]
$page-head-bgcolor:             $color-white;
$page-head-wrapper-bgcolor:     transparent;
$page-head-has-border:          false;
$page-head-border-size:         1px;
$page-head-border-color:        $global-border-color;
$page-head-min-height:          115px;




/* PAGINATION
   ========================================================================== */
$c-pagination-link-padding-y:         6px;
$c-pagination-link-padding-x:         12px;
$c-pagination-line-height:            1.25;
$c-pagination-border-radius:          50%;
$c-pagination-icon-size:              16px;

$c-pagination-font-weight:            $font-weight-medium;
$c-pagination-color:                  $html-text-color;

$c-pagination-hover-color:            $color-black;

$c-pagination-active-color:           $a-link-color;
$c-pagination-active-font-weight:     $font-weight-bold;
$c-pagination-active-bg:              $primary-blue;
$c-pagination-active-border-color:    $neutral-medium;

$c-pagination-disabled-color:         $global-disabled-text-color;

$c-pagination-focus-box-shadow:       0px 0px 5px $neutral-light;

$c-pagination-steps-color:            $neutral-medium;
$c-pagination-steps-current-color:    $html-text-color;




/* POPOVER
   ========================================================================== */
$c-popover-icon-size:             $global-icon-size; // width and height value
$c-popover-icon-size-lg:          $global-icon-size-lg;
$c-popover-default-icon-color:    $neutral-heavy;
$c-popover-active-icon-color:     $primary-blue;

$c-popover-width:                 354px;
$c-popover-padding:               32px 42px;
$c-popover-border-radius:         6px;
$c-popover-bgcolor:               $color-white;
$c-popover-border-color:          $neutral-ulight;
$c-popover-pointer-size:          16px; // Size of the directional caret
$c-popover-offset:                13px; // Distance of slide-in animation

$c-popover-dark-bgcolor:          $color-dark-mode-bg;
$c-popover-dark-text-color:       $color-dark-mode-text;
$c-popover-dark-border-color:     $neutral-heavy;

$c-popover-wide-width:            444px;
$c-popover-wide-padding:          42px;




/* #PROGRESS
   ========================================================================== */
$c-progress-height:           8px;
$c-progress-font-size:        12px;
$c-progress-bgcolor:          $neutral-xlight;
$c-progress-border-radius:    $button-pill-radius;
$c-progress-box-shadow:       inset 0 .1rem .1rem rgba($color-black, .1);

$c-progress-bar-color:        $color-white;
$c-progress-bar-bgcolor:      $primary-blue;
$c-progress-bar-gradient:     linear-gradient(to right, $primary-blue 0, $secondary-purple 50%, $secondary-red 100%) left/var(--p,100%) fixed, $c-progress-bgcolor;
$c-progress-bar-transition:   width .6s ease;

$c-progress-stepper-width:    32px;




/* SITENAV
   ========================================================================== */
// Mobile
$m-site-nav-logo-spacing:             $global-unit-small;
$m-site-nav-list-bgcolor:             $color-white;
$m-site-nav-link-padding:             $global-unit-small;
$m-site-nav-link-color:               $primary-black;
$m-site-nav-hover-color:              $primary-blue-hover;
$m-site-nav-hover-bgcolor:            $neutral-ulight;
$m-site-nav-sub-link-color:           $primary-black;
$m-site-nav-sub-hover-color:          $primary-blue-hover;
$m-site-nav-hamburger-color:          $color-black;
$m-site-nav-close-btn-size:           $global-icon-size;

$m-mobile-username-font-size:         21px;
$m-mobile-username-font-weight:       $font-weight-light;
$m-mobile-username-text-color:        $primary-blue;


// Desktop
$site-nav-y-alignment:                flex-end;  // [ flex-start, flex-end, center ]
$site-nav-position:                   right;
$site-nav-spacing:                    0; // distance between logos and menu if menu is positioned left.
$site-nav-logo-spacing:               $global-unit;
$site-nav-link-tablet-spacing:        $global-unit;
$site-nav-link-desktop-spacing:       $global-unit-large; // distance between menu items
$site-nav-link-padding:               0;
$site-nav-link-height:                28px; // line-height property, set a link height.
$site-nav-link-color:                 $primary-black;
$site-nav-hover-color:                $color-black;
$site-nav-active-color:               $primary-blue;
$site-nav-hover-bgcolor:              transparent;
$site-nav-active-bgcolor:             transparent;
$site-nav-font-size:                  $res-global-font-size;
$site-nav-font-weight:                $font-weight-medium;
$site-nav-ddlb-arrow-size:            16px;
$site-nav-ddlb-arrow-color:           $site-nav-link-color;
$site-nav-ddlb-arrow-active-color:    $site-nav-active-color;

// Main menu dropdowns
$site-nav-sub-min-width:              182px;
$site-nav-sub-max-width:              260px;
$site-nav-sub-bgcolor:                $color-white;
$site-nav-sub-border-color:           $neutral-ulight;
$site-nav-sub-link-color:             $primary-black;
$site-nav-sub-hover-color:            $color-black;
$site-nav-sub-hover-bgcolor:          $neutral-ulight;
$site-nav-sub-active-color:           $site-nav-active-color;
$site-nav-sub-active-bgcolor:         $secondary-blue;


// Menu item is icon only
$site-nav-icon-size:                  $global-icon-size;
$site-nav-icon-size-large:            $global-icon-size-lg;
$site-nav-icon-default-color:         $site-nav-link-color;
$site-nav-icon-hover-color:           $site-nav-hover-color;

// Stacked or side nav
$stacked-nav-item-bottom-border:      false;  // show the bottom border
$stacked-nav-active-color:            $primary-blue;
$m-stacked-nav-active-bgcolor:        $color-white;

// Tabs style
$nav-tabs-margin-bottom:              $global-unit-large;
$nav-tabs-border-height:              4px;
$nav-tabs-border-color-active:        $primary-blue-hover;
$nav-tabs-border-color-hover:         $neutral-medium;
$nav-tabs-link-color:                 $primary-black;
$nav-tabs-link-color-active:          $primary-blue-hover;
$nav-tabs-link-line-height:           60px;
$nav-tabs-pipe-dividers:              false;  // Show a small vertical dividing line between tabs @tablet and above

// Using h1 as wordmark or title
$site-nav-title-font-weight:          $font-weight-bold;
$site-nav-title-font-size:            21px;
$site-nav-title-text-color:           $site-nav-link-color;
$site-nav-title-divider-color:        $site-nav-title-text-color;




/* SVG SYMBOLS
   ========================================================================== */
$c-svg-default-icon-color:    $neutral-heavy;
$c-svg-active-icon-color:     $primary-blue;



/* #TAGS
   ========================================================================== */
$c-tag-padding-y:             $global-unit-micro;
$c-tag-padding-x:             $global-unit-small;
$c-tag-bgcolor:               $neutral-ulight;
$c-tag-radius:                $button-pill-radius;
$c-tag-font-size:             12px;

// User status tags
$c-tag-status-padding-y:      $global-unit-tiny;
$c-tag-status-padding-x:      $global-unit-small;
$c-tag-status-font-size:      13px;
$c-tag-status-font-weight:    $font-weight-bold;

$c-tag-primary-text-color:    $status-primary;
$c-tag-primary-bgcolor:       $status-primary-bg;
$c-tag-secondary-text-color:  $status-secondary;
$c-tag-secondary-bgcolor:     $status-secondary-bg;
$c-tag-success-text-color:    $status-success;
$c-tag-success-bgcolor:       $status-success-bg;
$c-tag-danger-text-color:     $status-danger;
$c-tag-danger-bgcolor:        $status-danger-bg;
$c-tag-warning-text-color:    $status-warning;
$c-tag-warning-bgcolor:       $status-warning-bg;
$c-tag-dark-text-color:       $status-dark;
$c-tag-dark-bgcolor:          $status-dark-bg;
$c-tag-light-text-color:      $status-light;
$c-tag-light-bgcolor:         $status-light-bg;



/* TOOLTIPS
   ========================================================================== */
$c-tooltip-min-width:             80px;
$c-tooltip-padding:               $global-unit-tiny;
$c-tooltip-border-radius:         6px;
$c-tooltip-bgcolor:               $color-dark-mode-bg;
$c-tooltip-opacity:               0.9;
$c-tooltip-text-color:            $color-dark-mode-text;
$c-tooltip-font-size:             12px;
$c-tooltip-line-height:           1.5;
$c-tooltip-pointer-size:          $global-unit-micro;
$c-tooltip-offset:                $global-unit-tiny; // Distance of slide-in animation
$c-tooltip-multi-min-width:       200px;
$c-tooltip-multi-max-width:       300px;
$c-tooltip-icon-size:             $global-icon-size; // width and height value



/* HEADINGS
   ========================================================================== */
$c-heading-page-color:      $html-text-color;
$c-heading-main-color:      $html-text-color;
$c-heading-sub-color:       $html-text-color;
$c-heading-section-color:   $color-grey90;




/******************************************************************************
 * OBJECTS
 ******************************************************************************/
 
/* CAROUSELS
   ========================================================================== */
$hero-slick-dot-color:           $color-white;
$hero-slick-dot-color-active:    $color-white;


 
/* #LISTS
   ========================================================================== */
$o-list-heading-font-size:    16px;
$o-list-heading-font-weight:  $font-weight-bold;
$o-list-heading-color:        $html-text-color;

$o-list-label-font-size:      14px;
$o-list-label-font-weight:    $font-weight-medium;
$o-list-label-color:          $html-text-color;

$o-list-value-font-size:      14px;
$o-list-value-font-weight:    $font-weight-normal;
$o-list-value-color:          $html-text-color;




/* SECTIONS
   ========================================================================== */
$o-section-mobile-top-spacing:      $global-unit-large;
$o-section-mobile-bottom-spacing:   $global-unit-large;
$o-section-top-spacing:             $global-unit-large + $global-unit-small;
$o-section-bottom-spacing:          $global-unit-large + $global-unit-small;

$o-section-divider-border-color:    $global-border-color;
$o-section-divider-border-size:     1px;

// Spacing between content and the bottom border
$o-section-divider-mobile-border-buffer:   $global-unit;
$o-section-divider-border-buffer:          $global-unit-large;

$o-section-home-top-spacing:        $global-unit-large;
$o-section-home-bottom-spacing:     $global-unit-large;

$o-section-tiny-spacing:            $global-unit-tiny;
$o-section-small-spacing:           $global-unit-small;
$o-section-large-spacing:           $global-unit-large;
$o-section-huge-spacing:            $global-unit-huge;




/* TABLES
   ========================================================================== */
$table-margin-bottom:        $global-unit-large;
$table-cell-padding:         $global-unit-small;
$table-cell-bgcolor:         $color-white;
$table-th-color:             $color-white;
$table-th-font-weight:       $font-weight-bold;
$table-th-bgcolor:           $primary-blue;
$table-td-color:             $html-text-color;
$table-td-font-weight:       $font-weight-normal;
$table-alt-row-bgcolor:      $neutral-ulight;




/*******************************************************************************
 * VENDORS
 *******************************************************************************/
 
/* MODAAL
   ========================================================================== */
$modaal-overlay-color:                    rgba(0,0,0, 0.85);

$modaal-radius:                           6px;
$modaal-main-bg:                          $color-white;
$modaal-main-text:                        $html-text-color;
$modaal-max-width:                        800px;
$modaal-shadow:                           0 2px 10px 0 rgba($neutral-medium,0.5);
$modaal-padding:                          $global-unit;
$modaal-hover-color:                      rgba(0,0,0, 0.7);


// Style our modal content area
$c-modaal-btn-font-size:                  $global-font-size;
$c-modaal-btn-font-weight:                $font-weight-bold;
$c-modaal-btn-letter-spacing:             1px;
$c-modaal-btn-radius:                     $button-pill-radius;
$c-modaal-btn-padding-vertical:           $global-unit-small;
$c-modaal-btn-padding-horizontal:         $global-unit-large;

$c-modaal-heading-font-size:              24px;
$c-modaal-heading-font-weight:            $font-weight-light;

$c-modaal-close-btn-top:                  $global-unit-small;
$c-modaal-close-btn-right:                $global-unit-small;
$c-modaal-close-btn-color:                $html-text-color;
$c-modaal-close-btn-hover-color:          $color-white;
$c-modaal-close-btn-hover-bgcolor:        $primary-blue;

// Buttons styles needed for confirm type modal
$c-modaal-btn-primary-text-color:         $color-white;
$c-modaal-btn-primary-bgcolor:            $primary-blue;
$c-modaal-btn-primary-hover-bgcolor:      $primary-blue-hover;
$c-modaal-btn-primary-active-bgcolor:     $primary-blue-active;
$c-modaal-primary-btn-min-width:          $button-min-width;

$c-modaal-btn-secondary-text-color:       $primary-black;
$c-modaal-btn-secondary-border:           1px solid $primary-black;
$c-modaal-btn-secondary-bgcolor:          transparent;
$c-modaal-btn-secondary-hover-bgcolor:    transparent;
$c-modaal-btn-secondary-active-bgcolor:   transparent;



/* SLICK
   ========================================================================== */
$slick-arrow-color:             $color-black;
$slick-arrow-size:              $global-icon-size;
$slick-prev-character:          $svg-chevron-left;
$slick-next-character:          $svg-chevron-right;
$svg-slick-dot-color:           $color-black;
$svg-slick-dot-color-active:    $svg-slick-dot-color;
$svg-slick-dot-character:       $svg-slick-dot;
$svg-slick-dot-size:            10px;
$slick-opacity-default:         0.75;
$slick-opacity-on-hover:        1;
$slick-opacity-not-active:      0.25;