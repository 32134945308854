﻿/* ==========================================================================
   #CERN
   ========================================================================== */

// REBRANDING 2025: Vastago Grotesk
@font-face {
    font-family: 'Vastago Grotesk';
    src: url('/assets/fonts/vastago/vastago_grotesk_bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

// REBRANDING 2025: Plus Jakarta Sans
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/jakarta/plus_jakarta_sans_variable_font.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

// REBRANDING 2025: Noto Sans
@font-face {
    font-family: 'Noto Sans';
    src: url('/assets/fonts/noto/NotoSans-VariableFont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

// 100 - Ultra Light
@font-face {
    font-family: 'Cern';
    src: url('/assets/fonts/cern/CernUltraLight-webfont.eot');
    src: url('/assets/fonts/cern/CernUltraLight-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/cern/CernUltraLight-webfont.woff') format('woff'),
         url('/assets/fonts/cern/CernUltraLight-webfont.ttf') format('truetype'),
         url('/assets/fonts/cern/CernUltraLight-webfont.svg#cern') format('svg');
    font-weight: 100;
    font-style: normal;
}

// 300 - Light
@font-face {
    font-family: 'Cern';
    src: url('/assets/fonts/cern/CernLight-webfont.eot');
    src: url('/assets/fonts/cern/CernLight-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/cern/CernLight-webfont.woff') format('woff'),
         url('/assets/fonts/cern/CernLight-webfont.ttf') format('truetype'),
         url('/assets/fonts/cern/CernLight-webfont.svg#cern') format('svg');
    font-weight: 300;
    font-style: normal;
}

// 600 - Medium or Normal
@font-face {
    font-family: 'Cern';
    src: url('/assets/fonts/cern/CernMed-webfont.eot');
    src: url('/assets/fonts/cern/CernMed-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/cern/CernMed-webfont.woff') format('woff'),
         url('/assets/fonts/cern/CernMed-webfont.ttf') format('truetype'),
         url('/assets/fonts/cern/CernMed-webfont.svg#cern') format('svg');
    font-weight: 600;
    font-style: normal;
}

// 700 - Bold
@font-face {
    font-family: 'Cern';
    src: url('/assets/fonts/cern/CernBold-webfont.eot');
    src: url('/assets/fonts/cern/CernBold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/cern/CernBold-webfont.woff') format('woff'),
         url('/assets/fonts/cern/CernBold-webfont.ttf') format('truetype'),
         url('/assets/fonts/cern/CernBold-webfont.svg#cern') format('svg');
    font-weight: 700;
    font-style: normal;
}
