﻿/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * Define our own base styles for the `table` element.
 *
 * Related files:
 * /inuitcss/objects/objects.tables.scss
 * /inuitcss/elements/elements.tables.scss
 * objects/objects.tables.scss
 */

/**
 * 1. Ensure tables fill up as much space as possible.
 * 2. Set the default vertical aligment for our table cells.
 */
table {
  display: table;
  table-layout: auto;
  width: 100%; /* [1] */
  border-collapse: collapse;
  th {
    vertical-align: middle; /* [2] */
  }
  td {
    vertical-align: top; /* [2] */
  }
}
