﻿/* ==========================================================================
  #JQUERY TABS
  ========================================================================== */
// jQuery UI classes and modifiers


/* jQuery UI stuff */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}


.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}


.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}


.ui-helper-clearfix:after {
  clear: both;
}


.ui-helper-clearfix {
  min-height: 0; /* support: IE7 */
}


.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
}


.ui-front {
  z-index: 100;
}





/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}

/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}






/* Customizations.
  ========================================================================== */
.c-jq-tabs--faq {
    @include animation(aniStayHidden 500ms, aniFadeIn 500ms ease-out 500ms 1);

    @include mq($until: tablet) {
        margin-left: -$global-unit;
        margin-right: -$global-unit;
    }

    &.ui-tabs {
      position: relative;
      margin-bottom: $global-unit;
      @include clearfix();

        .ui-tabs-nav {
          margin: 0;
          padding: 0;
          width: 49%;
          padding-right: $global-unit-small;
          float: left; 
            
            li {
              position: relative;
              list-style: none;
              display: block;
              top: 0;
              margin: 0;
              padding: 0;
              border-width: 0 0 1px;
              border-color: shade($color-grey10, 3%);
              border-style: solid;
              white-space: nowrap;
              background-color: $neutral-ulight;
              transition: background-color 250ms ease-out;

                &:first-child {
                    border-width: 1px 0;
                }

                a {
                    position: relative;
                    cursor: default;

                    &.ui-tabs-anchor {
                      display: block;
                      padding: 0;
                      text-decoration: none;
                      color: $color-grey40;
                    }

                    span {
                        position: relative;
                        display: block;
                        padding: $global-unit-small $global-unit;
                        padding-left: $global-unit-large;
                    }

                    .c-svg {
                        display: none;
                        @include position-y-center();
                        right: 6px;
                        @include transform-origin(50% 50%);
                        @include font-size(24px);
                    }

                }


                &.ui-tabs-active {
                  background-color: $color-white;

                    a {
                        &.ui-tabs-anchor {
                          color: shade($color-grey80, 7%);
                        }

                        .c-svg {
                            display: block;
                        }

                    }

                }

            }

        }



        .c-jq-tabs__panel {
          display: block;
          padding: 0;
          border-width: 1px 0;
          border-color: shade($color-grey10, 3%);
          border-style: solid;
          width: 49%;
          padding-left: $global-unit-small;
          float: right;

            .ui-tabs-panel {
                padding: $global-unit-small 0 0 $global-unit;
                border-width: 0;
                background: none;
                color: shade($color-grey80, 7%);

                ul {
                    @include no_list();

                    li {
                        @include no_list();
                        display: block;
                        margin-bottom: 8px;
                    }

                }

                .c-svg {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    width: 60px;
                    height: 46px;
                }


                &.myequipment {

                    .c-svg {
                        top: 5px;
                        width: 72px;
                        height: 54px;
                    }

                }

          }

        }

    }

}





.jQaccordion {

    &.ui-accordion {
      position: relative;
        margin-bottom: $global-unit;
        border-width: 0 0 1px;
        border-color: shade($color-grey10, 3%);
        border-style: solid;

        h3 {
            position: relative;
            border-width: 1px 0 0;
            border-color: shade($color-grey10, 3%);
            border-style: solid;
            white-space: nowrap;
            background-color: $color-grey5;
            margin: 0; 
            color: $color-grey60;
            cursor: pointer;
            outline: none;

            span {
                position: relative;
                display: block;
                padding: $global-unit-small $global-unit;
                padding-right: $global-unit-large;

                @include font-size(16px);
                @include mq($from: tablet) {
                    padding: $global-unit-small 8px;
                }
                
            }

            .c-svg {
                display: block;
                position: absolute;
                top: 50%;
                right: $global-unit;
                @include font-size(18px, 1, true);
                @include translateY(-50%);
                @include transform-origin(50% 50%);
                @include transformsition(250ms ease-out);

                @include mq($from: tablet) {
                    right: 4px;
                }

            }

            &.expanded,
            &.ui-state-active {
              background-color: $color-white;
                color: shade($color-grey80, 7%);

                span {
                    font-weight: $font-weight-medium;

                    
                }
                
                .c-svg {
                    @include transform(rotate(180deg) translateY(50%));
                }
                
            }

        }


        .tab_panel {
            display: none;
          padding: 8px $global-unit 30px;
            background-color: $color-white;

            @include mq($from: tablet) {
                padding: 8px 8px 30px;
            }

            h3,
            .c-svg {
                display: none;
            }

            ul {
                @include no_list();

                li {
                    @include no_list();
                    display: block;
                    margin-bottom: $global-unit-small;
                }

            }

        }

    }

}
