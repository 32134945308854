﻿/* ==========================================================================
   #CARDS
   ========================================================================== */

$c-card-bgcolor:          $color-white !default;
$c-card-border-radius:    $global-unit-tiny !default;

$c-card-heading-color:    $html-text-color !default;
$c-card-heading-size:     20px !default;
$c-card-heading-weight:   $font-weight-bold !default;

$c-card-text-color:       $neutral-xheavy !default;
$c-card-text-font-size:   $global-font-size !default;

$c-card-svg-icon-size:    $global-icon-size-lg !default;
$c-card-svg-icon-color:   $neutral-light !default;
$c-card-svg-icon-lg:      50px !default;

/**
 * Content tiles or lures.
 *
 * HTML

    Simple:
      <a href="#0" class="c-card">
        <div class="c-card__body">
          <h3 class="c-card__heading">A clean title</h3>
          <p class="c-card__text">This is going to be card copy that will hold minimal amount of info.</p>
        </div>
      </a>

      <a href="#0" class="c-card c-card--vertical">
        <div class="c-card__img">
          <img ... />
        </div>
        <div class="c-card__body">
          <h3 class="c-card__heading">A clean title</h3>
          <p class="c-card__text">This is going to be card copy that will hold minimal amount of info.</p>
        </div>
      </a>

    Complex:
      <div class="c-card c-card--vertical js-eqHeight">
        <div class="c-card__body">
          <h3 class="c-card__heading">A clean title</h3>
          <p class="c-card__text">This is going to be card copy that will hold minimal amount of info.</p>
        </div>
        <div class="c-card__footer">
          <a href="#0" class="c-btn c-btn--primary">Button</a>
        </div>
      </div>

      <a href="#0" class="c-card c-card--media js-eqHeight">
        <div class="c-card__img">
          <img src="path/to/image.jpg" alt="postcard" />
        </div>
        <div class="c-card__body">
          <h3 class="c-card__heading">A nice title</h3>
          <p class="c-card__text">This is a short, punchy sentence.</p>
        </div>
      </a>
 *
 */

/**
 * 1. Cards are clickable components so we need to use an anchor tag as the
 *    container element.
 *    However, if the card has a text link or button, use a div for the container
 *    and the `.c-card__footer` element for the link or button.
 * 2. Make sure it's a block element so it fills the width of the column or give
 *    it a width.
 * 3. Override any default link styles.
 */
.c-card {
  display: block; /* [2] */
  margin-bottom: $global-unit;
  background-color: $c-card-bgcolor;
  box-shadow: 0px 2px 5px $neutral-xlight;
  transition: box-shadow 250ms ease-out;
  border-radius: $c-card-border-radius;
  overflow: hidden;
  @include clearfix();

  /* [1] */
  @at-root a#{&},
  & {
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $c-card-text-color; /* [3] */
      text-decoration: none; /* [3] */
    }

    &:hover {
      will-change: box-shadow;
      // cursor: pointer;
      text-decoration: none; /* [3] */
      box-shadow: 0px 4px 15px $neutral-medium;
    }
  }

  @include mq($from: tablet) {
    margin-bottom: $global-unit;
  }

}


// Container for the card's icon or image
.c-card__img {
  display: block;
  overflow: hidden;

  .c-svg {
    top: 0;
    color: $c-card-svg-icon-color;
  }

}

// Container for the card's copy
.c-card__body {
  display: block;
  padding: $global-unit;

  &,
  > :last-child {
    margin-bottom: 0;
  }

}

.c-card__heading {
  margin-bottom: 0;
  @include font-size($c-card-heading-size, 1.25);
  font-weight: $c-card-heading-weight;
  color: $c-card-heading-color;
}


.c-card__text {
  @include font-size($c-card-text-font-size);
  color: $c-card-text-color;
}

/**
 * When the card itself is not clickable, add this container
 * after the body container and place your link or button here.
 */
.c-card__footer {
  display: flex;
  padding: $global-unit;
  align-items: flex-end;

  .c-btn {
    margin-bottom: 0;
  }

}




/* Style variants
   ========================================================================== */
.c-card--equipment {
  margin-bottom: 0;

  &,
  &:hover {
    cursor: default;
    box-shadow: none;
    transition: none !important;
    opacity: 1 !important;
  }

  .c-card__img {

    @include mq($from: wide) {
      min-height: 166px;
    }

  }

  .c-card__body,
  .c-card__footer {
    text-align: center;
  }

}


.c-card--library {
  /**
   * Setting a min-height since we can't do equal heights
   */
  @include mq($from: tablet) {
    min-height: 271px;
  }

  @include mq($from: desktop) {
    min-height: 266px;
  }

  @include mq($from: wide) {
    min-height: 276px;
  }

  @include mq($from: x-wide) {
    min-height: 322px;
  }

  .o-section--tips .o-carousel__item & {
    margin-left:  $global-unit;
    margin-right: $global-unit;
    min-height: 313px;

    @include mq($from: tablet) {
      margin-left:  0;
      margin-right: 0;
      min-height: 268px;
    }

    @include mq($from: desktop) {
      min-height: 247px;
    }

    @include mq($from: wide) {
      min-height: 268px;
    }

    .c-card__heading {
      @include mq($from: tablet, $until: desktop) {
        @include font-size(18px, 1.25);
      }
    }

  }

}


.c-card--empty {
  color: $neutral-heavy;
  background-color: $neutral-ulight !important;
  text-shadow: 0px 1px 0px $color-white;
  box-shadow: none;
  font-weight: $font-weight-medium;

  &:hover {
    will-change: unset;
    cursor: default;
    box-shadow: none;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

}


.c-card--img-only {

  .c-card__img {
    padding: 2px;
    background-color: $neutral-heavy;
  }

  .c-card__body {
    text-align: center;
  }

}




/* Card variants
   ========================================================================== */
.c-card--has-icon {

  .c-card__img {
    padding: $global-unit;
    float: left;

    .c-svg {
      width:  $c-card-svg-icon-size;
      height: $c-card-svg-icon-size;
    }

  }

  .c-card__body {
    padding-left: 80px;
  }

}

/**
 * Stack the contents of the card vertically. The content will be vertically
 * and horizontally centered. Can be used in conjunction with the
 * `.c-card--has-icon` modifier.
 */
.c-card--vertical {
  @include display-flex();
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;


  .c-card__img {
    padding-top: $global-unit;
    padding-bottom: 0;

    .c-svg {
      margin: 0 auto;
      width:  $c-card-svg-icon-lg;
      height: $c-card-svg-icon-lg;
    }

  }

  .c-card__body {
    display: flex;
    width: 100%;
    // align-items: center;
    flex: 1 0 auto;
    justify-content: center;
    flex-direction: column;
    // padding-top:   $global-unit-large;
    padding-left:  $global-unit-large;
    padding-right: $global-unit-large;
    text-align: center;
  }

}

/**
 * Card with a large image.
 * 1. Create a `window` that the image will scaled within.
 * 2. Container that will allow to crop the image as it's scaled
 *    responsively.
 * 3. Vertically and horizontally center the image within
 *    the cropped view.
 */
.c-card--media {
  // transition: $opacity-hover-trans;
  // opacity: 1;

  // &:hover {
  //   will-change: opacity;
  //   opacity: $opacity-hover-amount;
  // }

  .c-card__img {
    position: relative;
    overflow: hidden;

    /* [1] */
    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 64%;
    }

    .c-icon-btn--play {
      @include position-center();
      z-index: 100;

      .c-svg {
        color: $color-white;
      }

    }

    &:not(.is-video) {
      .c-icon-btn--play {
        display: none;
      }
    }

  }

  .c-card__heading {
    margin-bottom: $global-unit-tiny;
  }

  &.c-card--empty {

    > .c-card__img {

      .c-svg {
        height: 80px;
      }

    }

    > .c-card__body {
      text-align: center;
    }

  }

}


/* [2] */
.c-card__media-item {
  position: absolute;
  top:    0;
  bottom: 0;
  left:   0;
  height: 100%;
  width:  100%;

  /* [3] */
  > img {
    @include position-x-center();
    display: block;
    width: auto;
    height: 100%;
    max-width: initial;
  }

  &.is-device-img > img {
    @include position-center();
    width: 100%;
    height: auto;
  }

  > .c-svg {
    @include position-center();
    display: block;
    width: auto;
    height: 100%;
    max-width: initial;
  }

}
