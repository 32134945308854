/* ==========================================================================
   #SVG ICONS
   ========================================================================== */

/**
 * 1. Help align our inline SVG icons.
 * 2. Align bottom of icon with baseline.
 * 3. icon only, keep flush with wrapper.
 */
.c-svg {
  position: relative; /* [1] */
  top: 0.25em; /* [1] */
  display: inline-flex; /* [1] */
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  align-self: center; /* [1] */

  &.c-svg--baseline {
    top: 0.125em; /* [2] */
  }

  &.c-svg--flush {
    top: 0; /* [3] */
  }

}



/* Interactions
   ========================================================================== */
.c-svg--hover {
  position: relative;

  &.c-icon-btn-top-link {
    @include position-sticky();

    // For stupid IE
    ._Telerik_IE9 & {
      position: fixed;
      left: auto;
      bottom: 186px;
    }

  }

  .c-svg {
    position: absolute;
    top: 0;
    left: 0;
    width:  100%;
    height: 100%;
    transition: opacity .25s ease-in-out, color .25s ease-in-out;
    will-change: color;

    &.icon-default {
      color: $neutral-heavy;
      visibility: visible;
      opacity: 1;
    }

    &.icon-hover {
      visibility: hidden;
      opacity: 0;
      color: $primary-blue;
    }

  }

  &:not(.c-icon-btn-thumbs):hover {
        
    .c-svg {

      &.icon-default {
        visibility: hidden;
        opacity: 0;
      }

      &.icon-hover {
        visibility: visible;
        opacity: 1;
      }

    }

  }

  &.c-icon-btn-thumbs {

    &:hover {
  
      .c-svg.icon-default {
        color: $primary-blue;
      }
  
    }
  
  
    &.is-active {
      
      &,
      &:hover {
  
        .c-svg.icon-default {
          visibility: hidden;
          opacity: 0;
        }
        
        .c-svg.icon-hover {
          visibility: visible;
          opacity: 1;
          color: $primary-blue;
        }
  
      }
  
    }
  
  }

}


/* ==========================================
Single-colored icons can be modified like so:
.c-svg--name {
  font-size: 32px;
  color: red;
}
========================================== */
.c-svg--streak {
  width: 0.5791015625em;
}

.c-svg--mask-leak {
  width: 1.1875em;
}

.c-svg--book-open {
  width: 1.2939453125em;
}

.c-svg--connectivity {
  width: 1.2939453125em;
}

.c-svg--cpap-device {
  width: 1.2939453125em;
}

.c-svg--headmask-fullface {
  width: 1.2939453125em;
}

.c-svg--headmask-nasal {
  width: 1.2939453125em;
}

.c-svg--headmask-pillow {
  width: 1.2939453125em;
}

.c-svg--device-laptop {
  width: 1.2939453125em;
}

.c-svg--checkmark {
  width: 1.2939453125em;
}
