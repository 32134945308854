/* ==========================================================================
   #PAGE HEADER
   ========================================================================== */

$page-head-y-alignment:         center !default; // [ flex-start, flex-end, center ]
$page-head-bgcolor:             $color-white !default;
$page-head-wrapper-bgcolor:     transparent !default;
$page-head-has-border:          true !default;
$page-head-border-size:         1px !default;
$page-head-border-color:        $global-border-color !default;
$page-head-min-height:          80px !default;

/** 
 * 1. Need to force the `o-wrapper` element to expand to the max-width setting
 *    because of flexbox.
 */
.page-head {
  position: relative;
  @include display-flex();
  flex-shrink: 0;
  grid-area: header;  
  flex-direction: row!important;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: $page-head-y-alignment;
  margin: 0;
  padding: 0;
  min-height: $page-head-min-height;
  background: $page-head-bgcolor;
  line-height: 0;

  @if ($page-head-has-border == true) {
    border-bottom: $page-head-border-size solid $page-head-border-color;
  }

  @include clearfix();

  &.is-logged-in {
    min-height: 100px;
  }

  .o-wrapper {
    width: 100%; /* [1] */
    background: $page-head-wrapper-bgcolor;
  }

}




/* Variant
   ========================================================================== */
/**
 * 1. Override the max-width on the `.o-wrapper` element so the header will span
 *    the width of the viewport while keeping the outer gutters.
 * 2. Keep the ouside gutters in case it is being removed on the `.o-wrapper` 
 *    element at x-wide breakpoint.
 */
.page-head--full {

  .o-wrapper {
    width: 100% !important;
    max-width: 100% !important;  /* [1] */
    padding-right: $global-unit; /* [2] */
    padding-left:  $global-unit; /* [2] */
  }

}


.page-head--sticky {
  position: sticky;
  top: 0;
  z-index: 1071;

  .modaal-noscroll & {
    z-index: 100;
  }
  
}


.page-head--box-shadow {
  box-shadow: 0 3px 8px 1px rgba($color-black, 0.2);
}