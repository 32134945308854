﻿﻿/* ==========================================================================
   #HERO
   ========================================================================== */
/**
 * We all need a hero sometimes. Especially if it's a meatball hero.
 *
 * HTML

    <div class="c-hero c-hero--carousel" data-slide="1">
      <div class="o-wrapper">
        <div class="c-hero__wrapper">
          <div class="c-hero__body [ u-push-1/12@tablet u-2/5@tablet ]">
            <h1 class="c-hero__heading">Welcome to Resmed</h1>
            <p>Learn about sleep apnea and rediscover restful sleep.</p>
            <a href="#0" class="c-btn c-btn--primary  c-btn--small" role="button">Click me</a>
          </div>
        </div>
      </div>
    </div>

 *
 * 1. Make sure there's no extra spacing added to the main hero block.
 * 2. Set some defaults values for background image properties.
 * 3. To keep the hero content inline with the page width, we need to wrap the
 *    `.c-hero__wrapper` in the `o-wrapper` object. Change the `o-wrapper`
 *    display property to `table` with a width of `100%`.
 */
.c-hero {
  position: relative;
  display: block;
  width: 100%;
  line-height: 0; /* [1] */
  background-position: 50% 0; /* [2] */
  background-repeat: no-repeat; /* [2] */
  background-size: cover; /* [2] */

  &.o-carousel__item {
    //display: inherit;
    width: auto;
  }

  > .o-wrapper {
    display: table; /* [3] */
    width: 100%; /* [3] */
  }

}




/**
 * 1. Allow us to vertically align the body content within our hero area.
 *    Using flex at tablet screen size because stupid IE can't position play button
 *    absolutely if container is table-cell.
 * 2. Default align the content vertically to the middle of the hero element.
 * 3. We have to explicitly set the height of our hero for the vertical
 *    alignment to work.
 */
// .c-hero__wrapper {
//     position: relative;
//     display: table-cell; __<<ngThemingMigrationEscapedComment9>>__
//     vertical-align: middle; __<<ngThemingMigrationEscapedComment10>>__
//     width: 100%;

//     .c-hero--carousel & {

//         @include mq($from: tablet) {
//             display: flex; __<<ngThemingMigrationEscapedComment11>>__
//             align-items: center; __<<ngThemingMigrationEscapedComment12>>__
//         }

//     }

//     // .slide-2 & {

//     //     @include mq($until: tablet) {
//     //         vertical-align: bottom;
//     //     }

//     // }

//     // .c-hero--library & {
//     //     height: 480px; __<<ngThemingMigrationEscapedComment13>>__
//     //     vertical-align: bottom;

//     //     @include mq($from: tablet) {
//     //         height: 360px; __<<ngThemingMigrationEscapedComment14>>__
//     //         vertical-align: middle;
//     //     }

//     // }

// }

/**
 * 1. Allow us to vertically align the body content within our hero area.
 * 2. Default align the content vertically to the middle of the hero element.
 */
.c-hero__wrapper {
  position: relative;
  @include display-flex(); /* [1] */
  width: 100%;
  align-items: center; /* [2] */
}



// Style the heading as needed within the modifier class of a specific Hero
.c-hero__heading {
  font-size: inherit;
  font-weight: inherit;
}




/* Vertical alignment modifiers.
   ========================================================================== */
/**
 * Align content to the top of the container.
 */
.c-hero--top {

  > .c-hero__wrapper {
    vertical-align: top;
  }

}


/**
 * Align content to the bottom of the container.
 */
.c-hero--bottom {

  > .c-hero__wrapper {
    vertical-align: bottom;
  }

}





/* Hero variants
   ========================================================================== */
/**
 * Custom hero layouts. Below are examples of a carousel style hero, and a
 * hero mastehead with a callout style block of content.
 *
 * 1. Some height setting craziness for all our mobile screen sizes and
 *    orientations.
 * 2. Set some defaults values for background image properties.
 */
.c-hero--carousel {

  &,
  .o-carousel__item,
  .c-hero__wrapper {

    height: 480px; /* [1] */

    @include mq($from: x-wide) {
      height: 540px; /* [1] */
    }
  }



  /**
   * Set the background image for each slide in the carousel.
   * After optimizing your images for the web with PhotoShop, use a
   * compression tool to further reduce the file size of your images.
   * Such as Optimizzila: http://optimizilla.com/
   *
   * 1. Mobile first image.
   */
  .slide-1,
  &.slide-1 {
    background-image: url(/assets/images/hero-home-mobile-min.jpg); /* [1] */

    @include mq($from: tablet) {
      background-image: url(/assets/images/hero-home-desktop-min.jpg);
    }

  }



  /*.slide-2,
  &.slide-2 {
      background-image: url(/assets/images/hero-appdl-mobile-min.jpg);

      @include mq($from: tablet) {
          background-image: url(/assets/images/hero-appdl-desktop-min.jpg);
      }

  }*/



  .slide-2,
  &.slide-2 {
    background-image: url(/assets/images/hero-manInKitchen-mobile-min.jpg); /* [1] */

    @include mq($from: tablet) {
      background-image: url(/assets/images/hero-manInKitchen-desktop-min.jpg);
    }

  }



  // The first slide displays a play button which would launch a
  // full screen overlay with a video player.
  .c-icon-btn.c-icon-btn--play {
    display: block;
    margin: $global-unit auto $global-unit-large;

    @include mq($from: tablet) {
      @include position-center();
      margin: 0;
      width:  80px;
      height: 80px;
    }

  }



  // Using the inuitcss box object as the content container.
  .o-box {
    padding: 0;
    padding-top: $global-unit;
    padding-bottom: $global-unit;

    @include mq($from: tablet) {
    }

    sup {
      top: -1em;
      font-size: 36%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }

    p {
      @include font-size(16px);
      color: $color-white;

      @include mq($from: tablet) {
        @include font-size(20px);
      }

      @include mq($from: tablet) {
        @include font-size(21px);
      }

      @include mq($from: desktop) {
        @include font-size(24px, 1.5);
      }

      // app disclaimer
      &.c-promo__disclaimer {
        margin: 0;
        @include font-size(10px, 1.25);
      }

    }

    a {
      transition: opacity 250ms ease-out;
      cursor: pointer;

      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: $color-white;
        text-decoration: none;
      }

      &:hover {
        opacity: 0.8;
      }

    }

    .c-app-badges {
      display: block;
      margin-left: -24px;
      margin-right: -24px;

      > .o-layout__item {
        width: 50%;
        padding: 0 $global-unit-tiny;

        &:first-child {
          text-align: right;
        }

        @include mq($from: tablet) {
          margin-bottom: $global-unit-small;
        }

      }


      @include mq($from: tablet) {
        margin-left: 0;
        margin-right: 0;
      }

    }

  }


  .c-hero__inset {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 230px;
    height: 262px;
    margin-left: -115px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;

    @media only screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
      background-image: url(/assets/images/iPhoneApp@1x-min.png);
    }

    @media only screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(/assets/images/iPhoneApp@2x-min.png);
    }

    @media only screen and (max-width: 768px)and (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(/assets/images/iPhoneApp@3x-min.png);
    }


    @include mq($from: tablet) {
      display: none;
    }

  }

}



// Custom element for this hero carousel
.c-hero__heading {
  color: $color-white;

  .slide-1 & {
    @include font-size(28px);
    margin-bottom: 12px;

    @include mq($from: tablet) {
      @include font-size(32px);
      margin-bottom: 12px;
    }

    @include mq($from: tablet) {
      @include font-size(42px);
      margin-bottom: 0.5em;
    }

  }

  .slide-2 & {
    @include font-size(18px);
    margin-bottom: 34px;
    text-align: center;

    @include mq($from: tablet) {
      @include font-size(34px);
      margin-bottom: $global-unit-large;
    }

    @include mq($from: desktop) {
      margin-bottom: 2em;
      text-align: left;
    }

  }

  .slide-2 & {

    .is-EU & {
      @include font-size(24px);
      margin-bottom: $global-unit;
      text-align: center;

      @include mq($from: tablet) {
        @include font-size(28px);
        margin-bottom: 2em;
        text-align: left;
      }

    }

  }

}


.c-hero__cta {
  .slide-2 & {
    @include mq($until: desktop) {
      text-align: center;
    }
  }
}

.c-hero__text-btn {
  transition: opacity 250ms ease-out;
  cursor: pointer;

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: $color-white;
    text-decoration: none;
  }

  &:hover {
    opacity: 0.8;
  }

}





.c-hero-parallax {
  position: relative;
  width: 100%;
  overflow: hidden;
}


.c-hero-parallax__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 0;
}





/**
 * A hero banner with a callout style block of content.
 *
 * 1. Mobile first backgorund image.
 */
.c-hero--library {
  height: 186px;
  background-image: url(/assets/images/hero/hero-sleeplibrary-mobile.min.jpg); /* [1] */
  background-position: 50% 100%;
  background-size: cover;

  @include mq($from: tablet) {
    height: 250px;
  }

  @include mq($from: tablet) {
    background-image: url(/assets/images/hero/hero-sleeplibrary.min.jpg);
  }

  @include mq($from: x-wide) {
    height: 300px;
  }

  // Using the inuitcss box object for the callout.
  // .o-box {
  //     line-height: normal;
  //     background-color: $color-white;
  // }
}



.c-hero--welcome {

  .c-hero-parallax {
    height: 130px;

    @include mq($from: $bp-414) {
      height: 180px;
    }

    @include mq($from: tablet) {
      height: 260px;
    }

  }


  .c-hero-parallax__img {
    height: 260px;
    @include translateY(-65px);
    background-image: url(/assets/images/hero-activation-mobile-min.jpg);
    background-size: cover;


    @include mq($from: $bp-414) {
      height: 320px;
      @include translateY(-80px);
    }

    @include mq($from: tablet) {
      height: 520px;
      @include translateY(-130px);
      background-image: url(/assets/images/hero-activation-desktop-min.jpg);
    }

  }

}



.c-hero--devices {
  height: 204px;
  margin: 0 auto;
  background-image: url(/assets/images/myAirAppDevices.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;

  // @include mq($from: tablet) {
  //     height: 200px;
  // }

  @include mq($from: tablet) {
    height: 330px;
  }

  &.c-hero--devices-home {

    @include mq($from: desktop) {
      height: 543px;
    }

  }

  &.c-hero--devices-welcome {
    margin-top: -100px;

    @include mq($from: tablet) {
      margin-top: -150px;
    }

    @include mq($from: tablet) {
      margin-top: -200px;
    }

  }

}
