/* ==========================================================================
   #WRAPPERS
   ========================================================================== */

/**
 * Some custom overrides to the inuit wrapper.
 * 1. Removing the default padding at wider than max-width screen sizes so we
 *    can get the full max-width page.
 *
 * Related files:
 * /node_modules/inuitcss/objects/objects.wrapper
 */

.o-wrapper,
.o-wrapper--tiny ,
.o-wrapper--small,
.o-wrapper--large,
.o-wrapper--huge {

   @include mq($from: x-wide) {
    padding-right: 0;
    padding-left:  0;
   }

}


// Plain master page with no UI, we want a narrow max-width
// For example the email feedback page.
.o-wrapper--narrow {
  max-width: 420px !important;
}

.o-wrapper--tablet {
  max-width: $bp-768 !important;
}



.o-wrapper--flush {
    padding-right: 0 !important;
    padding-left:  0 !important;
}


// Some items on the page, inside of a .o-wrapper--flush, might still need padding.
.o-wrapper--buffer {

    @include mq($until: desktop) {
        padding-right: $global-unit-small;
        padding-left:  $global-unit-small;
    }

}

.o-wrapper--trends {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;

    @include mq($from: desktop) {
        padding-right: $global-unit-huge;
        padding-left: $global-unit-huge;
    }

}

.o-wrapper-positioning {
    position: relative;
}



/* Size variants
   ========================================================================== */

.o-wrapper--tiny {
  padding-left: $global-unit-tiny;
  padding-right: $global-unit-tiny;
}

.o-wrapper--small {
  padding-left: $global-unit-small;
  padding-right: $global-unit-small;
}

.o-wrapper--large {
  padding-left: $global-unit-large;
  padding-right: $global-unit-large;
}

.o-wrapper--huge {
  padding-left: $global-unit-huge;
  padding-right: $global-unit-huge;
}
