﻿/* ==========================================================================
  #WIDGETS
  ========================================================================== */
.o-widget {
    display: table;
    width: auto;
    margin-bottom: $global-unit;
}

.o-widget__row {
    display: block;
    width: 100%;

    @include mq($from: tablet) {
        display: table-row;
    }

}

.o-widget__btns,
.o-widget__body {
    display: block;
    width: 100%;
    text-align: center;

    @include mq($from: tablet) {
        display: table-cell;
        width: auto;
        vertical-align: middle;
        white-space: nowrap;
        padding-right: $global-unit;
    }

}


.o-widget__btns {
    line-height: 0;
    
    @include mq($from: tablet) {
        padding: 0;
    }

}


.o-widget__btn {
    display: inline-block;
    padding-right: $global-unit-tiny;

    &:last-child {
      padding-right: 0;
    }

}





/* Widget variants.
   ========================================================================== */
.o-widget--ratings {
    margin-top: $global-unit;
    margin-bottom: $global-unit-small;
    margin-left: auto;
    margin-right: auto;

    @include mq($from: tablet) {
        margin-left: 0;
        margin-right: 0;
    }

}

.o-widget--error {
    margin-bottom: $global-unit;

    .o-widget__body {
        text-align: center;

        @include mq($from: tablet) {
            width: 100%;
            white-space: unset !important;
            padding-right: 0;
        }

    }

}

.o-widget--form {

    @include mq($from: desktop) {
        width: 58.33333% !important;
    }
    
}