﻿@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

// $slick-font-path:           "../fonts/slick/" !default;
// $slick-font-family:         $global_icon-font;
$slick-loader-path:             "/assets/images/" !default;
$slick-arrow-color:             $color-black !default;
$slick-arrow-size:              $global-icon-size !default;
$slick-prev-character:          $svg-chevron-left !default;
$slick-next-character:          $svg-chevron-right !default;
$svg-slick-dot-color:           $color-black !default;
$svg-slick-dot-color-active:    $svg-slick-dot-color !default;
$svg-slick-dot-character:       $svg-slick-dot !default;
$svg-slick-dot-size:            8px !default;
$slick-opacity-default:         0.75 !default;
$slick-opacity-on-hover:        1 !default;
$slick-opacity-not-active:      0.25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  }
  @else {
    @return url($slick-loader-path + $url);
  }
}

// @function slick-font-url($url) {
//     //@if function-exists(font-url) {
//     //    @return font-url($url);
//     //}
//     //@else {
//         @return url($slick-font-path + $url);
//     //}
// }

/* Slider */

.slick-list {
  .slick-loading & {
      background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
// @if $slick-font-family == "slick" {
//     @font-face {
//         font-family: "slick";
//         src: slick-font-url("slick.eot");
//         src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
//         font-weight: normal;
//         font-style: normal;
//     }
// }

/* Arrows */

.slick-prev,
.slick-next {
  @include position-y-center();
  display: block;
  height: $slick-arrow-size;
  width:  $slick-arrow-size;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  transition: $opacity-hover-trans;
  will-change: opacity;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width:  100%;
    height: 100%;
    opacity: $slick-opacity-default;
    @include translateX(0);
    transition: $opacity-hover-trans, 0.3s transform ease-out;
  }

  @include hocus() {
    outline: none;
    background: transparent;
    color: transparent;

    &:before {
      opacity: $slick-opacity-on-hover;
      will-change: opacity, transform;
    }

  }

  &.slick-disabled {
    cursor: default;
    
    &:before {
      opacity: $slick-opacity-not-active;
    }
  }
  

}

.slick-prev {
  left: -25px;
  [dir="rtl"] & {
    left: auto;
    right: -25px;
  }
  &:before {
    @include svg-bg-img($slick-prev-character, $global-icon-size, $global-icon-size, $slick-arrow-color);
    
    [dir="rtl"] & {
      @include svg-bg-img($slick-next-character, $global-icon-size, $global-icon-size, $slick-arrow-color);
    }
  }
  @include hocus() {
    &:not(.slick-disabled):before {
      @include translateX(-3px);
    }
  }
}

.slick-next {
  right: -25px;
  [dir="rtl"] & {
      left: -25px;
      right: auto;
  }
  &:before {
    @include svg-bg-img($slick-next-character, $global-icon-size, $global-icon-size, $slick-arrow-color);
    [dir="rtl"] & {
      @include svg-bg-img($slick-prev-character, $global-icon-size, $global-icon-size, $slick-arrow-color);
    }
  }
  @include hocus() {
    &:not(.slick-disabled):before {
      @include translateX(3px);
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: $global-unit-huge;
}

.slick-dots {
  position: absolute;
  bottom: -$global-unit;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0 $global-unit-large;
  margin: 0;
  width: 100%;

  @include mq($from: tablet) {
      padding: 0;
  }


  li {
    position: relative;
    display: inline-block;
    height: $svg-slick-dot-size;
    width:  $svg-slick-dot-size;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 100%;
      width:  100%;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      transition: $opacity-hover-trans;
      will-change: opacity;

      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
          transition: $opacity-hover-trans;
          will-change: opacity;
        }
      }
      &:before {
        @include position-center();
        width:  100%;
        height: 100%;
        @include svg-bg-img($svg-slick-dot-character, $global-icon-size, $global-icon-size, $svg-slick-dot-color);
        opacity: $slick-opacity-not-active;
      }
    }
    &.slick-active button:before {
      @include svg-bg-img($svg-slick-dot-character, $global-icon-size, $global-icon-size, $svg-slick-dot-color-active);
      opacity: $slick-opacity-default;
    }
  }
}