/* ========================================================================
   #TYPOGRAPHY
   ======================================================================== */
/** 
 * The typography settings file contains all the font-families and font-weights
 * used in your project. Define some global default typography variables.
 */


/* Font stacks
   ========================================================================== */
// Add any fonts, and fallbacks, used in your project here.

// ResMed font
$font-family-cern:          "Cern", "Helvetica Neue", "Helvetica", Helvetica, Arial, Verdana, sans-serif !default;


// System fonts
$font-family-sans-serif:    "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif !default;
$font-family-serif:         Georgia, "Times New Roman", serif !default;


// Monospace stack
$font-family-monospace:     Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace !default;


// Imported fonts
// $font-family-opensans:      "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
// $font-family-roboto:        "Roboto", "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;





/* Font weights
   ========================================================================== */
// Adjust these based on the font(s) being used
$font-weight-thin:          100 !default; // Cern Ultra Light
$font-weight-light:         300 !default; // Cern Light
$font-weight-normal:        400 !default;
$font-weight-medium:        600 !default; // Cern Medium or normal - default
$font-weight-bold:          700 !default; // Cern Bold
$font-weight-extra:         800 !default;
$font-weight-black:         900 !default;




/* Global typography styles
   ========================================================================== */
$html-font-family:          $font-family-cern !default;
$html-font-weight:          $font-weight-light !default;
$html-text-color:           $primary-black !default;  // Main body content, `<p></p>`


/**
 * Override inuitcss default H1 - H6 heading sizes with our own sizes,
 */
$inuit-font-size-h1:        36px !default; // ( default: 36px ) 50px
$inuit-font-size-h2:        28px !default; // ( default: 28px ) 36px
$inuit-font-size-h3:        24px !default; // ( default: 24px ) 28px
$inuit-font-size-h4:        20px !default; // ( default: 20px ) 24px
$inuit-font-size-h5:        18px !default; // ( default: 18px ) 20px
$inuit-font-size-h6:        16px !default; // ( default: 16px ) 17px


// Default heading styles for H1 - H6 elements without a class name.
$h-headings-color:          $html-text-color !default;
$h-headings-font-family:    $font-family-cern !default;
$h-headings-font-weight:    $font-weight-light !default;

$h1-font-weight:            $font-weight-medium !default;
$h2-font-weight:            $font-weight-medium !default;
$h3-font-weight:            $font-weight-bold !default;
$h4-font-weight:            $font-weight-bold !default;
$h5-font-weight:            $font-weight-medium !default;
$h6-font-weight:            $font-weight-normal !default;


// Default colors for `a` element :link :visited :hover :active
@import "../tools/tools.color.functions";

$a-link-color:              $primary-blue !default;
$a-visited-color:           #4156a6  !default;
$a-hover-color:             $primary-blue-hover !default;
$a-active-color:            shade($primary-blue-hover, 12%) !default;
$a-hover-trans:             color 250ms ease-out !default;