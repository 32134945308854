﻿/* ==========================================================================
  #VIDEO
  ========================================================================== */
/**
 * Video player overlay. Hidden container with link to video, opens in
 * full screen overlay when a link on the page is clicked to invoke the overlay.
 * Requires fitvid.js plugin
 *
 * HTML

    <div id="videoOverlay" class="c-video c-video--overlay" data-state="is-hidden">
      <div class="c-video__table">
        <div class="c-video__pos">
          <div class="c-video__wrapper">
            <a href="#0" id="videoClose" data-toggle="videoOverlay" data-playback="pauseVideo" class="c-video__close"><svg class="c-svg c-svg--clear-circle c-svg--flush"><use xlink:href="#c-svg--clear-circle"></use></svg></a>
        
            <div class="c-video__panel">
              <div class="fit-video">
                <iframe runat="server" id="videoContent" width="640" height="360" src="" frameborder="0" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

 */
.c-video {
  position: relative;
}



.c-video--overlay {

  &,
  &[data-state="is-hidden"] {
    display: block;
    position: absolute;
    top: -99999em;
    left: -99999em;
    width: 0%;
    height: 0%;
    background-color: rgba($color-black, 0.85);
    z-index: 99999;
    overflow: hidden;
    opacity: 0;
  }


  &.isActive,
  &[data-state="is-active"] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include animation-name(aniStayHidden, anifadeIn);
    @include animation-duration(250ms, 250ms);
    @include animation-delay(0s, 250ms);
    @include animation-timing-function(linear, ease-out);
    // animation-fill-mode: forwards;
    @include animation-iteration-count(1);
    opacity: 1;
  }



  &.isNotActive,
  &[data-state="is-dismissed"] {
    display: block;
    @include animation-name(anifadeOut);
    @include animation-duration(250ms);
    @include animation-delay(0s);
    @include animation-timing-function(ease-out);
    @include animation-fill-mode(forwards);
    @include animation-iteration-count(1);
  }


  .o-wrapper {

    @include mq($until: desktop) {
      position: relative;
      @include display-flex();
      align-items: center;
      padding: 0;
      height: 100%;
    }

  }

}

.c-video__table {
  display: table;
  width: 100%;
  height: 100%;
}

.c-video__pos {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;

}


.c-video__wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;

  @include mq($from: tablet) {
    max-width: 850px;
  }

  @include mq($from: desktop) {
    max-width: 853px;
  }

  @include mq($from: x-wide) {
    max-width: 960px;
  }

  .c-video__panel {
    width: 100%;
    border: $global-unit-micro solid $color-white;
    //height: 56.25vw;

    @include mq($from: desktop) {
      border-width: $global-unit-small;
    }
  }

}



.c-video__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  z-index: 99999;

  @include mq($from: desktop) {
    right: -40px;
  }

  .c-svg {
    top: 0;
    width: 100%;
    height: 100%;
    color: $color-white;
    opacity: $opacity-hover-amount;
    transition: $opacity-hover-trans;
    will-change: opacity;
}

  &:hover {
    .c-svg {
      opacity: 1;
    }
  }

}



/* Choose a language screen. When a video is not available in the user's chosen
* language, the user must select a video from a list of avaialble languages.
  ========================================================================== */

//video language selection
.c-video-language--home {

  .c-video-language--home_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;//98.0123456%;
    margin: 0 auto;
    //border:4px solid $color-grey20;
    cursor: pointer;
    background: $color-grey20 url(/assets/images/videoHomeThumb.jpg) 50% 50% no-repeat;
    background-size: cover;
    z-index: 1000;
  }

  .c-video-language__msg {
    opacity: 0;
    background: $color-grey20 url(/assets/images/videoHomeBg.jpg) 50% 50% no-repeat;
    background-size: cover;
    transition: opacity, 250ms, ease, 250ms;

    &.active {
      opacity: 1;
      will-change: opacity;
    }

  }

}




.c-video-language--lib {

  .c-video-language__msg {
    background: $color-grey20 url(/assets/images/videoLangBg.jpg) 50% 50% no-repeat;
    background-size: cover;
  }

}





.c-video-language__msg {
  position: relative;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background-size: cover;

  h3 {

    &,
    span {
      font-weight:$font-weight-bold;
      color: $color-black;

      @include mq($until: tablet) {
        @include font-size(18px);
      }

    }

  }


  p {
    color: $color-black;

    @include mq($until: tablet) {
      @include font-size(14px);
    }
  }

  .c-video-language__header,
  .c-video-language__btns {
    position: absolute;
    width: 100%;
    padding: 0 $global-unit;
    text-align: center;
  }

  .c-video-language__header {
    top: 6%;

    @include mq($from: tablet) {
      top: 20%;
    }

    @include mq($from: desktop) {
      top: 25%;
    }

  }

  .c-video-language__btns {
    bottom: 4%;

    @include mq($from: tablet) {
      bottom: 16%;
    }

    @include mq($from: desktop) {
      bottom: 20%;
    }

    [type="submit"],
    [type="button"] {

      &.btn-primary,
      &.btn-secondary {
        margin: 0 0 4px;
        padding: 11px;
        @include font-size(12px);


        @include mq($from: tablet) {
          @include font-size(14px);
        }
      }

    }

  }

}




.c-video-language__menu {
  margin: 0 auto;
  padding: 4px 0;
  border-bottom: 1px solid $color-grey20;
  @include clearfix();

  p {
    display: none;
  }

  h3 {

    &,
    span {
      display: inline;
      color: $color-black;
      @include font-size(12px, 2);
      margin-bottom: 0;
      font-weight: $font-weight-normal;
    }

  }

  .c-video-language__header,
  .c-video-language__btns {
    display: block;
    @include font-size(12px);

    @include mq($from: tablet) {
      display: inline;
    }

  }


  .c-video-language__btns {

    [type="submit"],
    [type="button"] {

      &.btn-primary,
      &.btn-secondary {
        margin: 0 0 0 2px;
        padding: 5px 11px;
        @include font-size(12px);
      }

    }

  }

}





.c-video-language--home {
  position: relative;

  .c-video-language--home_img,
  .c-video-language__msg {
    height: 292px;


    @include mq($from: $bp-375) {
      height: 246px;
    }

    @include mq($from: $bp-568) {
      height: 300px;
    }

    @include mq($from: $bp-667) {
      height: 292px;
    }

    @include mq($from: tablet) {
      height: 447px;
    }

    @include mq($from: desktop) {
      height: 480px;
    }

  }

}




.c-video-language--lib {
  position: relative;
  margin-bottom: $global-unit-large;

  .c-video-language__msg {
    height: 292px;


    @include mq($from: $bp-375) {
      height: 232px;
    }

    @include mq($from: $bp-414) {
      height: 246px;
    }

    @include mq($from: $bp-568) {
      height: 292px;
    }

    @include mq($from: $bp-667) {
      height: 400px;
    }

    @include mq($from: desktop) {
      height: 447px;
    }

  }

}


// JS adds class to body to prevent background scrolling when video
// overlay is active
.no-scroll--video {
  overflow: hidden;
  height: 100vh;
}


// FitVids styles
.fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  height: 0;
  padding: 0;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}