﻿/* ==========================================================================
   #APPLE BADGES
   ========================================================================== */


.c-apple-badge {
  display: inline-block;
  margin: 0;
  width: 135px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 135px 40px;
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;

  &.is-new-badge {
    html:lang(en) & {
      width: 152px;
      height: 48px;
      background-repeat: no-repeat;
      background-size: 152px 48px;
    }
  } 
}
