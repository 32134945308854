/* ==========================================================================
   #EXPANDER
   ========================================================================== */

$c-expander-arrow-size:                       16px !default;
$c-expander-label-color:                      $primary-black !default;
$c-expander-label-color-active:               $primary-blue !default;
$c-expander-display-nav-label-above-mobile:   none !default; // [ none | block ]

$c-expander-nav-mobile-bgcolor:               $neutral-xlight !default;
$c-expander-nav-label-mobile-font-size:       16px !default;
$c-expander-nav-label-font-size:              18px !default;
$c-expander-nav-label-text-color:             $html-text-color !default;
$c-expander-nav-label-font-weight:            $font-weight-bold !default;

$c-expander-group-label-font-size:            24px !default;
$c-expander-group-label-font-weight:          $font-weight-normal !default;

/** Custon expander -- toggling a section of UI open and closed
 * Toggling requires js method `res_expander()` to be active
 *
 * HTML

      <div class="c-expander" data-state="is-hidden">
        <label class="c-expander__label" aria-expanded="false" aria-controls="unique_id" role="button"><svg class="c-svg c-svg--chevron-right"><use xlink:href="#c-svg--chevron-right"></use></svg> Click on me to expand or collapse the content</label>
        <div id="unique_id" class="c-expander__panel">
          <div class="c-expander__body">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
        </div>
      </div>
 *
 */

/**
 *
 * 1. Make the expander default to 100% width of it's parent container.
 * 2. A little buffer between the expander and other content on the page.
 * 3. Hide the contents of the `c-expander__panel` when it's collapsed.
 * 4. Position the `c-expander__panel` up slightly so we can create a little
 *    slide down effect when it's toggled open.
 * 5. When the panel is `active`, change the color of the expander label text
 *    and rotate the arrow icon.
 * 6. Make the `c-expander__panel` visible and give it a high enough max-height
 *    to make our transition work properly.
 */
.c-expander {
  position: relative;
  display: block;
  width: 100%; /* [1] */
  margin-top:     $global-unit; /* [2] */
  margin-bottom:  $global-unit; /* [2] */


  /* Toggle/animation states */
  /* [3] */
  &[data-state="is-hidden"] {

    > .c-expander__panel {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;
    }

  }

  &[data-state="is-animating"] {

    > .c-expander__panel {
      opacity: 0;
      visibility: visible;
    }

  }

  &[data-state="is-expanded"] {

    > .c-expander__label {
      color: $c-expander-label-color-active; /* [5] */

      // Arrow is pointing right and turns downward
      .c-svg {
        @include transform(rotate(90deg) translateX(-50%));
        color: $c-expander-label-color-active;
      }

    }

    > .c-expander__panel {
      margin-top: 0;
      max-height: 400px;   /* [6] */
      overflow: visible;   /* [6] */
      visibility: visible; /* [6] */
      opacity: 1;          /* [6] */
      will-change: opacity;


      &.c-expander__panel--500 {
        max-height: 500px; /* [6] */
      }

      &.c-expander__panel--600 {
        max-height: 600px; /* [6] */
      }

      &.c-expander__panel--700 {
        max-height: 700px; /* [6] */
      }

      &.c-expander__panel--800 {
        max-height: 800px; /* [6] */
      }

      &.c-expander__panel--max {
        max-height: 1800px; /* [6] */
      }

    }

  }

}



/* The label which toggles the panel.
 *
 * 1. Add some left paddingto make room for the arrow icon.
 * 2. Align the icon and text vertically.
 * 3. Force the label elements to appear clickable.
 * 4. By default the arrow icon is positioned on the left and pointing right. `>`
 */
.c-expander__label {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding: $global-unit-small 0;
  padding-left: $global-unit + $global-unit-tiny; /* [1] */
  color: $c-expander-label-color;
  font-weight: $font-weight-medium;
  vertical-align: middle; /* [2] */
  cursor: pointer; /* [3] */

  /* [4] */
  .c-svg {
    @include position-y-center();
    left: 8px;
    width:  $c-expander-arrow-size;
    height: $c-expander-arrow-size;
    color: $c-expander-label-color;
    text-align: center;
    @include transformsition(250ms ease-out);
    @include transform-origin(50% 50%);
  }

}



/* The content we want to reveal.
 * 1. Hide everything.
 * 2. Set up our transitions.
 * 3. Undo it all @tablet size and above if it's navigation.
 */
.c-expander__panel {
  position: relative;
  margin-top:    -$global-unit-small;
  margin-bottom: $global-unit-small;
  max-height: 0; /* [1] */
  overflow: hidden; /* [1] */
  opacity: 0; /* [1] */
  transition: max-height 500ms ease-out, margin-top 500ms ease-out, opacity 500ms ease-out 150ms; /* [2] */


  .c-expander--countries[data-state="is-hidden"] &,
  .c-expander--top-nav[data-state="is-hidden"] &,
  .c-expander--navigation[data-state="is-hidden"] & {

    /* [3] */
    @include mq($from: tablet) {
      max-height: none !important;
      overflow: initial !important;
      visibility: visible !important;
      opacity: 1;
    }

  }

}

// Need a body container to apply padding for the content because adding it
// to the panel messes up the annimation
// 1. Match the padding on the label text (icon space) so the content aligns
//    with the label.
.c-expander__body {
  padding: $global-unit-small;
  padding-left: $global-unit + $global-unit-tiny; /* [1] */
}



/* Navigation that turns into an expander at mobile screen sizes.
   ========================================================================== */
/**
 * 1. Reset some defaults.
 * 2. Force the navigation container to be full screen width @mobile sizes
 *    since it's probably inside an `o-wrapper` object. Useful only if your
 *    navigation container has a background color.
 * 3. Add some padding so the content isn't butted against the edge of the screen.
 * 4. Let the navigation be the full width of it's parent container @tablet size
 *    and above.
 * 5. In navigation mode, the label's default arrow icon position is to point down
 *    when collapsed, and to rotate 180° to point up when expanded.
 */
.c-expander--navigation {
  width: auto; /* [1] */
  margin-top:     0; /* [1] */
  margin-bottom:  $global-unit-large; /* [1] */
  margin-left:    -$global-unit; /* [2] */
  margin-right:   -$global-unit; /* [2] */
  padding: 0 $global-unit; /* [3] */
  background-color: $c-expander-nav-mobile-bgcolor;


  @include mq($from: tablet) {
    width: 100%; /* [4] */
    margin: 0;
    margin-top: 0;
    padding: 0;
    background-color: transparent;
  }


  > .c-expander__label {
    display: block; /* [1] */
    margin-bottom: 0;
    @include font-size($c-expander-nav-label-mobile-font-size);
    font-weight: $c-expander-nav-label-font-weight;
    color: $c-expander-nav-label-text-color;
    text-align: center; /* [1] */

    @include mq($from: tablet) {
      display: $c-expander-display-nav-label-above-mobile;
      padding: 0; /* [1] */
      margin-bottom: $global-unit-small; /* [1] */
      @include font-size($c-expander-nav-label-font-size);
      text-align: left;
    }

    /* [5] */
    .c-svg {
      left: auto;
      right: 4px;

      @include mq($from: tablet) {
        display: none;
      }


    }

    &.isExpanded,
    &[data-state="is-expanded"] {

      > .c-expander__label .c-svg {
        @include transform(rotate(180deg) translateY(50%)); /* [5] */
      }

    }

  }

  &.isExpanded,
  &[data-state="is-expanded"] {

    > .c-expander__label {

      @include mq($from: tablet) {
        color: $c-expander-label-color;
        cursor: default;
      }

    }

  }

  > .c-expander__panel {
    top: 0;
    margin-left:  -$global-unit;
    margin-right: -$global-unit;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: $global-unit-small;

    @include mq($from: tablet) {
      top:     0;
      margin:  0;
      padding: 0;
    }

  }

}

.c-expander--top-nav {
  width: auto; /* [1] */
  margin-top:   0; /* [1] */
  margin-bottom: 0; /* [1] */
  margin-left:  -$global-unit; /* [2] */
  margin-right: -$global-unit; /* [2] */
  padding: 0; /* [3] */
  background-color: transparent;

  @include mq($from: tablet) {
    width: 100%; /* [4] */
    margin: 0;
    margin-top: 0;
    padding: 0;
    background-color: transparent;
  }


  .c-expander__label {
    display: block; /* [1] */
    margin-bottom: 0;
    @include font-size(16px);
    font-weight: $font-weight-bold;
    color: $neutral-ulight;
    text-align: center; /* [1] */

    @include mq($from: tablet) {
      display: none;
    }

    /* [5] */
    .c-svg {
      position: absolute;
      top: 50%;
      right: 4px;
      @include font-size(18px, 1, true);
      @include translateY(-50%);
      @include transform-origin(50% 50%);
      @include transformsition(250ms ease-out);
      color: $neutral-ulight;

      @include mq($from: tablet) {
        display: none;
      }

    }

  }


  &.isExpanded,
  &[data-state="is-expanded"] {

    .c-expander__label .c-svg {
      @include transform(rotate(180deg) translateY(50%)); /* [5] */
    }

  }


  .c-expander__panel {
    top: 0;
    margin-top: 0;
    margin-left: -$global-unit;
    margin-right: -$global-unit;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;

    @include mq($from: tablet) {
      top:     0;
      margin:  0;
      padding: 0;
    }

  }

}



/* Expander group --
   ========================================================================== */
/**
 * Make it work like an accordion, one on others off.
 *
 * HTML

    <div class="c-expander c-expander--group" data-state="is-hidden" data-group="my-group-name">
      <label class="c-expander__label" aria-expanded="false" aria-controls="unique_id3" role="button"><svg class="c-svg c-svg--chevron-down-24"><use xlink:href="#c-svg--chevron-down-24"></use></svg> Click on me to expand or collapse the content</label>
      <div id="unique_id3" class="c-expander__panel">
        <div class="c-expander__body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
    </div>
    <div class="c-expander c-expander--group" data-state="is-hidden" data-group="my-group-name">
      <label class="c-expander__label" aria-expanded="false" aria-controls="unique_id4" role="button"><svg class="c-svg c-svg--chevron-down-24"><use xlink:href="#c-svg--chevron-down-24"></use></svg> Click on me to expand or collapse the content</label>
      <div id="unique_id4" class="c-expander__panel">
        <div class="c-expander__body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
    </div>
    <div class="c-expander c-expander--group" data-state="is-hidden" data-group="my-group-name">
      <label class="c-expander__label" aria-expanded="false" aria-controls="unique_id5" role="button"><svg class="c-svg c-svg--chevron-down-24"><use xlink:href="#c-svg--chevron-down-24"></use></svg> Click on me to expand or collapse the content</label>
      <div id="unique_id5" class="c-expander__panel">
        <div class="c-expander__body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
    </div>
 *
 */

/**
 * 1. Put a border between each item in the group.
 * 2. Add a top border to the first item.
 * 3. In accordion mode, the label's default arrow icon position is to point down
 *    when collapsed, and to rotate 180° to point up when expanded.
 */
.c-expander--group {
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid $global-border-color; /* [1] */

  &:first-of-type {
    border-top: 1px solid $global-border-color; /* [2] */
  }

  .c-expander__label {
    width: 100%;
    @include font-size($c-expander-group-label-font-size);
    font-weight: $c-expander-group-label-font-weight;

    .c-svg {
      @include transformsition(250ms ease-out);
    }

  }

  &[data-state="is-expanded"] {

    /* [3] */
    .c-expander__label .c-svg {
      @include transform(rotate(180deg) translateY(50%));
    }

  }

}





/* Expander icon position variant
   ========================================================================== */
// 1. Undo the arrow icon's default settings.
// 2. Place the arrow icon to the far right of the label.
// 3. Point arrow up when expanded.
.c-expander--group,
.c-expander--icon-right {

  > .c-expander__label,
  > .c-expander__body {
    padding-left: 0px; /* [1] */
    padding-right: $global-unit + $global-unit-tiny; /* [2] */
  }

  &:not(.c-expander--countries) {
    > .c-expander__label {

      .c-svg {
        left: auto; /* [1] */
        right: 4px; /* [2] */
      }

    }
  }

  &[data-state="is-expanded"] {

    > .c-expander__label .c-svg {
      @include transform(rotate(180deg) translateY(50%)); /* [3] */
    }

  }

  &.c-expander--navigation {

    > .c-expander__label {
      padding-left: $global-unit + $global-unit-tiny;

      @include mq($from: tablet) {
        padding-left: 0px;
      }

    }

  }

}


.c-expander--no-icon {

  > .c-expander__label,
  > .c-expander__panel .c-expander__body {
    padding-left: 0;
    padding-right: 0;
  }

  &[data-state="is-hidden"],
  &[data-state="is-expanded"] {
    .c-expander__label {
      color: $c-expander-label-color;
    }
  }

}

// When the side nav has sections which are expanders
.is-nested-nav {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: $global-unit;

  > .c-expander__label {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  }

  > .c-expander__panel {
    margin-bottom: 0;

    .c-expander__body {
      padding: 0;
    }

  }

  .site-nav--stacked & {
    .site-nav__list {
      margin-bottom: 0;
    }
  }

}




/* Custom expanders
   ========================================================================== */
// For country selection page, countries lists turn into expanders at mobile.
.c-expander--countries {
  margin: 0;
  padding: 0;
  //padding-bottom: $global-unit-small;

  &,
  &:first-of-type {
      border: none;
  }

  // place the world map image within each group
  &:before {
    @include mq($until: tablet) {
      position: absolute;
      top: 0;
      right: 0;
      width: 160px;
      height: 100%;
      content: "";
      background-image: url(/assets/images/countryMap.png);
      background-repeat: no-repeat;
      background-position: -6px 12px;
      background-size: 430px auto;

      #europe & {
        background-position: -178px 12px;
        @include clip-path(polygon(17% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 15%));
      }

      #apac & {
        width: 136px;
        background-position: -287px 6px;
      }

    }

  }

  &[data-state="is-hidden"] {

    &:before {
      @include mq($until: tablet) {

        #europe & {
          @include clip-path(polygon(17% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%));
        }

      }

    }

  }


  // Region headings on country selection page
  // matches `c-heading-region` heading styles
  .c-expander__label {
    margin-bottom: 0;
    padding: $global-unit 42px;
    @include font-size(22px);
    font-weight: $font-weight-bold;

    @include mq($from: tablet) {
      margin-bottom: $global-unit;
      padding: 0;
      padding-bottom: $global-unit-tiny;
      border-bottom: 1px dotted shade($color-grey5, 3%);
    }


    @include mq($from: wide) {

      html:lang(fi) &,
      html:lang(nb) &,
      html:lang(sv) & {
        @include font-size(18px);
      }

    }

    .c-svg {
      width:  24px;
      height: 24px;

      @include mq($from: tablet) {
        display: none;
      }

    }

  }

  .c-expander__panel {
    top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: $global-unit-small;

    @include mq($from: tablet) {
      top: 0;
      margin: 0;
      padding: 0;
    }

  }

}




/* Legacy accordion. Some hard-coded content in the RESX uses this accordion
 * code. For example, some of the FAQ support content.
   ========================================================================== */
.accordion {
  margin-bottom: $global-unit;

  h4 {
    position: relative;
    color: $a-link-color;
    margin: 0;
    padding-left: 12px;

    span {
      color: $a-link-color;

      &.show {
        display: inline;
      }

      &.hhide {
        display: none;
      }
    }


    &:hover {
      cursor: pointer;
    }

    &.res-open-right {

      padding-right: 36px;

      :before {
        position: absolute;
        top: 50%;
        right: $global-unit-micro;
        width: 24px;
        height: 18px;
        @include svg-bg-img($svg-ddlb-arrow, $global-icon-size, $global-icon-size, $primary-black);
        transition: background-image 250ms ease-out, transform 250ms ease-out;
        @include translateY(-50%);
        @include transform-origin(50% 50%);
      }
    }

    &.expanded {

      &:before {
        @include transform(rotate(180deg) translateY(50%));
      }

      span {

        &.show {
            display: none;
        }

        &.hhide {
          display: inline;
        }

      }

    }

    @include mq($from: tablet) {
      padding-left: 24px;
    }

  }


  .panel {
    display: none;
    padding: $global-unit 0;
  }


  .tbl-scoreData {
    margin: 0 auto;
  }

}


.expandableParagraph {
  display: none;
}
