﻿/* ==========================================================================
   #INSET
   ========================================================================== */
/**
 * Place an image within the flow of content and align it left or right. You
 * need to add width classes to set the width of your inset box.

    <div class="o-inset">
        Some content here...

        <div class="o-inset__img o-inset--right u-1/3@tablet">
            <img src="..." />
        </div>

        ...some more content here.
    </div>
*/


.o-inset {
    display: block;
    @include clearfix();
}


.o-inset__img {
    margin-bottom: $global-unit-large;

    img {
        //display: block;
        width: 100%;
        height: auto;
        max-width: 500px;
        line-height: 0;
	    border: 0;
	    -ms-interpolation-mode: bicubic;
    }


    &.u-2\/5\@tablet {

        img {

            @include mq($from: tablet) {
                max-width: 398px;
            }

        }

    }

}




.o-inset__img--left {

    @include mq($from: tablet) {
        display: inline;
        float: left;
        padding-right: $global-unit-large;
    }

}


.o-inset__img--right {

    @include mq($from: tablet) {
        display: inline;
        float: right;
        padding-left: $global-unit-large;
        text-align: right;
    }

}




.o-inset__img--small {

    img {

        @include mq($from: tablet) {
            max-width: 250px;
        }

    }

}



.o-inset__img--med {

    img {

        @include mq($from: tablet) {
            max-width: 400px;
        }

    }

}



.o-inset__img--large {

    @include mq($from: tablet) {
        width: 600px;
    }

}



/* Background image cases
   ========================================================================== */
/**
 * Some images are background-images so we can change the image based on region
 * without any back-end code.
 */
.o-inset--invite {
    display: block;
    width: 100%;
    max-width: 395px;
    height: 276px;
    background-image: url(/assets/images/formExample.png);
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-size: 100% auto;


    /* Languages */
    html:lang(de) & {
        background-image: url(/assets/images/formExample_de.png);
    }

    html:lang(es) & {
        background-image: url(/assets/images/formExample_es.png);
    }

    html:lang(fi) & {
        background-image: url(/assets/images/formExample_fi.png);
    }

    html:lang(fr) & {
        background-image: url(/assets/images/formExample_fr.png);
    }

    html:lang(ja) & {
        background-image: url(/assets/images/formExample_ja.png);
    }

    html:lang(nb) & {
        background-image: url(/assets/images/formExample_nb.png);
    }

    html:lang(nl) & {
        background-image: url(/assets/images/formExample_nl.png);
    }

    html:lang(pb) & {
        background-image: url(/assets/images/formExample_pb.png);
    }

    html:lang(pl) & {
        background-image: url(/assets/images/formExample_pl.png);
    }

    html:lang(sv) & {
        background-image: url(/assets/images/formExample_sv.png);
    }

}
