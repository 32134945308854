/* ==========================================================================
  #FORMS
  ========================================================================== */

// Form input labels
$c-form-field-label-font-size:        16px !default;
$c-form-field-label-line-height:      1.25 !default;
$c-form-field-label-font-weight:      $font-weight-medium !default;
$c-form-field-label-color:            $neutral-heavy !default;

// Hint text for fields
$c-form-field-hint-font-size:         14px !default;
$c-form-field-hint-line-height:       1.25 !default;
$c-form-field-hint-color:             $neutral-medium !default;
$c-form-field-error-color:            $color-ui-error !default;

// Inline error messages
$c-form-field-error-font-size:        14px !default;
$c-form-field-error-line-height:      1.125 !default;
$c-form-field-error-color:            $color-ui-error !default;

// Form input fields
$c-input-max-width:                   376px !default;
$c-input-height:                      $input-field-height;
$c-input-bgcolor:                     transparent !default;
$c-input-text-color:                  $primary-black !default;
$c-input-font-size:                   18px !default;
$c-input-font-size-tablet:            21px !default; // Adjust size at landsacpe screen sizes
$c-input-border-width:                1px !default;
$c-input-border-color:                $neutral-medium !default;
$c-input-border-color-focus:          $primary-blue !default;
$c-input-border-radius:               0 !default;
$c-input-opacity-disabled:            $opacity-disabled !default;

$c-input-number-max-width:            120px !default;
$c-input-textarea-min-height:         120px !default;
$c-input-textarea-border-width:       1px !default;
$c-input-textarea-width-tablet:       600px !default;
$c-input-textarea-bgcolor:            $color-white !default;

$c-input-select-bgcolor:              $color-white !default;
$c-input-select-arrow-size:           16px !default;
$c-input-radiocheck-bgcolor:          $secondary-blue !default;

$c-input-num-spinner-btn-width:       32px !default;
$c-input-num-spinner-btn-height:      $c-input-height !default;
$c-input-num-spinner-btn-icon-size:   24px !default;
$c-input-num-spinner-color:           $primary-black !default;
$c-input-num-spinner-active-color:    $primary-blue !default;
$c-input-num-spinner-input-width:     50px !default;


/**
 * Define custom styles for our form inputs.
 *
 * Related files:
 * `elements/elements.forms.scss`
 */

// Break a form up into sections or wrap the whole form in this container if you
// want a bottom border.
.c-form-section {
  border-bottom: 1px solid $global-border-color;
  margin-bottom: $global-unit;
  @include clearfix();
}



/**
 * Wrapper for a form component. A form component is comprised at minimum of a
 * label and an input field. Other elements include hint text, error messages,
 * links or buttons.
 *
 * HTML

    <div class="c-form-field">
      <div class="c-form-field__label">Label</div>
      <div class="c-form-field__input">
        <input type="text" value="" class="c-input" />
      </div>
    </div>

 *
 */
.c-form-field {
  position: relative;
  box-sizing: border-box;
  vertical-align: top;
  margin-bottom: $global-unit;

  @include mq($from: tablet) {
    display: inline-block;
    width: 100%;
  }

}

.c-form-field--nested {
  padding-left: $global-unit;
}

.c-form-field--repeater-btns {
  padding-top: 32px;
  text-align: right;
}



/**
 * Form field labels.
 *
 * 1. We don't want the label to be a block element but we want to add spacing
 *    between the label and the input.
 * 2. Force a line-break after the label.
 * 3. That time when you have a form field with no label, but need it to line up
 *    horizontally with other form fields in a row. Include an empty `.c-form-field__label`
 *    with a `&nbsp;` in it and add this class to the `.c-form-field` container.
 *
 * HTML

    <div class="c-form-field c-form-field--nolabel">
      <div class="c-form-field__label">&nbsp;</div>
      <div class="c-form-field__input"><input type="text" value="" class="c-input" /></div>
    </div>

 *
 */
.c-form-field__label {
  display: block;
  color: $c-form-field-label-color;
  @include font-size($c-form-field-label-font-size, $c-form-field-label-line-height);
  font-weight: $c-form-field-label-font-weight;
  margin-bottom: $global-unit-tiny;

  @include mq($from: tablet) {
      display: inline-block; /* [1] */

      &:after { /* [2] */
        content: "\A";
        white-space: pre;
      }

  }

  /* [3] */
  .c-form-field--nolabel & {
    display: block;
    // width: 1px;
  }

  .is-required {
    color: $c-form-field-error-color;
  }

  .is-optional {
    @include font-size(14px, inherit);
    color: $c-form-field-hint-color;
  }

}


// Hint text for a form field.
.c-form-field__hint {
  display: block;
  margin: 0;
  padding-top: $global-unit-tiny;
  @include font-size($c-form-field-hint-font-size, $c-form-field-hint-line-height);
  color: $c-form-field-hint-color;
}

.c-form-field__instruction {
  display: block;
  margin-bottom: $global-unit-small;
}


// Inline error messaging on a form field.
.c-form-field__error {
  position: relative;
  padding: 0;
  padding-top: $global-unit-tiny;
  @include font-size($c-form-field-error-font-size, $c-form-field-error-line-height);
  color: $c-form-field-error-color;

  a {
    @include all-anchor-states() {
      color: $c-form-field-error-color !important;
      text-decoration: underline !important;
    }
  }

  /*
    Use absolute positioning to display the inline error message below the form input
    without it affecting the vertical spacing. Make sure there is room below the form input
    for the error message to be displayed properly.
  */
  &.is-pos-bottom {
    position: absolute;
    top: 100%;
    left: 0;
  }

  &.is-list-field {
    @include mq($from: tablet) {
      margin-top: -$global-unit;
    }
  }

}




/* Form input components
   ========================================================================== */
// Container for the input field
.c-form-field__input {
  position: relative;
  margin: 0; // If container is a <ul> for radio and checkbox lists

  // Position the validation icons
  // &:before {
  //   position: absolute;
  //   left: -20px;
  //   width:  20px;
  //   height: 20px;
  // }

}


/**
 * 1. Adding a height because some browsers, looking at you IE, don't add up
 *    the line-height and padding properly, so inputs can vary slightly in height.
 * 2. Remove any default browser stuff.
 */
.c-input {
  display: inline-block;
  width: 100%;
  height: $c-input-height; /* [1] */
  padding: 0;
  @include font-size($c-input-font-size);
  color: $c-input-text-color;
  background-color: $c-input-bgcolor !important;
  border: 0;
  border-bottom-width: $c-input-border-width;
  border-bottom-style: solid;
  border-bottom-color: $c-input-border-color;
  border-radius: $c-input-border-radius;
  box-shadow: none;
  @include appearance(none); /* [2] */

  &.is-focus,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    border-bottom-color: $c-input-border-color-focus;
  }

  &:disabled {
    cursor: default;
    opacity: $c-input-opacity-disabled;
  }

  @include mq($from: tablet) {
    @include font-size($c-input-font-size-tablet);
  }

}


// Grouped fields
.c-input-group {
  display: block;
  margin:  0;
  padding: 0;
  list-style: none;
  margin-left: -$global-unit-tiny;
  // max-width: 410px;

  @if ($use-markup-fix == false) {
    font-size: 0;
  }

  .c-input-group__item {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding-left: $global-unit-tiny;

    @if ($use-markup-fix == false) {
      @include font-size(16px, 0);
    }

  }


  &.c-input-group--dob {
    margin-left: -$global-unit-tiny;

    .c-input-group__item {
      padding-left: $global-unit-tiny;

      &.c-input--dob-day {
        width: 25%;
      }

      &.c-input--dob-month {
        width: 50%;
      }

      &.c-input--dob-year {
        width: 25%;
      }
    }
  }



  html:lang(ja) & {

    // Reverse ordering of date control fields for Japanese
    &.c-input-group--dob {
      direction: rtl;

      > .c-input-group__item {
        direction: ltr;
        text-align: left;
      }

    }

  }

}


.c-input-group--gender {

  > .o-layout__item {
    margin-bottom: 0;
  }

}


.c-input-group--middle {

  > .c-input-group__item {
    vertical-align: middle;
  }

}



/* Input type modifiers
   ========================================================================== */
// Modifactions to the `.c-input` styles for individual field types.
.c-input--text {

  &.is-focus,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

}

.c-input--number {
  max-width: $c-input-number-max-width;
}

.c-input--textarea {
  width: 100%;
  min-height: $c-input-textarea-min-height;
  padding: $global-unit-tiny;
  background-color: $c-input-textarea-bgcolor !important;
  border: $c-input-textarea-border-width solid $c-input-border-color;

  @include mq($from: tablet) {
    width: $c-input-textarea-width-tablet;
  }

}

.c-input--full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.c-input--bmi {
  display: inline-block;
  min-width: 60px;
  font-weight: $font-weight-bold;
  border-bottom: 1px solid $color-grey40;
}

.c-input--search,
.c-input--search.ui-inputtext {
  @include calc(width, 100%, "-", 40px, 76%);
  max-width: 100%;
  float: left;

  // This field has a button butted up against it, so remove the corner raidii
  border-top-right-radius:    0 !important;
  border-bottom-right-radius: 0 !important;

  + .c-btn {
    border-top-left-radius:    0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.c-form-field--dob-month {
  @include mq($from: tablet) {
    width: 160px;
  }
}
.c-form-field--dob-day {
  @include mq($from: tablet) {
    width: 100px;
  }
}
.c-form-field--dob-year {
  @include mq($from: tablet) {
    width: 60px;
  }
}

.c-form-captcha {
  margin-top: -3px;
  padding: 4px 2px 2px;
  border: 1px solid $global-border-color;
  background: $secondary-blue; // $neutral-ulight;
  @include font-size(12px);
  @include clearfix();
}




/**
 * We need to wrap select boxes so we can style them properly across browsers.
 * The select input needs to be inside a container with the `.c-input` class and
 * this modifier.
 *
 <div class="c-input c-input--select">
     <select>
         <option selected>Please select</option>
         <option>Option 1</option>
     </select>
 </div>

 * 1. Override the padding of the `.c-input` class since we're using it as a wrapper.
 *    Padding gets applied to select element instead.
 * 2. Make our custom drop-down arrow pseudo-element.
 * 3. Font-family and icon for the select input arrow.
 */
.c-input--select  {
  position: relative;
  padding: 0 !important; /* [1] */
  background-color: $c-input-select-bgcolor !important;
  overflow: hidden;
  vertical-align: bottom; // to counter extra space created by overflow hidden

  &:before { /* [2] */
    @include position-y-center();
    right:  4px;
    width:  $c-input-select-arrow-size;
    height: $c-input-select-arrow-size;
    @include svg-bg-img($svg-ddlb-arrow, $global-icon-size, $global-icon-size, $c-input-text-color);
    transition: background-image 250ms ease-out;
    z-index: 0;
  }

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    background-color: $c-input-select-bgcolor;
    border-bottom-color: $c-input-border-color-focus;

    &:before {
      @include svg-bg-img($svg-ddlb-arrow, $global-icon-size, $global-icon-size, $c-input-border-color-focus);
    }

  }

  /**
   * 1. Reset some defaults on select just in case.
   * 2. Height of our `c-input` minus the top and bottom border height.
   * 3. Hide the native drop-down button arrow in IE10+.
   */
  select {
    position: relative;
    display: block;
    width: 100%;
    height: $c-input-height - 2; /* [2] */
    padding: 0 24px 0 0; //$global-unit-tiny + 1) ($global-unit + $global-unit-small) ($global-unit-tiny - 1) $global-unit-tiny;
    color: $c-input-text-color;
    background-image: none; /* [1] */
    background-color: transparent; /* [1] */
    border: 0; /* [1] */
    border-color: transparent; /* [1] */
    @include appearance(none); /* [1] */
    -webkit-text-size-adjust: none; /* [1] */
    cursor: pointer; /* [1] */
    @include font-size($c-input-font-size);
    z-index: 1;

    &::-ms-expand {
      display: none; /* [3] */
    }

    &:disabled {
      cursor: default;
      opacity: $opacity-disabled;
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-bottom-color: $c-input-border-color-focus;
    }

    @include mq($from: tablet) {
      @include font-size($c-input-font-size-tablet);
    }

    option {
      max-width: 92%;
      font-size: inherit;
      line-height: inherit;
      background-color: transparent;
    }

  }

}





/**
 * Custom number picker
 * HTML:

    <div class="c-form-field u-1/3@tablet" data-validation="default" data-role="group" aria-labelledby="demoNumSpinnerLabel demoNumSpinnerCurrValue">
      <label class="c-form-field__label" id="demoNumSpinnerLabel">Label</label>
      <div class="c-form-field__input">

        <div class="c-num-spinner">
          <div hidden id="demoNumSpinnerCurrValue">The current value is <span data-num-spinner="current-value">0</span></div>
          <div hidden class="js-numSpinnerStrings" data-num-spinner-decrease="Decrease amount by" data-num-spinner-increase="Increase amount by" aria-hidden="true"></div>
          <button class="c-icon-btn c-num-spinner__btn"
                  tabindex="-1"
                  data-num-spinner="decrease"
                  type="button">
            <svg class="c-svg c-svg--remove-24"><use xlink:href="#c-svg--remove-24"></use></svg>
          </button>

          <input  type="number"
                  id="demoFormInputNumperPicker1"
                  class="c-input"
                  value="0"
                  tabindex="0"
                  aria-label="Label of what this input is for" />

          <button class="c-icon-btn c-num-spinner__btn"
                  tabindex="-1"
                  data-num-spinner="increase"
                  type="button">
            <svg class="c-svg c-svg--add-24"><use xlink:href="#c-svg--add-24"></use></svg>
          </button>

        </div>

      </div>
    </div>

 */
.c-num-spinner {
  white-space: nowrap;
  font-size: 0;

  .c-input,
  .c-num-spinner__btn {
    vertical-align: middle;
  }

  .c-num-spinner__btn {
    display: none;
    width:  $c-input-num-spinner-btn-width;
    height: $c-input-num-spinner-btn-height;
    padding-left: 0;
    padding-right: 0;
    color: $c-input-num-spinner-color;
    text-align: center;
    border-radius: $global-radius;

    &:hover,
    &:focus,
    &:active {
      color: $c-input-num-spinner-active-color;
    }

    .c-svg {
      width: $c-input-num-spinner-btn-icon-size;
      height:  $c-input-num-spinner-btn-icon-size;
    }

    &.is-active {
      display: inline-block;
    }

  }

  .c-input {
    width: auto;
    min-width: $c-input-num-spinner-input-width;
    max-width: $c-input-number-max-width;
    padding-left:  $global-unit-micro;
    padding-right: $global-unit-micro;
    border: 1px solid $c-input-border-color;
    border-radius: $global-radius;

    &.is-focus {
      border-color: $c-input-border-color-focus;
    }

    // Remove the native up/down controls from number inputs
    // so that we don't have double controls going on here.
    &.c-input--num-spinner {
      text-align: center;

      &,
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        @include appearance(none);
        -moz-appearance: textfield;
      }

    }

  }

}




/**
 * Fancy radio buttons and checkboxes
 * 1. Undo settings from our `.c-input` class and make our radio
 *    buttons and checkboxes stack as a list by default.
 */

.custom-checkbox,
.c-input--radio,
.c-input--checkbox {
  display: block; /* [1] */
  width: auto; /* [1] */
  height: fit-content; /* [1] */
  border: 0; /* [1] */
  margin-bottom: $global-unit-small;

  @at-root li#{&} {
    height: unset;
  }

  input[type="radio"],
  input[type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    + label,
    + .c-form-field__label {
      position: relative;
      display: inline-block;
      color: $html-text-color;
      @include font-size($c-input-font-size);
      font-weight: $font-weight-normal;
      margin-bottom: 0;
      padding-left: 32px;
      vertical-align: middle;
      user-select: none;
      cursor: pointer;

      &:before {
        @include position-y-center();
        left: 0;
        width:  20px;
        height: 20px;
        content: '';
        color: $c-form-field-label-color;
        text-align: center;
        border: 1px solid $c-input-border-color;
        box-sizing: content-box;
        transition: all 0.4s ease;
      }

      @include mq($from: tablet) {
        @include font-size($c-input-font-size-tablet);
      }

    }

    &:focus,
    &:hover {

      + label,
      + .c-form-field__label {

        &:before {
          border-color: $c-input-border-color-focus;
          background-color: $c-input-radiocheck-bgcolor;
        }

      }

    }

    &:active + label,
    &:active + .c-form-field__label {

      &:before {
        transition-duration: 0s;
      }

    }

  }

  &.is-consent {
    input[type="radio"],
    input[type="checkbox"] {

      + label,
      + .c-form-field__label {
        @include font-size(16px);
      }

    }

  }

  &.is-hint {
    input[type="radio"],
    input[type="checkbox"] {

      + label,
      + .c-form-field__label {
        @include font-size(13px, 1.25);
      }

    }

  }

  &.align-top {
    input[type="radio"],
    input[type="checkbox"] {

      + label,
      + .c-form-field__label {

        &:before {
          top: 0px;
          @include translateY(0);
        }

        @include mq($from: tablet) {
          &:before {
            top: 3px;
          }
        }

      }

    }

    input[type="radio"],
    input[type="checkbox"] {

      + label,
      + .c-form-field__label {

        &:after {
          top: 11px;
        }

        @include mq($from: tablet) {
          &:after {
            top: 14px;
          }
        }

      }

    }

  }

}


/* Checkboxes */
.custom-checkbox,
.c-input--checkbox {

  input[type="checkbox"] {

    + label,
    + .c-form-field__label {

      &:before {
        border-radius: 4px;
      }

      &:after {
        position: absolute;
        top: 50%;
        left: 2px;
        width: 11px;
        height: 5px;
        border: 2px solid $color-white;
        border-top-style: none;
        border-right-style: none;
        box-sizing: content-box;
        content: '';
        @include transform(scale(0) translateY(-50%) rotate(0deg));
        @include transform-origin(50% 0);
        transition: transform 200ms ease-out;
        z-index: 1;
      }

    }

    &:disabled {

      + label,
      + .c-form-field__label {
        color: $neutral-light;

        &:before {
          border-color: $neutral-light;
        }

        &:focus,
        &:hover {
          &:before {
            background-color: inherit;
          }
        }

      }


      &:checked {

        + label,
        + .c-form-field__label {
          &:before {
            border-color: $neutral-light;
            background-color: $neutral-light;
          }
          &:after {
            border-color: $color-white;
          }
        }

      }

    }

    &:checked {

      + label,
      + .c-form-field__label {
          // color: $primary-blue;

        &:before {
          @include animation(borderscale 300ms ease-in);
          border-color: $c-input-border-color-focus;
          background-color: $c-input-border-color-focus;
        }

        &:after {
          @include transform(scale(1) translateY(-50%) rotate(-45deg));
        }

      }

    }

  }

  &.is-consent {
    input[type="checkbox"] {

      + label,
      + .c-form-field__label {

        &:before {
          top: 0;
          @include translateY(0%);
        }

        &:after {
          top: 7px;
          @include transform(scale(0) translateY(0%) rotate(0deg))
        }

      }

      &:checked {

        + label,
        + .c-form-field__label {

          &:after {
            @include transform(scale(1) translateY(0%) rotate(-45deg));
          }

        }

      }

    }

  }

  &.has-no-label {

    input[type="checkbox"] {
      + label,
      + .c-form-field__label {
        padding-left: 22px;
      }
    }

  }

}

// OKTA remember me checkbox
.o-form-input-name-rememberDevice {
  .custom-checkbox {
    margin-top: -20px;

    // smaller size checkbox
    input[type="checkbox"] {

      + label {
        padding-left: 28px;
        @include font-size(14px, 1.35);

        &::before {
          width:  16px;
          height: 16px;
        }
        &::after {
          width:  9px;
          height: 3px;
        }
      }

    }

  }
}



/* Radio buttons */
.c-input--radio {

  input[type="radio"] {

    + label,
    + .c-form-field__label {

      &:before {
        border-radius: 50%;
      }

      &:after {
        position: absolute;
        top: 50%;
        left: 5px;
        width:  12px;
        height: 12px;
        background-color: $c-input-border-color-focus;
        border-radius: 50%;
        content: '';
        box-sizing: content-box;
        @include transform(scale(0) translateY(-50%));
        @include transform-origin(50% 0);
        transition: transform 200ms ease-out;
      }

    }

    &:disabled {

      + label,
      + .c-form-field__label {
        color: $neutral-light;
        cursor: default;

        &:before {
          border-color: $neutral-light;
        }

        &:focus,
        &:hover {
          &:before {
            background-color: inherit;
          }
        }

      }


      &:checked {

        + label,
        + .c-form-field__label {
          &:after {
            background-color: $neutral-light;
          }
        }

      }

    }

    &:checked {

      + label,
      + .c-form-field__label {
          // color: $primary-blue;

        &:before {
          @include animation(borderscale 300ms ease-in);
          background-color: $color-white;
        }

        &:after {
          @include transform(scale(1) translateY(-50%));
        }

      }

    }

  }

}


// Sleepiness level sleepy faces radio buttons
.c-sleep-icon {

  .is-disabled & {
    cursor: none !important;
    opacity: 0.3;
  }

}

.sleepyface_radio {

  > span {
    display: block;
  }

  .c-sleep-icon {
    margin: 0 auto $global-unit-small auto;
    width: 80px;
    height: 80px;
    color: $color-grey60;
    transition: color 250ms ease-out;

    .c-svg {
      width: 100%;
      height: 100%;
    }
  }

  &:hover,
  &:focus {

    .c-sleep-icon {
      will-change: color;
      color: $color-grey80;
    }

  }

  &[data-state="is-active"] {

    .c-sleep-icon {
      color: $primary-blue;
    }

  }


    input[type="radio"] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        + label {
            position: relative;
            padding-left: 0;
            padding-top: 86px;
            vertical-align: bottom;
            user-select: none;
            cursor: pointer;
        }

        &:checked {

          + label {
            color: $primary-blue;
          }

        }

        &:disabled {

            + label {
                color: $neutral-ulight;
            }

            &:focus,
            &:hover {

                + label {
                    color: inherit;
                }

            }

            &:checked {

                + label {
                    color: $neutral-ulight;
                }

            }

        }

    }

}


.c-input--img-radio {
  border: 0;
  height: unset;

  .c-svg {
    width: 100%;
    height: 100%;
  }

  input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    + label {
      position: relative;
      display: block;
      max-width: 80px;
      height: 80px;
      margin: 0 auto;
      padding: 0;
      user-select: none;
      cursor: pointer;

      color: $color-grey60;
      transition: color 250ms ease-out;
    }

    &:hover,
    &:focus {

      + label {
        will-change: color;
        color: $color-grey80;
      }

    }

    &:checked {

      + label {
        color: $primary-blue;
      }

    }

    &:disabled {

      + label {
        color: $neutral-ulight;
      }

      &:focus,
      &:hover {

        + label {
          color: inherit;
        }

      }

      &:checked {

        + label {
          color: $neutral-ulight;
        }

      }

    }

  }

}


/**
 * Combo field input and button, like a search field with an icon button next
 * to it.
 *

    <div class="c-form-field">
      <label class="c-form-field__label">Search</label>
      <div class="c-form-field--btn-combo">
        <input type="text" name="name" class="c-input c-input--btn-combo" placeholder="Search" />
        <a class="c-btn c-btn--primary  [ c-btn--input-combo ]" role="button"><span>Search</span></a>
      </div>
    </div>

 *
 * 1. Add classes to target PrimeNG controls. Remove if not using PrimeNG.
 * 2. The field is probably in a colum or container with a width, make the
 *    field full width minus the width of your button. Default is 40px for
 *    the search icon.
 * 3. Remove the border radii on the right side of the field.
 */
.c-form-field--btn-combo {
  @if ($use-markup-fix == false) {
    font-size: 0;
  }

  .c-input--btn-combo,
  .c-btn--input-combo {

    @if ($use-markup-fix == false) {
      font-size: $inuit-global-font-size;
      font-size: 1rem;
    }

  }


}

.c-input--btn-combo {

  &,
  &.ui-inputtext { /* [1] */
    padding-left: $global-unit-small;
    padding-right: $global-unit-small;

    @include calc(width, 100%, "-", 40px, 76%); /* [2] */
    max-width: 100%;
    border: $c-input-border-width solid $c-input-border-color;
    border-radius: $global-radius;
    border-top-right-radius:    0 !important; /* [3] */
    border-bottom-right-radius: 0 !important; /* [3] */
  }

}




/* Validation styles
   ========================================================================== */
// 1. Make room for the validation icons that appear left of the field.
.c-form--has-validation {
  padding-left: $global-unit-tiny; /* [1] */

  @include mq($from: desktop) {
    padding-left: 0;
  }

}


.c-input--validation {
  position: relative;

  &:before {
    position: absolute;
    left: -$global-icon-size;
    width:  $global-icon-size;
    height: $global-icon-size;
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
  }


  &[data-form-field-status="completed"] {

    &:before {
      top: 10px;
      @include svg-bg-img($svg-field-success, $global-icon-size, $global-icon-size, $color-ui-success);
    }

  }


  &[data-form-field-status="error"] {

    &:before {
      top: 9px;
      @include svg-bg-img($svg-field-error, $global-icon-size, $global-icon-size, $color-ui-error);
    }

    .c-input {
        border-color: $color-ui-error;
    }

    [type="radio"],
    [type="checkbox"] {
      border: 1px solid $color-ui-error;
    }

    }


    &[data-form-field-status="warning"] {

      &:before {
        top: 8px;
        @include svg-bg-img($svg-field-warning, $global-icon-size, $global-icon-size, $color-ui-warning);
      }

      .c-input {
        border-color: $color-ui-warning;
        box-shadow: 0 0 4px $color-ui-warning;
      }

    }

    // Adjustments for certain fields
    &[data-form-field*="-gender"] {
      &:before {
        top: 0;

        @include mq($from: tablet) {
          top: 4px;
        }
      }
    }

    &[data-form-field*="-MaskType"],
    &[data-form-field*="-sleep-test"] {
      &:before {
        top: 40px;

        @include mq($from: tablet) {
          top: 68px;
        }

      }
    }

    &[data-form-field*="-MaskModel"] {
      &:before {
        top: 0;
      }
    }

    &[data-form-field="signup-accept-terms"] {
      &:before {
        top: 23px;

        @include mq($from: tablet) {
          top: 26px;
        }

      }

      .c-input--checkbox {
        input[type="checkbox"] {

          + label,
          + .c-form-field__label {

            &:before {
              top: -4px;
            }

            &:after {
              top: 3px;
            }

          }
        }
      }
    }

    &[data-form-field="signup-accept-terms-eu"],
    &[data-form-field="signup-accept-privacy-eu"] {
      &:before {
        top: 0;

        @include mq($from: tablet) {
          top: 4px;
        }

      }
    }
}



/* Custom form controls
   ========================================================================== */
/* Phony image radio button hybrid
 *
 * 1. Default size for picker element, use modifier class to size specific controls.
*/
.c-form-img-picker {
  display: block;
  width:  80px; /* [1] */
  // height: 80px; __<<ngThemingMigrationEscapedComment44>>__
  margin: 0 auto;
  text-align: center;

  @include mq($from: $bp-414) {
    width: 100px; /* [1] */
    // height: 100px; __<<ngThemingMigrationEscapedComment46>>__
  }

  @include mq($from: tablet) {
    width: 150px; /* [1] */
    // height: 150px; __<<ngThemingMigrationEscapedComment48>>__
  }

}


  /*.c-form-img-picker--mask-type {
      width: 100px;
      height: 100px;

      @include mq($from: tablet) {
          width: 150px;
          height: 150px;
      }

  }*/

.c-form-img-picker--masks,
.c-form-img-picker--device {
  width: auto;
  height: auto;
  // max-width: 300px;
  // margin: 0 $global-unit-tiny;

  // @include mq($from: $bp-414) {
  //   max-width: 348px;
  // }

  // @include mq($from: tablet) {
  //   max-width: 308px;
  //   margin: 0 $global-unit-small;
  // }

  // width: 130px;
  // height: 166px;
  //
  // @include mq($from: $bp-375) {
  //     width: 158px;
  //     height: 192px;
  // }
  //
  // @include mq($from: $bp-414) {
  //     width: 176px;
  //     height: 210px;
  // }
  //
  // @include mq($from: tablet) {
  //     width: 235px;
  //     height: 300px;
  // }
  //
  // @include mq($from: desktop) {
  //     width: 265px;
  //     height: 300px;
  // }
  //
  // @include mq($from: wide) {
  //     width: 320px;
  //     height: 360px;
// }

}


.c-form-img-picker__item {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  //height: 100%;
  background-color: $color-white;
  border: none;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  @include appearance(none);


  .c-form-img-picker--masks &,
  .c-form-img-picker--device & {
    margin: 0;
  }

}


.c-form-img-picker__img {
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //margin: -48% 0 0 -48%;
  width: 100%;
  height: 100%;
  outline: none;
  line-height: 1;
  border-width: 5px;
  border-style: solid;
  border-color: $global-border-color;
  transition: border 350ms ease;


  &:hover,
  &:focus,
  &:active {
    border-color: $primary-blue;
  }

  .is-active & {
    border-color: $primary-blue-active;
  }

}


.c-form-img-picker--device {
  @include mq($until: tablet) {
    margin-bottom: $global-unit;
  }

  .c-form-img-picker__img  {
    position: relative;
    @include display-flex();
    width:  200px;
    height: 200px;
    margin: 0 auto;
    overflow: hidden;
    align-items: center;

    @include mq($from: tablet) {
      width:  286px;
      height: 286px;
    }

    > img {
      display: block;
      width: 100%;
      height: auto;
      max-height: initial;
    }

  }

}


.c-form-img-picker__label {
  @include font-size(12px);
  color: $c-form-field-label-color;
  font-weight: $font-weight-light;

  @include mq($from: tablet) {
    @include font-size(16px);
  }

}


.c-form-img-picker__item--round {
  border-width: 5px;
  border-style: solid;
  border-color: $global-border-color;
  border-radius: 50%;
  transition: border 350ms ease;


  &:hover,
  &:focus,
  &:active {
    border-color: $primary-blue;
  }

  &.is-active {
    border-color: $primary-blue-active;
  }

  img,
  [type="image"] {
    border: none;
    border-radius: 50%;
  }

}


.c-input--dummy-radio {
  position: relative;
  display: inline-block;
  //float:left;
  width: 16px;
  height: 16px;
  margin: 0 6px 0 0;
  border: 1px solid $color-grey20;
  // background-color: $color-grey10;
  box-shadow: inset 0px 0px 0px 3px rgba($color-white,1);
  border-radius: 40px;

  .is-active & {
    background-color: $primary-blue;
  }

}



/* Form buttons
   ========================================================================== */
/**
 * Group your Save and Cancel buttons inline.
 * {Button} can be an anchor tag, button tag, or input.
 *
 * HTML

    <div class="c-form-buttons  [ o-layout o-layout--auto ]">
      <div class="o-layout__item">
        < {button} class="c-btn c-btn--primary" [...]>Save</>
      </div>
      <div class="o-layout__item">
        < {button} class="c-btn c-btn--secondary" [...]>Cancel</>
      </div>
    </div>

 *
 */
.c-form-buttons {
  margin-bottom: 0;
  padding-top: $global-unit;

  @include mq($from: tablet) {
    padding-top: $global-unit-large;
  }

  &.o-layout--auto > .o-layout__item {
    @include mq ($until: tablet) {
      width: 100% !important;
    }
  }

  .c-btn {
    @include mq($from: tablet) {
      margin-bottom: 0;
    }
  }

}


.c-form-field--buttons {
  margin-bottom: 0;
  margin-top: $global-unit;


  .c-btn {
    margin-bottom: 0;
  }

}


.c-form-field--buttons-wizard {
  display: block;
  padding-top: $global-unit;
  border-top: 1px solid $global-border-color;
  @include clearfix();

  @include mq($until: desktop) {
    width: auto;
    margin-right: -$global-unit;
    margin-left: -$global-unit;
    padding-right: $global-unit;
    padding-left: $global-unit;
  }

}


.c-form-field--buttons-wizard__next {
  margin-bottom: $global-unit;

  @include mq($from: tablet) {
    width: 50%;
    margin-bottom: 0;
    float: right;
    text-align: right;
  }

}

.c-form-field--buttons-wizard__back {
  margin-bottom: $global-unit;

  @include mq($from: tablet) {
    width: 50%;
    margin-bottom: 0;
    float: left;
  }

}


/* Hidden form control
   ========================================================================== */
/**
 * Form input that is hidden from view until a user interaction occurs.
 * Such as showning a text input field when the user select `Other` from a
 * radio button list. Works with JS to toggle data-state.
 */
.c-hidden-field {

  .c-hidden-field__item {
    transition: max-height 500ms ease-out, opacity 500ms ease-out 150ms;
  }

  &[data-state="is-hidden"] {

    .c-hidden-field__item {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
    }

  }

  &[data-state="is-animating"] {

    .c-hidden-field__item {
      opacity: 0;
    }

  }

  &[data-state="is-active"] {

    .c-hidden-field__item {
      margin-top: 0;
      max-height: 400px;
      overflow: visible;
      opacity: 1;
      will-change: opacity;
    }

  }

}






// Legacy stuff - may remove later

/* Group input types
   ========================================================================== */

// Place a single or group of radio buttons or checkboxes and its label inside
// this container
.c-input-rc-group {
  display: block;
}

  .rc-group__item {
    display: block;
    margin: 0;
    margin-bottom: $global-unit;
    font-weight: $font-weight-normal;
    line-height: 1;
  }


  .rc-group--inline {
    margin-left: -$global-unit;

    @if ($inuit-use-markup-fix == false) {
      font-size: 0;
    }

    .rc-group__item {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      padding-left: $global-unit;


      @if ($inuit-use-markup-fix == false) {
        font-size: $inuit-global-font-size; /* [6] */
        font-size: 1rem;
      }

    }

  }


  .c-input-radio {
    vertical-align: baseline;

        label,
        .c-form-field__label {
            display: inline-block;
            @include calc(width, 100%, "-", 22px, 76%);
            max-width: 100%;
            color: $color-black;
            @include font-size(18px);
            font-weight: $font-weight-normal;

            &:after {
                content: "";
                white-space: initial;
            }

            &.is-hint {
                @include font-size(16px);
            }

            @include mq($from: tablet) {
                @include font-size(21px);
            }

        }

  }



    .c-input-checkbox {
        @include clearfix();

        label,
        .c-form-field__label {
            display: inline;
            color: $color-black;
            @include font-size(18px);
            font-weight: $font-weight-normal;

            &:after {
                content: "";
                white-space: initial;
            }

            &.is-hint {
                @include font-size(16px);
            }

            @include mq($from: tablet) {
                @include font-size(21px);
            }

        }


        [type="checkbox"] {
            display: block;
            float: left;
            width: 16px;
            height: 16px;
            margin-top: 3px;
            margin-right: $global-unit-tiny;

            .o-media & {
                margin-right: 0;
            }

        }

    }



.c-input--signup-terms {
    padding-top: $global-unit-large;
}
