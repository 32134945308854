/* ==========================================================================
   #FOOTER
   ========================================================================== */
// Page footer

$page-footer-text-color:        $neutral-light !default;
$page-footer-bgcolor:           $primary-black !default;
$page-footer-font-size:         14px !default;
$page-footer-m-top-padding:     $global-unit !default;
$page-footer-m-bottom-padding:  $global-unit !default;
$page-footer-m-top-padding:     $global-unit !default;
$page-footer-m-bottom-padding:  $global-unit !default;
$page-footer-top-padding:       $global-unit-large !default;
$page-footer-bottom-padding:    $global-unit !default;


/**
 * 1. By default, the text is centered (mobile first).
 * 2. Reset some defaults for text elements we might use in the footer
 * 3. Line up the footer text with the Resmed logo in this particular layout.
 */
.page-footer {
  padding: 0;
  padding-top:    $page-footer-m-top-padding;
  padding-bottom: $page-footer-m-bottom-padding;
  background-color: $page-footer-bgcolor;
  text-align: center; /* [1] */


  /* stylelint-disable selector-list-comma-newline-after */
  p {
    margin-bottom: 0; /* [2] */
  }

  p, a,
  ul, li {
    color: $page-footer-text-color; /* [2] */
    @include font-size($page-footer-font-size); /* [2] */
  }
  /* stylelint-enable selector-list-comma-newline-after */

  a {

    & {
      text-decoration: none;  /* [2] */
    }

  }

  @include mq($from: tablet) {
    padding-top:    $page-footer-top-padding;
    padding-bottom: $page-footer-bottom-padding;
    text-align: left;

    .o-layout__item {
      vertical-align: top; /* [3] */
      margin-bottom: 0; /* [3] */
      line-height: 1; /* [3] */
    }

  }

}

.page-footer__copyright {
  padding-bottom: $global-unit;
  text-align: center;

  @include mq($from: desktop) {
    padding-bottom: $global-unit-medium;
    text-align: right;
  }

  p span {
    @include mq($until: tablet) {
      display: block;
    }
  }

}




/* Footer link list
   ========================================================================== */
.page-footer__links {
  padding-bottom: $global-unit;
  text-align: center;

  @include mq($from: desktop) {
    padding-bottom: $global-unit-medium;
    text-align: left;
  }

  &.page-footer__links-item:first-child {

    @include mq($from: tablet) {
      margin-left: 0;
    }

  }

}

.page-footer__links-item {
  display: inline-block;
  margin-left: $global-unit-tiny;
  margin-right: $global-unit-tiny;
  
  @include mq($from: tablet) {
    margin-left: $global-unit-small;
    margin-right: $global-unit-small;
  }

  @include mq($from: desktop) {
    margin-left: 0;
    margin-right: $global-unit;
    margin-bottom: $global-unit-small;
  }

}





/* Variant
   ========================================================================== */
/**
 * 1. Override the max-width on the `.o-wrapper` element so the footer will span
 *    the width of the viewport while keeping the outer gutters.
 * 2. Keep the ouside gutters in case it is being removed on the `.o-wrapper` 
 *    element at x-wide breakpoint.
 */
.page-footer--full {

  .o-wrapper {
    width: 100% !important;
    max-width: 100% !important; /* [1] */
    padding-right: $global-unit; /* [2] */
    padding-left:  $global-unit; /* [2] */
  }

}


.page-footer__languages {
  padding-top: $global-unit-small;
  border-top: 1px solid $color-grey30;
  @include clearfix();
}

.page-footer__languages-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  @include clearfix();
}


.page-footer__languages-list-item {
  list-style-type: none;
  margin: 0;
  margin-bottom: $global-unit-small;
  padding: 0;
  width: 33%;
  float: left;
  line-height: 1;
  @include clearfix();
      
  @include mq($from: tablet) {
    width: auto;
  }

  &:first-child {

    .page-footer__languages-link {
      border: 0;

      span {
      
        @include mq($from: tablet) {
          padding-left: 0;
        }

      }

    }

  }

}


.page-footer__languages-link {
  display: block;
  margin: 0;
  @include font-size(11px, 1);
  color: $color-grey30;
  width: 100%;
  text-align: center;
  float: left;


  @include mq($from: tablet) {
    width: auto;
    text-align: left;
    float: none;
    border-left: 1px solid $color-grey30;
  }


  &.is-active {
    color: $color-white;
    text-decoration: underline;
  }

  span {
    display: block;
    padding: 0 $global-unit-small;
  }

}



/* Layout variations
   ========================================================================== */
.page-footer--countries {
  padding-top: 0;
  background-color: transparent;
  text-align: center;

  @include mq($from: tablet) {
    @include animation-name(aniStayHidden, aniFadeIn);
    @include animation-duration(1500ms, 550ms);
    @include animation-delay(0s, 1500ms);
    @include animation-timing-function(linear, ease-out);
    // seems to break IE11
    //@include animation-fill-mode(forwards);
    //@include animation-iteration-count(1);
  }


  .page-footer_country-heading {
    margin-bottom: $global-unit-tiny;

    @include mq($until: tablet) {
      margin: 0;
      @include font-size(12px);
      font-weight: $font-weight-medium;
    }

  }


  .page-footer_country-text {
    margin: 0;
    @include font-size(12px);
    color: $h-headings-color;

    @include mq($from: tablet) {
      @include font-size(14px);
    }

  }


  .o-wrapper {
    padding-top: $global-unit;
    padding-bottom: $global-unit;
  }

}

.page-footer--basic {
  text-align: center;
  background-color: $color-white;

  p, 
  a,
  ul, 
  li {
    color: $primary-black;
  }

  .page-footer__links {
    padding-top: $global-unit;
    padding-bottom: $global-unit;
    text-align: center;

    @include mq($from: desktop) {
      padding-top: $global-unit;
      padding-bottom: $global-unit;
      text-align: center;
    }

  }

  .page-footer__copyright {
    padding-top: $global-unit;
    padding-bottom: 0;
    text-align: center;
    @include inuit-font-size(14px);

    @include mq($from: desktop) {
      padding-top: $global-unit;
      padding-bottom: 0;
      text-align: center;
    }

  }

  .o-wrapper {
    border-top: 1px solid $global-border-color;
  }

}