/* ==========================================================================
   #FLOAT
   ========================================================================== */
// Handle your floats.

/* Force floats
   ========================================================================== */
$float-directions: none, right, left !default;

@if ($float-directions != null) {

  @each $float in $float-directions {
    .u-float-#{$float} { float: $float !important; }
  }

}



/* Respsonsive floats
   ========================================================================== */
$responsive-float-directions: $float-directions !default;


@if ($responsive-float-directions != null) {

  @each $float in $responsive-float-directions {
    
    .u-float-#{$float} { 
      
      @each $bp-name in $bp-key-names {
        $index: index($bp-key-names, $bp-name);
        
        @if ($index != 1) {

          &#{$bp-separator}#{$bp-name} {

            @include mq($from: $bp-name) {
              float: $float !important;
            }

          }

          &-below-#{$bp-name} {

            @include mq($until: $bp-name) {
              text-align: $float !important;
            }

          }

        }

      }
      
    }

  }

}