﻿/* ==========================================================================
   #TRENDS
   ========================================================================== */
// Trends section of the Dashboard.


/* Navigation for trends flexslider
   ========================================================================== */
.c-trends-nav {
  padding-bottom:0;
  @include clearfix();
}


.c-trends-nav__list {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -$global-unit-small;
  @include clearfix();


  @if ($inuit-use-markup-fix == false) {
    font-size: 0;
  }

}


.c-trends-nav__item {
  position: relative;
  display: inline-block;
  padding-left: $global-unit-small;
  text-align: center;

  @if ($inuit-use-markup-fix == false) {
    font-size: $inuit-global-font-size;
    font-size: 1rem;
  }

}


.c-trends-nav__tab {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding-block-end: 0;
  padding: $global-unit-tiny;
  padding-top: 0;
  @include font-size(12px);
  text-align: center;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  &:hover {
    cursor: pointer;
  }

  .c-svg {
    position: relative;
    display: block;
    margin: 0 auto;
    width:  32px;
    height: 32px;

    @include mq($from: tablet) {
      width:  42px;
      height: 42px;
      margin-bottom: $global-unit-small;
    }

  }

  &.usage-hours .c-svg {
    color: $primary-blue;
  }

  &.mask-leak .c-svg {
    color: $color-mask-leak;
    width:  38px;

    @include mq($from: tablet) {
      width:  50px;
    }
  }

  &.ahi-events .c-svg {
    color: $secondary-red;
  }

  &.mask-on-off .c-svg {
    color: $color-mask-onoff;
  }

  &.sleep-score .c-svg {
    color: $secondary-amber;
  }


  .is-active & {
    background-color: $color-white;

    &.usage-hours {
      border-bottom-color: $primary-blue;
    }

    &.mask-leak {
      border-bottom-color: $color-mask-leak;
    }

    &.ahi-events {
      border-bottom-color: $secondary-red;
    }

    &.mask-on-off {
      border-bottom-color: $color-mask-onoff;
    }

    &.sleep-score {
      border-bottom-color: $secondary-amber;
    }

  }

}



.c-trends-nav__label {
  display: none;

  @include mq($from: desktop) {
    display: block;
  }

}





/* Trend data - the slides in the carousel
  ========================================================================== */
.c-trends-data {
  position: relative;
  margin: 0 auto;
  padding: 0 $global-unit-tiny;
  @include clearfix();

  @include mq($from: tablet) {
    padding: 0;
  }

}


.c-trends-data__header {
  display: none;
  padding-bottom: $global-unit;
  text-align: center;
}


  .c-trends-data__heading {

    @include mq($until: tablet) {
      margin-bottom: $global-unit-small;
      @include font-size(14px, 1.2);


      a {
        display: block;
      }
    
    }

  }


  .c-trends-data__text {
    margin-bottom: 0;

    @include mq($until: tablet) {
      @include font-size(12px);
    }

  }



.c-trends-data__mobiPager {
  position: relative;
  width: 200px;
  margin: $global-unit-tiny auto $global-unit;
  text-align: center;

  .mobiPager-dates  {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    padding: 0 $global-unit-small;
    width: auto;
    min-width: 120px;
    text-align: center;
    @include font-size(12px);
  }

  .c-directional-nav__arrow {
    width:  24px;
    height: 24px;

    @include all-anchor-states() {
      outline: none;
    }
  }

}



.c-trends-data__chart {
  position: relative;
  display: block;
  margin: 0 auto;
  height: 305px;


  @include mq($from: tablet) {
    height: 350px;
  }

  @include mq($from: desktop) {
    height: 450px;
  }

  @include mq($from: wide) {
    height: 600px;
  }


  > .flot-chart {
    margin: 0 auto;
    width: 100%;
    height: 100%;


    .tickLabel {
      @include font-size(10px, 1.25);

      @include mq($from: desktop) {
        @include font-size(11px, 1.65);
      }

    }

  }

}


// Flot styles
.xaxisLabel,
.yaxisLabel,
.y1LabelLayer,
.x1LabelLayer {
  font-weight: $font-weight-bold;
}

/* Chart bits
  ========================================================================== */
.c-chart-tooltip {
  position: absolute;
  display: none;
  top: 0;
  //left: calc(50% -1px);
  //width:36px;
  //margin-top: -48px;
  padding: 8px;
  max-width: 140px;
  border: 1px solid $neutral-medium;
  background-color: $color-white;
  font-family: $font-family_sans-serif;
  border-radius: 12px;
  @include font-size(12px, 1.15);

  @include transform(translateY(-120%) translateZ(0));
  box-shadow: 0px 6px 5px -4px rgba(0, 0, 0, 0.3);
  backface-visibility: hidden;
  transition-duration: 0.3s;
  transition-property: transform;
  z-index: 100;
  text-align: center;

  @include mq($from: tablet) {
    @include font-size(14px, 1.4);
  }


  @include mq($from: tablet) {
    max-width: initial;
  }


  .c-chart-tooltip__label {
    display: block;
    margin-bottom: $global-unit-tiny;
    color: $html-text-color;
    @include font-size(11px, 1.15);
  }


  .c-chart-tooltip__data {
    font-weight: $font-weight-bold;
    color: $color-black;
  }


  &.average {
    display: block;
    transition-delay: 0.5s;
  }


  &.is-active {
    @include translateY(-125%);
  }

  // directional arrows, two overlaid arrows to create outline.
  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: 100%;
    right: auto;
    bottom: auto;
    left: 50%;
    content: "";
    @include translate(-50%, 0px);
  }
  
  &:before {
    border-top:    10px $color-white solid !important; 
    border-left:   6px transparent solid !important; 
    border-right:  6px transparent solid !important;
    border-bottom: 0; 
    z-index: -1;
  }

  &:after {
    border-top:    12px $neutral-medium solid !important; 
    border-left:   8px transparent solid !important; 
    border-right:  8px transparent solid !important;
    border-bottom: 0;
    @include translate(-50%, 1px);
    z-index: -2;
  }

}



#legend {

  table{
    margin: 5px auto;

      td.legendLabel {
        @include font-size(11px, 1.3);
        padding: 0 8px 0 2px;
      }

  }

}


.c-trends-data__threshold {
  position: absolute;
  top: 0;
  right: 8px;
  color: $color-black;
  @include font-size(10px, 1);
  font-weight: $font-weight-bold;

  @include mq($from: tablet) {
    right: 18px;
  }

  @include mq($from: tablet) {
    right: 21px;
    @include font-size(12px, 1);
  }

  @include mq($from: desktop) {
    right: 24px;
  }

}