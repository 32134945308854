
/* ==========================================================================
   #OKTA
   ========================================================================== */

// Okta login widget
.primary-auth,
.mfa-verify,
.forgot-password {
  position: relative;
}

.o-form-content.o-form-theme.clearfix {
  @include inuit-clearfix();
}

.okta-form-title {

  &.o-form-head {
    color: $html-text-color;
    font-weight: $font-weight-bold;
    line-height: 1.15;
  }

}

// .o-form-error-container {}

// .o-form-fieldset-container {

// }

.o-form-fieldset {
  position: relative;
  box-sizing: border-box;
  vertical-align: top;
  margin-bottom: $global-unit;

  @include mq($from: tablet) {
    display: inline-block;
    width: 100%;
  }

}

.okta-form-label {
  &.o-form-label {
    display: block;
    color: $neutral-heavy;
    @include font-size(16px, 1.25);
    font-weight: $font-weight-medium;
    margin-bottom: $global-unit-tiny;

    @include mq($from: tablet) {
        display: inline-block; /* [1] */

        &:after { /* [2] */
          content: "\A";
          white-space: pre;
        }

    }
  }
}

.o-form-input {
  position: relative;
  margin: 0;
}

.o-form-control {

  input[type="tel"],
  input[type="text"],
  input[type="password"] {
    display: inline-block;
    width: 100%;
    height: $input-field-height;
    padding: 0;
    @include font-size(18px);
    color: $primary-black;
    background-color: #fafafa !important;
    border: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $neutral-medium;
    border-radius: 0;
    box-shadow: none;
    @include appearance(none);

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-bottom-color: $primary-blue;
    }
  }

  // &.focused-input {
  //   input[type="text"],
  //   input[type="password"] {
  //     &:focus,
  //     &:active {
  //       outline: none;
  //       box-shadow: none;
  //       border-bottom-color: $primary-blue;
  //     }
  //   }
  // }

  input:disabled {
    cursor: default;
    opacity: $opacity-disabled;
  }

  @include mq($from: tablet) {
    input[type="text"],
    input[type="password"] {
      @include font-size(21px);
    }
  }
}

.okta-form-input-error {
  position: relative;
  padding: 0;
  padding-top: $global-unit-tiny;
  @include font-size(14px, 1.25);
  color: $color-ui-error;
}

.mfa-send-email-content {
  margin-bottom: $global-unit-large;
}

.mfa-email-sent-content {
  margin-bottom: $global-unit;
}

.mfa-send-email-content,
.mfa-email-sent-content {
  .mask-email {
    font-weight: $font-weight-bold;
  }
}

.resend-email-infobox {
  margin-bottom: $global-unit;

  .infobox {

    span.icon {
      display: none;
    }
    p {
      margin-bottom: 0;

      + span {
        font-weight: $font-weight-bold;
      }

      .resend-email-btn {
        margin-left: $global-unit-small;
      }
    }
  }
}


.auth-footer {
  @include mq($until: tablet) {
    &,
    .help-links.js-help-links .link {
      text-align: center;
    }
  }

  @include mq($from: tablet, $until: wide) {
    padding-top: $global-unit;

    &,
    .help-links.js-help-links .link {
      text-align: left;
    }
  }

  @include mq($from: wide) {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
    transform: translate(0, -32px);

    .forgot-password & {
      transform: translate(0, -34px);
    }
  }

  .help-links.js-help-links {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
    padding: $global-unit-tiny 0;
    text-align: left;
    background-color: $color-white;
    border-radius: $global-radius;
    border: 1px solid $neutral-ulight;
    box-shadow: 0 1px 16px -3px rgba(0,0,0,0.3);

    .link {
      display: block;
      padding: $global-unit-small;
    }
  }

}

.o-form-error-container > div {
  position: relative;
  display: table;
  width: 100%;
  margin-bottom: $global-unit;
  padding: $global-unit $global-unit $global-unit $global-unit-huge;
  @include font-size($c-alert-font-size, 1.15);
  font-weight: $font-weight-medium;
  border-spacing: 0;
  border-radius: $global-radius;
  background-color: $color-ui-error;

  .okta-form-infobox-error {
    &,
    p {
      margin: 0;
      color: $color-white;
    }

    .icon.error-16 {
      @include position-y-center();
      left:   $global-unit;
      width:  $global-unit-large;
      height: 32px;
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: $color-white;

      &:before {
        display: block;
        width:  $global-unit-large;
        height: 32px;
        @include svg-bg-img($c-alert-error-icon, $global-icon-size, $global-icon-size, $color-white); /* [1] */
        background-size: unset !important;
        background-position: 0 50%;
      }
    }

  }
}
