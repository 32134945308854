/* ==========================================================================
   #WRAPPER
   ========================================================================== */

$inuit-wrapper-width:   1200px !default;
$inuit-wrapper-padding: $inuit-global-spacing-unit !default;

/**
 * Page-level constraining and wrapping elements.
 */

@if (type-of($inuit-wrapper-width) != number) {
  @error "`#{$inuit-wrapper-width}` needs to be a number.";
}

.o-wrapper {
  @include inuit-clearfix();
  padding-right: $inuit-wrapper-padding;
  padding-left:  $inuit-wrapper-padding;
  margin-right: auto;
  margin-left:  auto;
  max-width: $inuit-wrapper-width;
}