/* ==========================================================================
   #FORMS
   ========================================================================== */

$form-placeholder-text-color:   $neutral-light !default;
$form-input-text-color:         $color-black !default;
$form-font-size:                $inuit-global-font-size !default;
$form-font-family:              $font-family-sans-serif !default;
$form-font-weight:              $font-weight-normal !default;

/**
 * Form elements are already normalized by "inuitcss/generic/generic.normalize"
 * We can assign our base font settings for form inputs here.
 */

// input field placeholder text style
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  font-style: normal;
  color: $form-placeholder-text-color !important;
}

/**
 * 1. Change the normalized font-family and font-size.
 * 2. Set our default font-weight.
 * 3. Make sure the text color is black by default.
 */

/* stylelint-disable selector-list-comma-newline-after */
input, button, select, option, textarea, select option {
  font-family: $form-font-family; /* [1] */
  font-weight: $form-font-weight; /* [2] */
  @include font-size($form-font-size); /* [1] */
  color: $form-input-text-color; /* [3] */
}
/* stylelint-enable selector-list-comma-newline-after */


/* Change Autocomplete styles in Chrome */
input,
textarea,
select {

  &:-webkit-autofill {
    &,
    &:hover,
    &:focus {
      font-family: $form-font-family !important;
      font-weight: $form-font-weight !important;
      @include font-size(18px, auto, 0, true);
      color: $form-input-text-color !important;
      border-color: $primary-blue !important;
      -webkit-text-fill-color: $form-input-text-color !important;
      -webkit-box-shadow: 0 0 0 1000px rgba($primary-blue, 0.12) inset !important;
      transition: background-color 0s 5000s ease-in-out;
    }

    @include mq($from: tablet) {
      @include font-size(21px, auto, 0, true);
    }

  }

  &:-webkit-autofill::first-line {
    font-family: $form-font-family !important;
    font-weight: $form-font-weight !important;
    @include font-size(18px, auto, 0, true);
    color: $form-input-text-color !important;

    @include mq($from: tablet) {
      @include font-size(21px, auto, 0, true);
    }
  }

}


/**
Keeping a list in case we need to explicitly target an input by type
for setting defaults. Styling should be done with a modifier class
in `components.forms.scss`

[type="tel"],
[type="url"],
[type="text"],
[type="date"],
[type="email"],
[type="month"],
[type="button"],
[type="submit"],
[type="search"],
[type="number"],
[type="textbox"],
[type="password"]

*/
