/* ==========================================================================
   #TABLES
   ========================================================================== */
/** inuitcss classes defined in inuitcss/objects/objects.tables that we can
 * apply to our `table` tag:
 *
 * .o-table - table object, sets width to 100% again
 *
 * .o-table--fixed - fixed width table cells
 *
 * Size variants for `th` and `td` padding based on our global spacing unit:
 * .o-table--tiny
 * .o-table--small
 * .o-table--large
 * .o-table--huge
 *
 * How to use:
 * <table class="o-table o-table--fixed o-table--small">
 *
 * Related files:
 * /inuitcss/objects/objects.tables.scss
 * /inuitcss/elements/elements.tables.scss
 * elements/elements.tables.scss
 */

/* Table themes
   ========================================================================== */
.ui-datatable-tablewrapper {
  th, td {
    @include font-size(14px);
  }
}



// AirView style grid view
.o-table--av-gridview {
  th, td {
    padding: $global-unit-small 0 $global-unit-small $global-unit-tiny;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    background-color: $color-white;
  }
  th {
    font-weight: $font-weight-bold;
    color: $color-grey80;
    text-align: left;
    border-bottom-color: $color-grey40;
  }
  td {
    font-weight: $font-weight-normal;
    color: $color-grey80;
    border-bottom-color: $color-grey20;
  }
}






/* Legacy styles. Some hard-coded content in the RESX uses these table styles.
 * For example, some of the FAQ support content.
   ========================================================================== */
table {

    &.tbl-scoreData {
        display: table;
        table-layout: fixed;
        width: 100%;
        margin: $global-unit auto;
        @include font-size(20px);
        border-collapse: collapse;

        @include mq($from: desktop) {
            width: 600px;
        }

        caption {
            @include font-size(16px);
            font-style: italic;
        }

        tr {

            th,
            td {
                padding: 6px 16px;
                text-align: center;
                font-weight: $font-weight-bold;
                vertical-align: middle;
            }

            th {
                color: $color-white;
                background-color: $primary-blue;
            }

            td {
                color: $color-black;
                background-color: $color-white;

            }

            &:nth-child(odd) {

                td {
                    background-color: $color-grey5;
                }

           }

        }

    }

}