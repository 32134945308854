﻿/* ==========================================================================
   #SLEEP SCORE
   ========================================================================== */

// Score breakdown max points
$usageMaxPoints:        "/ 70";
$leakMaxPoints:         "/ 20";
$eventsMaxPoints:       "/ 5";
$onOffMaxPoints:        "/ 5";
$scoreMaxPoints:        "/ 100";


/**
 * The sleep score dial, which is generated by the jQuery Knob plugin, and
 * related sleep score elements.
 *
 *   jQuery Knob, by Anthony Terrien
 *   https://github.com/aterrien/jQuery-Knob
 *
 */
#sleepScore,
.c-sleep-score {
    margin-left: auto;
    margin-right: auto;
    width:  300px;
    height: 300px;

    @include mq($from: tablet) {
        width:  360px;
        height: 360px;
        margin-bottom: $global-unit;
    }

}



.c-sleep-score {
    position: relative;
    margin-bottom: $global-unit-small;


    .no-data & {

        .c-sleep-score__knob,
        .c-sleep-score__myair,
        .c-sleep-score__points {
            color: $html-text-color !important;
        }

        .c-sleep-score__points:before {
            border-color: $neutral-medium;
        }

    }

}


.c-sleep-score__item {
    position: relative;
    width: 100%;
    height: 100%;
    border: 0;
	border-radius: 50%;

  .js-gotoSleepScore &,
	.js-gotoPrev &,
	.js-gotoNext & {
        visibility: visible;
        @include animation-name(aniFadeOut);
		@include animation-duration(250ms);
        @include animation-delay(0s);
        @include animation-timing-function(ease-in);
        @include animation-fill-mode(forwards);
        @include animation-iteration-count(1);
    }

}
    .js-loadSleepScore,
    .js-initScore,
    .js-loadPrevScore,
    .js-loadNextScore {

        .has-data {

            .c-sleep-score__item {
                @include animation-name(aniStayHidden, aniFadeIn);
                @include animation-duration(50ms, 250ms);
                @include animation-delay(0s, 50ms);
                @include animation-timing-function(linear, ease-out);
                @include animation-fill-mode(forwards);
                @include animation-iteration-count(1);
            }

        }

        .no-data {

            .c-sleep-score__item {
		        @include animation-name(aniStayHidden, aniFadeIn, aniDim);
		        @include animation-duration(50ms, 250ms, 500ms);
                @include animation-delay(0s, 50ms, 750ms);
                @include animation-timing-function(linear, ease-out, ease-out);
                @include animation-fill-mode(forwards);
                @include animation-iteration-count(1);
            }

        }

    }



.c-sleep-score__score {
    @include position-center();
    width:  286px;
    height: 286px;
    z-index: 100;

    @include mq($from: tablet) {
        width:  346px;
        height: 346px;
    }

    > div {
        display: inline-block !important;
    }

}


.c-sleep-score__score-bg {
    @include position-center();
    width:  292px;
    height: 292px;
    background-color: $color-white;
    z-index: 0;
    overflow: hidden;
    border-radius: 50%;

    @include mq($from: tablet) {
        width:  348px;
        height: 348px;
    }



    .c-sleep-score__myair,
    .c-sleep-score__points {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
    }


    .is-Android & {
        border-color: $color-white;
    }

}


.c-sleep-score__myair {
    top: 72px;
    margin-bottom: 0;
    @include font-size(16px);
    font-weight: $font-weight-bold;
    color: $primary-black;
}


.c-sleep-score__points {
    top: 208px;
    padding-top: $global-unit-tiny;
    margin-bottom: 0;
    @include font-size(16px);
    font-weight: $font-weight-medium;

    &,
    & span {
      color: $neutral-heavy;
    }

    @include mq($from: tablet) {
      top: 240px;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      width: 124px;
      border-top: 1px solid $neutral-heavy;
      content: "";
      height: 1px;
      @include translateX(-50%);
    }

}


.c-sleep-score__knob {
    margin-top: 72px !important;
    letter-spacing: -6px;
    font-family: $html-font-family !important;
    font-weight: $font-weight-medium !important;
    color: $primary-black !important;
    line-height: 1.45 !important;
    outline: none;

    &[disabled] {
        color: $primary-black !important;
    }

    @include mq($from: tablet) {

        html:not(._Telerik_IE9) & {
            margin-top: 84px !important;
        }

    }

}


.c-sleep-score__msg {
    margin: 0;
    margin-bottom: $global-unit;
    min-height: 60px;
    font-weight: $font-weight-medium;
	text-align: center;
	color: $primary-black;
	@include font-size(20px, 1.1);


	@include mq($from: tablet) {
		@include font-size(24px, 1.1);
	}


    // RESX - secondary text in message
	span {
		display: block;
		margin-top: $global-unit-tiny;
        color: $primary-black;
        font-weight: $font-weight-light;
		@include font-size(16px);
	}

  .js-loadSleepScore &,
	.js-initScore &,
	.js-loadPrevScore &,
    .js-loadNextScore & {
		@include animation-name(aniStayHidden, aniFadeIn);
        @include animation-duration(550ms, 150ms);
        @include animation-delay(0s, 550ms);
        @include animation-timing-function(linear, ease-out);
        @include animation-fill-mode(forwards);
        @include animation-iteration-count(1);
	}

  .js-gotoSleepScore &,
	.js-gotoPrev &,
	.js-gotoNext & {
        visibility: visible;
        @include animation-name(aniFadeOut);
		@include animation-duration(250ms);
        @include animation-delay(0s);
        @include animation-timing-function(ease-in);
        @include animation-fill-mode(forwards);
        @include animation-iteration-count(1);
    }

}


.c-sleep-score,
.c-sleep-score__item,
.c-sleep-score__msg {
    visibility: hidden;

  .js-loadSleepScore &,
	.js-initScore &,
	.js-loadPrevScore &,
    .js-loadNextScore & {
        visibility: visible;
        will-change: visibility;
    }

}

.c-sleep-score__last-sync {
    font-family: $font-family_sans-serif;
    @include font-size(16px);
    font-weight: $font-weight-light;
    color: $neutral-heavy;

    @include mq($until: desktop) {
        margin-bottom: $global-unit;
        padding-bottom: $global-unit;
        border-bottom: 1px solid $global-border-color;
    }

    .c-svg {
        color: $primary-blue;
        @include font-size(24px);
    }

}





/* Sleep score breakdown
   ========================================================================== */
.c-sleep-score__details {
    position: relative;
    display: table;
    width: 100%;
    margin-bottom: 34px;
    cursor: pointer;

    @include mq($from: tablet) {
        margin-bottom: $global-unit + $global-unit-small;
    }


    &:hover {

        > .c-sleep-score__details-link .c-svg {
            color: $primary-black;
            @include translateX(50%);
        }

    }

    &:focus {
      outline: none;
    }

    .no-data & {

        .c-sleep-score__details-data,
        .metric-score__points {
            color: $neutral-medium !important;
        }

    }

}


.c-sleep-score__details-icon {
    display: table-cell;
    width: 38px;
    vertical-align: middle;
    text-align: center;

    @include mq($from: tablet) {
        width: 50px;
    }

    .c-svg {
        width:  32px;
        height: 32px;

        @include mq($from: tablet) {
            width:  42px;
            height: 42px;
        }

    }

}


.c-sleep-score__details-body {
    position: relative;
    display: table-cell;
    width: auto;
    vertical-align: middle;

    > .c-sleep-score__details-body {
        width: 100%;
        padding-left: 6px;

        @include mq($from: tablet) {
            padding-left: 12px;
        }
    }

}

    .c-sleep-score__details-data {
        @include font-size(18px, 1);
        color: $html-text-color;
        font-weight: $font-weight-bold;

        @include mq($from: tablet) {
            @include font-size(26px, 1);
        }

        @include mq($from: wide) {
            @include font-size(28px, 1);
        }

        .c-sleep-score__details--sleep-score & {
            font-weight: $font-weight-medium;
        }

    }

    .c-sleep-score__details-label {
        color: $neutral-heavy;
        @include font-size(12px, 1);

        @include mq($from: tablet) {
            @include font-size(18px, 1);
        }

        @include mq($from: wide) {
            @include font-size(20px, 1);
        }

    }


.c-sleep-score__details-score {
    position: relative;
    display: table-cell;
    width: 60px;
    padding-right: 28px;
    text-align: right;
    vertical-align: middle;

    @include mq($from: tablet) {
        width: 100px;
        padding-right: $global-unit-large;
    }

    > .metric-score__points {
        @include font-size(20px, 1);
        font-weight: $font-weight-medium;

        @include mq($from: tablet) {
            @include font-size(30px, 1);
        }

        @include mq($from: wide) {
            @include font-size(34px, 1);
        }

        // the score fraction
        &:before {
            position: absolute;
            top: auto;
            right: 0;
            bottom: 6px;
            width: 25px;
            @include font-size(11px, 1);
            color: $html-text-color;
            text-align: left;
            font-family: $font-family_sans-serif;
            font-weight: $font-weight-light;

            @include mq($from: tablet) {
                width: 40px;
                @include font-size(15px, 1);
            }

        }

    }

}


.c-sleep-score__details-meter {
    position: relative;
    display: block;
    // bottom: 0;
    // left: 0;
    width: 100%;
    height: 4px;
    overflow: hidden;
    background-color: $neutral-light;

    > .metric-meter__fill {
        position: absolute;
		width: 0%;
        height: 100%;
        top: 0%;
        border-right: 2px solid $color-white;
        @include translateX(-100%);
        transition-duration: 1s;
        transition-property: transform, width;
        transition-timing-function: ease-out;
    }

}


/**
 * 1. Let's us vertically-align the arrow and use the translateX property
 */
.c-sleep-score__details-link {
    display: table-cell;
    width: 18px;
    vertical-align: middle;
    text-align: right;

    @include mq($from: tablet) {
        text-align: left;
    }

    .c-svg {
        display: inline-block; /* [1] */
        width:  18px;
        height: 18px;
        color: $neutral-medium;
        @include translateX(0);
        transition-duration: 0.3s;
        transition-property: transform, color;
        transition-timing-function: ease-out;
    }

}


.c-sleep-score__details--usage {

    .c-sleep-score__details-icon,
    .metric-score__points {
        color: $primary-blue;
    }

    .metric-meter__fill {
        background-color: $primary-blue;
    }

    .metric-score__points {

        &:before {
            content: $usageMaxPoints;
        }

    }

}


.c-sleep-score__details--mask-leak {

    .c-sleep-score__details-icon,
    .metric-score__points {
        color: $color-mask-leak;
    }

    .c-sleep-score__details-icon .c-svg {
        width: 38px;

        @include mq($from: tablet) {
            width: 50px;
        }

    }

    .metric-meter__fill {
        background-color: $color-mask-leak;
    }

    .metric-score__points {

        &:before {
            content: $leakMaxPoints;
        }

    }

}


.c-sleep-score__details--ahi-events {

    .c-sleep-score__details-icon,
    .metric-score__points {
        color: $secondary-red;
    }

    .metric-meter__fill {
        background-color: $secondary-red;
    }

    .metric-score__points {

        &:before {
            content: $eventsMaxPoints;
        }

    }

}


.c-sleep-score__details--mask-on-off {

    .c-sleep-score__details-icon,
    .metric-score__points {
        color: $color-mask-onoff;
    }

    .metric-meter__fill {
        background-color: $color-mask-onoff;
    }

    .metric-score__points {

        &:before {
            content: $onOffMaxPoints;
        }

    }

}


.c-sleep-score__details--sleep-score {

    .c-sleep-score__details-icon,
    .metric-score__points {
        color: $secondary-amber;
    }

    .metric-meter__fill {
        background-color: $secondary-amber;
    }

    .metric-score__points {

        &:before {
            content: $scoreMaxPoints;
        }

    }

}


.c-sleep-score__metrics {

    @include mq($from: tablet) {
        padding-top: $global-unit;
    }

    @include mq($from: desktop) {
        padding: 60px 0;
    }

    &.is-active {

        .c-sleep-score__details--usage .metric-meter__fill {
            transition-delay: 0.5s;
        }

        .c-sleep-score__details--mask-leak .metric-meter__fill {
            transition-delay: 0.8s;
        }

        .c-sleep-score__details--ahi-events .metric-meter__fill {
            transition-delay: 1s;
        }

        .c-sleep-score__details--mask-on-off .metric-meter__fill {
            transition-delay: 1.2s;
        }

        .c-sleep-score__details--sleep-score .metric-meter__fill {
            transition-delay: 1.5s;
        }

        .metric-meter__fill {
            @include translateX(0);
		}

    }

}


/* Misc */
.c-tips-link {

  @include mq($from: tablet) {
    text-align: right;
  }

  .c-btn--text {
    margin-bottom: 0;
  }

  &.c-tips-link--bottom {
    .c-btn--text {
      @include mq($until: tablet) {
        text-align: left;
      }
    }
  }

}
