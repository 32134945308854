
/* ==========================================================================
   #DIRECTIONAL NAV
   ========================================================================== */

$c-dnav-label-font-size:        24px !default;
$c-dnav-label-line-height:      46px !default;
$c-dnav-label-font-weight:      $font-weight-medium !default;
$c-dnav-arrow-icon-size:        $global-icon-size-lg !default;
$c-dnav-arrow-color:            $primary-blue !default;
$c-dnav-arrow-disabled-color:   $global-disabled-text-color !default;

/**
 * Previous/Next style navigation buttons (chevron icon).

    <   MONTH   >

 * HTML:

    <div class="c-directional-nav c-directional-nav--month js-dir-nav">
      <span class="c-directional-nav__label">MONTH</span>

      <a href="#prev" id="js-prevMonth" class="c-directional-nav__arrow nav--prev js-dir-nav-btn">
        <svg class="c-svg c-svg--chevron-left-24"><use xlink:href="#c-svg--chevron-left-24"></use></svg>
      </a>
      <a href="#next" id="js-nextMonth" class="c-directional-nav__arrow nav--next is-disabled js-dir-nav-btn">
        <svg class="c-svg c-svg--chevron-right-24"><use xlink:href="#c-svg--chevron-right-24"></use></svg>
      </a>
    </div>

 *
 */
.c-directional-nav {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  width: auto;
  text-align: center;

  @include mq($from: tablet) {
    @include animation(aniStayHidden 1s, aniFadeInDown 250ms 1s 1 ease-out);
  }

}


.c-directional-nav__label {
  @include font-size($c-dnav-label-font-size, $c-dnav-label-line-height);
  font-weight: $c-dnav-label-font-weight;
}


.c-directional-nav__arrow {
  @include position-y-center();
  width:  $c-dnav-arrow-icon-size;
  height: $c-dnav-arrow-icon-size;
  text-align: center;

  // Remove default <button> styles
  padding: 0;
  @include appearance(none);
  border: 0;
  background-color: transparent;

  .c-svg {
    display: block;
    top: 0;
    width:  100%;
    height: 100%;
    @include translateX(0);
    transition-duration: 0.3s;
    transition-property: transform, color;
    transition-timing-function: ease-out;
    color: $c-dnav-arrow-color;
  }

  &.nav--prev {
    left: -40px;
  }

  &.nav--next {
    right: -40px;
  }

  &:hover {
    text-decoration: none;

    &.nav--prev {
      .c-svg {
        @include mq($from: desktop) {
          @include translateX(-20%);
        }
      }
    }

    &.nav--next {
      .c-svg {
        @include mq($from: desktop) {
          @include translateX(20%);
        }
      }
    }

  }

  &.is-disabled {

    &,
    &:hover {
      cursor: default;
      text-decoration: none;
      background-color: transparent;

      .c-svg {
        color: $c-dnav-arrow-disabled-color;
        transition: none;
        @include translateX(0);
      }
    }

  }

}




/* Variations
========================================================================== */
.c-directional-nav--month {
  min-width: 222px;
}
