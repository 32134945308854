/* ==========================================================================
   #BLOCK
   ========================================================================== */

$inuit-block-spacing:       $inuit-global-spacing-unit !default;
$inuit-block-tiny-spacing:  $inuit-global-spacing-unit-tiny !default;
$inuit-block-small-spacing: $inuit-global-spacing-unit-small !default;
$inuit-block-large-spacing: $inuit-global-spacing-unit-large !default;
$inuit-block-huge-spacing:  $inuit-global-spacing-unit-huge !default;

/**
 * Stacked image-with-text object. A simple abstraction to cover a very commonly
 * occurring design pattern.
 */

.o-block {
  display: block;
  text-align: center;
}

  .o-block__img {
    margin-bottom: $inuit-block-spacing;


    /* Size variants
       ====================================================================== */

    .o-block--flush > & {
      margin-bottom: 0;
    }

    .o-block--tiny > & {
      margin-bottom: $inuit-block-tiny-spacing;
    }

    .o-block--small > & {
      margin-bottom: $inuit-block-small-spacing;
    }

    .o-block--large > & {
      margin-bottom: $inuit-block-large-spacing;
    }

    .o-block--huge > & {
      margin-bottom: $inuit-block-huge-spacing;
    }

  }

  .o-block__body {
    display: block;
  }





/* Alignment variants
   ========================================================================== */

.o-block--right {
  text-align: right;
}

.o-block--left {
  text-align: left;
}