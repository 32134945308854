﻿/* ==========================================================================
   #FLAGS
   ========================================================================== */
// Country flag icons

[class^="flag-"]:before,
[class*=" flag-"]:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 17px;
  overflow: hidden;
  content: "";
  background-color: transparent;
  background-repeat: no-repeat;


  @media only screen and (min-width: 960px), screen and (-webkit-min-device-pixel-ratio: 1) {
    background-image: url(/assets/images/flags@1x-min.png);
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(/assets/images/flags@2x-min.png);
    background-size: 460px auto;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3) {
    background-image: url(/assets/images/flags@3x-min.png);
    background-size: 460px auto;
  }

}




/*** Americas  ***/
/* United States */
[class="flag-us"]:before {
  background-position: -406px -99px;
}
/* Canada */
[class="flag-ca"]:before {
  background-position: -57px -99px;
}
/* Argentina */
[class="flag-ar"]:before {
  background-position: -3px -99px;
}
/* Chile */
[class="flag-cl"]:before {
  background-position: -57px -175px;
}
/* Colombia */
[class="flag-co"]:before {
  background-position: -57px -213px;
}
/* Guatemala */
[class="flag-gt"]:before {
  background-position: -137px -137px;
}
/* Mexico */
[class="flag-mx"]:before {
  background-position: -244px -79px;
}
/* Brazil */
[class="flag-br"]:before {
  background-position: -30px -194px;
}




/*** Europe ***/
/* Austria */
[class="flag-at"]:before {
  background-position: -3px -156px;
}
/* Belgium */
[class="flag-be"]:before {
  background-position: -30px -60px;
}
/* Cyprus */
[class="flag-cy"]:before {
  background-position: -84px -137px;
}
/* Czech Republic */
[class="flag-cz"]:before {
  background-position: -83px -156px;
}
/* Denmark */
[class="flag-dk"]:before {
  background-position: -83px -175px;
}
/* Finland */
[class="flag-fi"]:before {
  background-position: -110px -193px;
}
/* Germany */
[class="flag-de"]:before {
  background-position: -137px -60px;
}
/* Greece */
[class="flag-gr"]:before {
  background-position: -137px -99px;
}
/* Italy */
[class="flag-it"]:before {
  background-position: -165px -175px;
}
/* South Africa */
[class="flag-za"]:before {
  background-position: -352px -79px;
}
/* France */
[class="flag-fr"]:before {
  background-position: -110px -213px;
}
/* Ireland */
[class="flag-ie"]:before {
  background-position: -164px -137px;
}
/* Netherlands */
[class="flag-nl"]:before {
  background-position: -271px -79px;
}
/* Norway */
[class="flag-no"]:before {
  background-position: -271px -176px;
}
/* Poland */
[class="flag-pl"]:before {
  background-position: -298px -117px;
}
/* Portugal */
[class="flag-pt"]:before {
  background-position: -298px -137px;
}
/* Spain */
[class="flag-es"]:before {
  background-position: -352px -117px;
}
/* Sweden */
[class="flag-se"]:before {
  background-position: -352px -213px;
}
/* Switzerland */
[class="flag-ch"]:before {
  background-position: -379px -3px;
}
/* United Kingdom */
[class="flag-gb"]:before {
  background-position: -406px -79px;
}
/* Iceland */
[class="flag-is"]:before {
  background-position: -164px -41px;
}
/* Malta */
[class="flag-mt"]:before {
  background-position: -245px -3px;
}

/* Luxembourg */
[class="flag-lu"]:before {
  background-position: -219px -99px;
}

/*** Middle East ***/
/* Saudi Arabia */
[class="flag-sa"]:before {
  background-position: -325px -117px;
}


/*** APAC ***/
/* Australia */
[class="flag-au"]:before {
  background-position: -3px -137px;
}
/* New Zealand */
[class="flag-nz"]:before {
  background-position: -271px -99px;
}
/* Japan */
[class="flag-jp"]:before {
  background-position: -165px -214px;
}
/* Hong Kong */
[class="flag-hk"]:before {
  background-position: -433px -213px;
}
/* Taiwan */
[class="flag-tw"]:before {
  background-position: -378px -41px;
}
/* Singapore */
[class="flag-sg"]:before {
  background-position: -325px -214px;
}
/* Malaysia */
[class="flag-my"]:before {
  background-position: -218px -175px;
}
/* India */
[class="flag-in"]:before {
  background-position: -164px -60px;
}
/* */
[class="flag-ph"]:before {
  background-position: -298px -98px;
}
/* Indonesia */
[class="flag-id"]:before {
  background-position: -245px -137px;
}
/* Thailand */
[class="flag-th"]:before {
  background-position: -379px -98px;
}
/* Vietnam */
[class="flag-vn"]:before {
  background-position: -405px -213px;
}
/* South Korea */
[class="flag-kr"]:before {
  background-position: -191px -99px;
}

