/* ==========================================================================
   #SITENAV
   ========================================================================== */

/**
 * Main menu settings
 */


// Mobile
$m-site-nav-logo-spacing:             $global-unit-small !default;
$m-site-nav-list-bgcolor:             $color-white !default;
$m-site-nav-link-padding:             $global-unit-small !default;
$m-site-nav-link-color:               $primary-black !default;
$m-site-nav-hover-color:              $primary-blue-hover !default;
$m-site-nav-hover-bgcolor:            $neutral-ulight !default;
$m-site-nav-sub-link-color:           $primary-black !default;
$m-site-nav-sub-hover-color:          $primary-blue-hover !default;
$m-site-nav-hamburger-color:          $color-black !default;
$m-site-nav-close-btn-size:           $global-icon-size !default;

$m-mobile-username-font-size:         21px !default;
$m-mobile-username-font-weight:       $font-weight-light !default;
$m-mobile-username-text-color:        $primary-blue !default;


// Desktop
$site-nav-y-alignment:                center !default; // [ flex-start, flex-end, center ]
$site-nav-position:                   right !default;
$site-nav-spacing:                    0 !default; // distance between logos and menu if menu is positioned left.
$site-nav-logo-spacing:               $global-unit-large !default;
$site-nav-link-tablet-spacing:        $global-unit !default;
$site-nav-link-desktop-spacing:       $global-unit-large !default;
$site-nav-link-padding:               0 !default;
$site-nav-link-height:                42px !default; // line-height property, set a link height.
$site-nav-link-color:                 $primary-black !default;
$site-nav-hover-color:                $color-black !default;
$site-nav-active-color:               $primary-blue !default;
$site-nav-hover-bgcolor:              transparent !default;
$site-nav-active-bgcolor:             transparent !default;
$site-nav-font-size:                  $res-global-font-size !default;
$site-nav-font-weight:                $font-weight-medium !default;
$site-nav-ddlb-arrow-size:            16px !default;
$site-nav-ddlb-arrow-color:           $site-nav-link-color !default;
$site-nav-ddlb-arrow-active-color:    $site-nav-active-color !default;
$site-nav-sub-active-color:           $site-nav-active-color !default;
$site-nav-sub-active-bgcolor:         $secondary-blue !default;

// Main menu dropdowns
$site-nav-sub-min-width:              160px !default;
$site-nav-sub-max-width:              260px !default;
$site-nav-sub-bgcolor:                $color-white !default;
$site-nav-sub-border-color:           $neutral-ulight !default;
$site-nav-sub-link-color:             $primary-black !default;
$site-nav-sub-hover-color:            $color-black !default;
$site-nav-sub-hover-bgcolor:          $neutral-ulight !default;

// Menu item is icon only
$site-nav-icon-size:                  $global-icon-size !default;
$site-nav-icon-size-large:            $global-icon-size-lg !default;
$site-nav-icon-default-color:         $site-nav-link-color !default;
$site-nav-icon-hover-color:           $site-nav-hover-color !default;

// Stacked or side nav
$stacked-nav-item-bottom-border:      false !default;  // show the bottom border
$stacked-nav-active-color:            $primary-blue !default;
$m-stacked-nav-active-bgcolor:        $color-white !default;

// Tabs style
$nav-tabs-margin-bottom:              $global-unit-large !default;
$nav-tabs-border-height:              4px !default;
$nav-tabs-border-color-active:        $primary-blue-hover !default;
$nav-tabs-border-color-hover:         $neutral-medium !default;
$nav-tabs-link-color:                 $primary-black !default;
$nav-tabs-link-color-active:          $primary-blue-hover !default;
$nav-tabs-link-line-height:           60px !default;
$nav-tabs-pipe-dividers:              false !default;  // Show a small vertical dividing line between tabs @tablet and above

// Using h1 as wordmark or title
$site-nav-title-font-weight:          $font-weight-medium !default;
$site-nav-title-font-size:            21px !default;
$site-nav-title-text-color:           $site-nav-link-color !default;
$site-nav-title-divider-color:        $site-nav-title-text-color !default;



/**
 * Navigation control for any type of site navigation. Use modifier classes for
 * Main, Tabs, or Stacked navigation.
 */
.site-nav {
  position: relative;
  @include clearfix();
}

.site-nav__branding {
  @include display-flex();
  margin: 0;
  margin-right: $global-unit-small;
  flex-grow: 1;
  align-items: $site-nav-y-alignment;
  @include clearfix();
}

.site-nav__home-link {
  display: inline-block;
  vertical-align: bottom;

  @include all-anchor-states() {
    color: $site-nav-link-color;
    text-decoration: none;
  }

  // @include mq($from: tablet) {
  //   margin-right: $global-unit-large;
  //   // height: $site-nav-link-height;
  // }

}

mpp-my-air-label .site-nav__home-link {
  margin-left: $m-site-nav-logo-spacing;

  @include mq($from: tablet) {
    margin-left: $site-nav-logo-spacing;
  }
}


.site-nav__logo {
  display: block;

  // `h1`
  .site-nav__title {
    position: relative;
    margin: 0;
    @include font-size($site-nav-title-font-size, $site-nav-link-height);
    font-weight: $site-nav-title-font-weight;
    color: $site-nav-title-text-color;

    &.show-divider{

      &:before {
        position: absolute;
        @include position-center();
        left: -24px;
        width: 1px;
        height: 50%;
        border-left: 1px solid $site-nav-title-divider-color;
        content: "";
      }

    }

  }

  + .site-nav__logo {
    margin-left: $m-site-nav-logo-spacing;

    @include mq($from: tablet) {
      margin-left: $site-nav-logo-spacing;
    }
  }

}




/**
 * The mobile menu toggle
 * 1. Mobile menu icon - hamburger
 * 2. Hide it from non-mobile screens
 */
.site-nav__open {
  position: absolute;
  display: block;
  top: 50%;
  right: 0;
  width: 30px;
  height: 25px;
  @include translateY(-50%);

  @include mq($from: tablet) {
    display: none; /* [2] */
  }

  span {
    display: block;
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background: $m-site-nav-hamburger-color;
    border-radius: 0;
    opacity: 1;
    @include rotate(0);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      @include transform-origin(left center);
    }

    &:nth-child(2) {
      top: 10px;
      @include transform-origin(left center);
    }

    &:nth-child(3) {
      top: 20px;
      @include transform-origin(left center);
    }

  }

  &:target {

    span {

      &:nth-child(1) {
        @include rotate(45);
        top: 10px;
        left: 0px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        @include rotate(-45);
        top: 10px;
        left: 0px;
      }

    }

  }

}


// Trigger for the off-canvas mobile menu
.site-nav__target {
  position: fixed;
  top: 0;
  left: 0;

  &:target+.site-nav__list {
    @include transform(none);
  }

}

// The list of menu items
.site-nav__list {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  @include clearfix();
}

// List item in the nav
.site-nav__item {

  @include mq($from: tablet) {
    position: relative;
    // float: left;
    text-align: center;
  }

}

// Link item in the nav
.site-nav__link {
  position: relative;
  display: block;
  font-weight: $site-nav-font-weight;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:active:focus {
    outline: none;
  }

  &.has-sub-menu {
    position: relative;

    &:before {
      display: none;

      @include mq($from: tablet) {
        display: block;
        @include position-y-center-right();
        width:  $site-nav-ddlb-arrow-size;
        height: $site-nav-ddlb-arrow-size;
        @include svg-bg-img($svg-ddlb-arrow, $global-icon-size, $global-icon-size, $site-nav-ddlb-arrow-color);
        transition: background-image 250ms ease-out;
      }

    }

    &:hover:before {
      @include mq($from: tablet) {
        @include svg-bg-img($svg-ddlb-arrow, $global-icon-size, $global-icon-size, $site-nav-ddlb-arrow-active-color);
      }
    }

  }

  &.is-icon-only {

    .c-svg {
      width:  $site-nav-icon-size;
      height: $site-nav-icon-size;
    }

  }

}

// Close mobile menu list
.site-nav__item--close {
  text-align: right;
}

.site-nav__close {
  display: inline-block;
  line-height: 0;

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  .c-svg {
    top: 0;
    width:  $m-site-nav-close-btn-size;
    height: $m-site-nav-close-btn-size;
  }

}

.site-nav__close,
.site-nav__item--close {
  @include mq($from: tablet) {
    display: none;
  }
}


// Menu dropdown
.site-nav__sub-menu {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  // padding-left: $global-unit;

  @include mq($from: tablet) {
    position: absolute;
    top: 100%;
    left: -$global-unit-small;
    @include translateY(-10px);
    z-index: 1;
    width: auto;
    min-width: 100%;
    max-width: 140px;
    padding: $global-unit-tiny 0;
    text-align: left;
    // white-space: nowrap;
    background-color: $site-nav-sub-bgcolor;
    border-radius: $global-radius;
    border: 1px solid $site-nav-sub-border-color;
    box-shadow: 0 1px 16px -3px rgba(0,0,0,0.3);
    visibility: hidden;
    opacity: 0;
    transition: $global-transition;

    // .site-nav__item {
    //   // float: none;
    // }

    .site-nav__item:hover & {
      opacity: 1;
      visibility: visible;
      will-change: opacity;
      @include translateY($global-unit-tiny);
    }

  }

}



.site-nav__sub-link {
  display: block;
  padding: $global-unit-small;
  font-weight: $site-nav-font-weight;
  transition: $bgcolor-hover-trans;
  // @include word-wrap(none);

  &,
  &:link,
  &:visited {
    color: $m-site-nav-sub-link-color;
  }

  &:hover,
  &:active,
  &:focus {
    color: $site-nav-sub-hover-color;
    text-decoration: none;
    background-color: $site-nav-sub-hover-bgcolor;
  }

}





/* Nav type modifiers.
   ========================================================================== */

// Main site navigation
.site-nav--main {
  @include display-flex();
  align-items: $site-nav-y-alignment;
  line-height: 0;

  .site-nav__list {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    transform: translateX(100%);
    transition: 0.2s;
    will-change: transform;
    background-color: $m-site-nav-list-bgcolor;
    line-height: normal;
    z-index: 99999;

    @include mq($until: tablet) {
      overflow-y: scroll;
    }

    // Angular mobile nav trigger
    @at-root .show-mobile-nav#{&} {
      @include transform(none);
    }

    @include mq($from: tablet) {
      @include display-flex();
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      background-color: transparent;
      overflow: visible;
      backdrop-filter: none;
      @include transform(none);
      z-index: 10;
    }

  }

  .site-nav__item {

    @include mq($from: tablet) {
      margin-left: $site-nav-link-tablet-spacing;
      padding: 0;

      &.site-nav--first-link {
        margin-left: 0;
      }

      &.has-sub-menu {

        @include hocus() {

          > .site-nav__link {
            color: $site-nav-hover-color;

            &:before {
              @include svg-bg-img($svg-ddlb-arrow, $global-icon-size, $global-icon-size, $site-nav-ddlb-arrow-active-color);
            }

          }

        }

      }

    }

    @include mq($from: desktop) {
      margin-left: $site-nav-link-desktop-spacing;
    }

    &.site-nav__item--mobile-username {

      .site-nav__link {
        @include font-size($m-mobile-username-font-size);
        color: $m-mobile-username-text-color;
        font-weight: $m-mobile-username-font-weight;
      }

      @include mq($from: tablet) {
          display: none;
      }

    }

  }

  .site-nav__link {
    padding: $m-site-nav-link-padding;

    @include all-anchor-states() {
      color: $m-site-nav-link-color;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $m-site-nav-hover-bgcolor;

      &.site-nav__close {
        background-color: transparent;
      }

    }

    &.is-mobile-username {
      @include font-size($m-mobile-username-font-size);
      color: $m-mobile-username-text-color;
      font-weight: $m-mobile-username-font-weight;
    }

    @include mq($until: tablet) {
      &:not(.site-nav__close) {
        text-align: center;
      }

      &.has-menu-icon {
        display: none;
      }

    }

    @include mq($from: tablet) {
      display: inline-block;
      height: inherit;
      padding: $site-nav-link-padding;
      background-color: transparent;
      vertical-align: middle;
      transition: $bgcolor-hover-trans;
      @include font-size($site-nav-font-size);

      &,
      &:link,
      &:visited {
        color: $site-nav-link-color;
      }

      &:hover,
      &:active,
      &:focus {
        color: $site-nav-hover-color;
        background-color: $site-nav-hover-bgcolor;
      }

      span {
        line-height: $site-nav-link-height;
      }

      &.has-menu-icon {
        padding-left: 34px;
      }

      &.has-sub-menu {
        padding-right: $global-unit;
      }

    }

  }

  // Menu dropdown
  .site-nav__sub-menu {

    @include mq($from: tablet) {
      // left: auto;
      // right: -6px;
      // width: 100%;
      min-width: $site-nav-sub-min-width;
      max-width: $site-nav-sub-max-width;
    }

    &.is-last {
      @include mq($from: tablet) {
        left: auto;
        right: 0;
      }
    }

    .site-nav__item {
      text-align: center;

      @include mq($from: tablet) {
        margin-left: 0;
        // float: none;
        text-align: left;
      }

    }

  }

  .site-nav__sub-link {

    span {
      line-height: normal;
    }

  }

  // @supports (backdrop-filter: blur(5px)) {

  //   .site-nav__list {

  //     @include mq($until: tablet) {
  //       background-color: rgba($site-nav-list-m-bgcolor, 0.8);
  //       backdrop-filter: blur(10px);
  //     }

  //   }
  // }


  // Meta menu - user profile
  // At tablet and above screens, profile menu is at the far right
  // of the header, so we need a separate nav__list to position it outside
  // of the rest of the main menu.
  .site-nav--metamenu {
    display: none;
    visibility: hidden;

    @include mq($from: tablet) {
      @include display-flex();
      visibility: visible;
      flex-grow: 0;
      @include clearfix();

      .site-nav__link {

        &:not(.has-menu-icon) {
          padding-left: 0;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
        }

        span {
          padding: 0;
        }

        &.is-icon-only {
          padding: 0;
        }

      }

    }

  }

  @if ($site-nav-position == 'left') {
    .site-nav__home-link {
      @include mq($from: tablet) {
        flex-grow: 0;
      }
    }

    .site-nav__list:not(.site-nav--metamenu) {
      @include mq($from: tablet) {
        flex-grow: 1;
      }
    }

  }

  @if ($site-nav-y-alignment == 'flex-end') {
    margin-bottom: $global-unit;

    @include mq($from: tablet) {
      margin-bottom: $global-unit-small;
    }
  }

} // end site-nav--main




// Tab style page navigation
.site-nav--tabs {
  margin-bottom: $nav-tabs-margin-bottom;
  // border-bottom: $nav-tabs-border-height solid $global-border-color;

  .site-nav__item {
    float: left;
    border-top: 1px solid $global-border-color;
    transition: background-color 250ms ease-out;

    @include mq($from: tablet) {
      border-top: none;
      transition: none;
    }


    &:first-child .site-nav__link {
      @include mq($from: tablet) {
        padding-left: 0;

        &:before {
          left: 0;
          @include calc(width, 100%, '-', $global-unit, 100%);
        }

        @if ($nav-tabs-pipe-dividers == true) {
          &:after {
            border-left: none;
          }
        }

      }

    }

  }

  .site-nav__link {
    padding: $global-unit-small;
    text-align: center;
    color: $nav-tabs-link-color;

    @include mq($from: tablet) {
      vertical-align: top;
      padding: 0 $global-unit;
      line-height: $nav-tabs-link-line-height;

      // Tab underline indicator
      &:before {
        position: absolute;
        top: 100%;
        @include calc(left, 0px, '+', $global-unit, 0);
        @include calc(width, 100%, '-', ($global-unit * 2), 100%);
        content: "";
        border-bottom-width: $nav-tabs-border-height;
        border-bottom-style: solid;
        border-bottom-color: transparent;
        line-height: 0;
        transition: border-bottom-color 250ms ease-out;
      }

      // Show vertical dividers between tabs if `true`
      @if ($nav-tabs-pipe-dividers == true) {
        &:after {
          position: absolute;
          top: 50%;
          left: 0;
          height: 50%;
          margin-top: -1em;
          content: "";
          border-left: 2px solid $global-border-color;
        }
      }

      &:hover {

        &:not(.is-active) {
          background-color: $neutral-ulight;

          @include mq($from: tablet) {
            background-color: transparent;
          }

        }

        @include mq($from: tablet) {

          &:before {
            border-bottom-color: $nav-tabs-border-color-hover;
          }

        }

      }

    }

  }

}



// Stacked navigation - like a sidebar nav
.site-nav--stacked {

  // @include mq($from: tablet) {
  //   margin-bottom: $global-unit-large;
  // }

  .site-nav__list {
    margin-bottom: $global-unit-large;

    &:last-of-type {
      margin-bottom: 0;
    }

  }

  .site-nav__item {
    width: 100%;

    @if ($stacked-nav-item-bottom-border == true) {
      border-bottom: 1px solid $global-border-color;

      &:first-child {
        border-top: 1px solid $global-border-color;
      }

    }

    @include mq($from: tablet) {
      text-align: left;
    }

  }

  .site-nav__link {
    color: $site-nav-link-color;
    text-align: center;
    padding: $global-unit-tiny $global-unit-small;
    transition: color 250ms ease-out;

    &:hover:not(.is-active) {
      color: $site-nav-hover-color;
    }

    @include mq($from: tablet) {
      display: inline-block;
      text-align: left;
      padding-right: $global-unit-large;
    }

    .c-svg {
      display: none;
    }

  }

}


.site-nav--branding {
  line-height: 0;
  text-align: center;

  .site-nav__logo.logo-myair {
    margin-right: 0
  }
}

// .site-nav--sticky {
//   margin-bottom: 0 !important;

//   @include mq($from: tablet) {
//     position: sticky;
//     top: 150px;
//     width: 100%;
//     height: calc(100vh - 236px);
//     margin-top: 0;
//     overflow: auto;
//     overflow-y: scroll;
//     z-index: 1000;
//   }

// }




/* Current section indicator.
   ========================================================================== */
/**
 * Add `.is-active` class dynamically to current `site-nav__link` or use
 * page/section classes for specificity.
 * e.g.: html.page--about .site-nav__link.site-nav__about {}
 */
 .is-active {

  .site-nav--main  & {

    .site-nav__link {

      @include all-anchor-states() {
        color: $m-site-nav-link-color;
      }
      background-color: $m-site-nav-hover-bgcolor;

      @include mq($from: tablet) {
        @include all-anchor-states() {
          color: $site-nav-active-color;
        }
        background-color: $site-nav-active-bgcolor;
      }

    }

    .site-nav__sub-link {
      background-color: $m-site-nav-hover-bgcolor;

      @include mq($from: tablet) {
        @include all-anchor-states() {
          color: $site-nav-sub-active-color;
        }
        background-color: $site-nav-sub-active-bgcolor;
      }

    }

  }


  .site-nav--tabs & {

    .site-nav__link {
      @include all-anchor-states() {
        color: $nav-tabs-link-color-active;
      }

      @include mq($from: tablet) {
        &:before {
          border-bottom-color: $nav-tabs-border-color-active;
        }
      }

    }

  }


  .site-nav--stacked & {

    .site-nav__link {
      @include all-anchor-states() {
        color: $stacked-nav-active-color;
      }
      background-color: $m-stacked-nav-active-bgcolor;

      @include mq($from: tablet) {
        background-color: transparent;

        .c-svg {
          display: block;
          @include position-y-center-right();
        }

      }

    }

  }

}


// ResMEd corporate nav at top of home page
.site-nav--rmd {
  margin: 0;

  .site-nav__list {

    @include mq($from: tablet) {
      float: right;
    }

  }

  .site-nav__item {

    @include mq($from: tablet) {
      margin-left: $global-unit-tiny;
      padding: 0;
      border-bottom: 0;
    }

  }

  .site-nav__link {
    padding: $global-unit-small;
    text-align: center;
    color: $neutral-ulight;
    @include font-size(14px, 36px);
    font-weight: $font-weight-medium;

    @include mq($from: tablet) {
      vertical-align: top;
      padding: 0 $global-unit-small;

      &:before {
        position: absolute;
        bottom: 0;
        @include calc(left, 0px, '+', $global-unit-small, 0);
        @include calc(width, 100%, '-', ($global-unit-small * 2), 100%);
        content: "";
        border-bottom-width: $nav-tabs_border-height;
        border-bottom-style: solid;
        border-bottom-color: transparent;
        line-height: 0;
        transition: border-bottom-color 250ms ease-out;
      }

      &:hover {
        &:not(.is-active) {
          background-color: $neutral-ulight;

          @include mq($from: tablet) {
            background-color: transparent;
          }

        }

        @include mq($from: tablet) {

          &:before {
            border-bottom-color: $color-grey50;
          }

        }

      }

    }

  }

}
