/* ==========================================================================
   #LOADERS
   ========================================================================== */

/**
 * CSS loading animations.
 * Taken from Luke Haas (https://projects.lukehaas.me/css-loaders/). See the site for other
 * loaders and to configure foreground and background color options.
 *
    <div class="c-loader c-loader--page"></div>
 *
 */
.c-loader {
  position: relative;
  display: inline-block;
  margin: 0;
  @include hide-text();
  @include translateZ(0);
}


.mpp-spinner-backdrop {
  width: 100%;
  height: 100%;
  background-color: $color-white;
  opacity: 0.85 !important;
}




/* Loader variants
   ========================================================================== */
// 8 dots in a circle pattern, from low to full opacity, giving the appearance
// of a spinning gradient
.c-loader--dots {
  @include font-size(10px);
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  @include hide-text();
  @include animation(loadDots 1.1s infinite ease);
}

// Page loading - rotating grey border circle with 1/4 radius blue arc, giving the
// appearance of the blue arc spinning around the grey circle.
.c-loader--page {
  @include position-center();
  width:  48px;
  height: 48px;
  @include font-size(12px);

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    border-top:     0.25em solid rgba(128,128,128, 0.2);
    border-right:   0.25em solid rgba(128,128,128, 0.2);
    border-bottom:  0.25em solid rgba(128,128,128, 0.2);
    border-left:    0.25em solid $primary-blue;
    border-radius:  50%;
    animation:      loadPage 1.1s infinite linear;
  }
}





/* Spinner animations
   ========================================================================== */
// Spinning dots
@include keyframes(loadDots) {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #c0c0c0, 1.8em -1.8em 0 0em rgba(192,192,192, 0.2), 2.5em 0em 0 0em rgba(192,192,192, 0.2), 1.75em 1.75em 0 0em rgba(192,192,192, 0.2), 0em 2.5em 0 0em rgba(192,192,192, 0.2), -1.8em 1.8em 0 0em rgba(192,192,192, 0.2), -2.6em 0em 0 0em rgba(192,192,192, 0.5), -1.8em -1.8em 0 0em rgba(192,192,192, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(192,192,192, 0.7), 1.8em -1.8em 0 0em #c0c0c0, 2.5em 0em 0 0em rgba(192,192,192, 0.2), 1.75em 1.75em 0 0em rgba(192,192,192, 0.2), 0em 2.5em 0 0em rgba(192,192,192, 0.2), -1.8em 1.8em 0 0em rgba(192,192,192, 0.2), -2.6em 0em 0 0em rgba(192,192,192, 0.2), -1.8em -1.8em 0 0em rgba(192,192,192, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(192,192,192, 0.5), 1.8em -1.8em 0 0em rgba(192,192,192, 0.7), 2.5em 0em 0 0em #c0c0c0, 1.75em 1.75em 0 0em rgba(192,192,192, 0.2), 0em 2.5em 0 0em rgba(192,192,192, 0.2), -1.8em 1.8em 0 0em rgba(192,192,192, 0.2), -2.6em 0em 0 0em rgba(192,192,192, 0.2), -1.8em -1.8em 0 0em rgba(192,192,192, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(192,192,192, 0.2), 1.8em -1.8em 0 0em rgba(192,192,192, 0.5), 2.5em 0em 0 0em rgba(192,192,192, 0.7), 1.75em 1.75em 0 0em #c0c0c0, 0em 2.5em 0 0em rgba(192,192,192, 0.2), -1.8em 1.8em 0 0em rgba(192,192,192, 0.2), -2.6em 0em 0 0em rgba(192,192,192, 0.2), -1.8em -1.8em 0 0em rgba(192,192,192, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(192,192,192, 0.2), 1.8em -1.8em 0 0em rgba(192,192,192, 0.2), 2.5em 0em 0 0em rgba(192,192,192, 0.5), 1.75em 1.75em 0 0em rgba(192,192,192, 0.7), 0em 2.5em 0 0em #c0c0c0, -1.8em 1.8em 0 0em rgba(192,192,192, 0.2), -2.6em 0em 0 0em rgba(192,192,192, 0.2), -1.8em -1.8em 0 0em rgba(192,192,192, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(192,192,192, 0.2), 1.8em -1.8em 0 0em rgba(192,192,192, 0.2), 2.5em 0em 0 0em rgba(192,192,192, 0.2), 1.75em 1.75em 0 0em rgba(192,192,192, 0.5), 0em 2.5em 0 0em rgba(192,192,192, 0.7), -1.8em 1.8em 0 0em #c0c0c0, -2.6em 0em 0 0em rgba(192,192,192, 0.2), -1.8em -1.8em 0 0em rgba(192,192,192, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(192,192,192, 0.2), 1.8em -1.8em 0 0em rgba(192,192,192, 0.2), 2.5em 0em 0 0em rgba(192,192,192, 0.2), 1.75em 1.75em 0 0em rgba(192,192,192, 0.2), 0em 2.5em 0 0em rgba(192,192,192, 0.5), -1.8em 1.8em 0 0em rgba(192,192,192, 0.7), -2.6em 0em 0 0em #c0c0c0, -1.8em -1.8em 0 0em rgba(192,192,192, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(192,192,192, 0.2), 1.8em -1.8em 0 0em rgba(192,192,192, 0.2), 2.5em 0em 0 0em rgba(192,192,192, 0.2), 1.75em 1.75em 0 0em rgba(192,192,192, 0.2), 0em 2.5em 0 0em rgba(192,192,192, 0.2), -1.8em 1.8em 0 0em rgba(192,192,192, 0.5), -2.6em 0em 0 0em rgba(192,192,192, 0.7), -1.8em -1.8em 0 0em #c0c0c0;
  }
}


// Arc going around ring
@include keyframes(loadPage) {
  0% {
    @include rotate(0);
  }
  100% {
    @include rotate(360);
  }
}
