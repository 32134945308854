/* ==========================================================================
   #HISTORY
   ========================================================================== */
/**
 * Components found on the sleep history page.
 */

// Month navigation control
// Navigate back and forth between monthly charts. Controls the trends carousel.
// Uses `.components.directional-nav` for the navigation.
.c-history-nav {
  position: relative;
  width: 100%;
  margin-top: 0;
  padding: 0;
  text-align: center;

  @include mq($from: tablet) {
    @include animation(aniStayHidden 1s, aniFadeInDown 250ms 1s 1 ease-out);
  }

}


.c-history-nav__month {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  padding: 0 $global-unit-small;
  width: auto;
  min-width: 222px;
  text-align: center;
}

.c-history-nav__month-name {
  @include font-size(24px, 46px);
  font-weight: $font-weight-medium;
}
