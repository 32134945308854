﻿/* ==========================================================================
   #APP PROMO
   ========================================================================== */
// Promotional banner

.c-promo {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  @include clearfix();

  .o-wrapper {
    position: relative;
  }
}



.c-promo__img {
  position: absolute;
  left: $global-unit;
  bottom: 0;
  width: 68px;
  height: 78px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;

  @media only screen and (min-width: 960px), screen and (-webkit-min-device-pixel-ratio: 1) {
    background-image: url(/assets/images/iPhoneApp@1x-min.png);
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(/assets/images/iPhoneApp@2x-min.png);
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 3) {
    background-image: url(/assets/images/iPhoneApp@3x-min.png);
  }

  @include mq($from: x-wide) {
    left: 0;
  }

}



.c-promo__body {
  display: block;
  width: 100%;

  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 86px;
  @include clearfix();

  @include mq($from: tablet) {
    padding-top: $global-unit-small;
    padding-right: 300px;
    padding-bottom: $global-unit-small;
    padding-left: 80px;
  }

}

.c-promo__heading {
  margin-bottom: $global-unit-small;
  @include font-size(12px, 1.4);

  @include mq($from: tablet) {
    @include font-size(16px);
  }

  @include mq($from: desktop) {
    @include font-size(18px);
  }

}

.c-promo__disclaimer {
  color: $color-grey80;
  margin: 0;
  @include font-size(10px, 1.25);
}




// Promo banner for myAir app
.c-promo--app {
  margin-bottom: 60px;
  box-shadow: 0 -8px 10px -5px rgba($color-black, 0.2) inset;
  background-color: shade($color-white, 4%);

  @include mq($from: tablet) {
    margin-bottom: 0;
  }

}



.c-promo--app_download {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  background-color: shade($color-white, 2%);

  @include mq($from: tablet) {
    //@include calc(top, 50%, "-", 20px, 12px);
    top: 50%;
    left: auto;
    right: $global-unit;
    width: auto;
    padding: 0;
    background-color: transparent;
    @include translateY(-50%);
  }

  @include mq($from: x-wide) {
    right: 0px;
  }


  .c-apple-badge {
    margin: 0 auto;

    @include mq($from: tablet) {
      margin: 0;
    }

  }

  > .o-layout__item {
    line-height: 0;

    @include mq($until: tablet) {
      width: 50%;
      padding: 0 $global-unit-small;

      &:first-child {
        text-align: right;
      }

    }

  }

}
