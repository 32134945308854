/* ==========================================================================
  #INLINE GROUP
  ========================================================================== */
/**
 * Group multiple singular elements inline such as tags or anchors.
 * Place a series of the same element inside a inline-group wrapper to display them
 * inline together. Simpler than trying to use the `o-layout` object for
 * something like this.
 */

// Auto-generates class names from $global-spacing-sizes
// e.g.: .o-inline-group, .o-inline-group-tiny, .o-inline-group-small and so on.
.o-inline-group {

  @each $size, $value in $global-spacing-sizes {

    &#{$size} {
      display: block;
      padding: 0;
      margin: 0;
      margin-left: -$value;

      @if ($use-markup-fix == false) {
        font-size: 0;
      }

      > * {

        // @include mq($until: tablet) {
        //   display: block !important;
        // }

        display: inline-block !important;
        // margin-left:   $value !important;
        padding-left:   $value !important;
        margin-bottom: $global-unit !important;
        vertical-align: middle;

        @if ($use-markup-fix == false) {
          @include font-size($global-font-size);
        }

      }

    }

  }

}



/* Vertical alignment modifiers
   ========================================================================== */

/**
 * Align all grid items to the middles of each other.
 */

.o-inline-group--top {
  > * {
    vertical-align: top;
  }
}


/**
* Align all grid items to the bottoms of each other.
*/
.o-inline-group--bottom {

  > * {
    vertical-align: bottom;
  }

}



/* Variants
   ========================================================================== */
.o-inline-group--block-at-mobile {

  @include mq($until: tablet) {
    > * {
      display: block !important;
    }
  }

}

.o-inline-group--no-margin {
  > * {
    margin-bottom: 0 !important;
  }
}
