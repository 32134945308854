/* ==========================================================================
   #HEADINGS
   ========================================================================== */

$c-heading-page-color:      $html-text-color !default;
$c-heading-main-color:      $html-text-color !default;
$c-heading-sub-color:       $html-text-color !default;
$c-heading-section-color:   $color-grey90 !default;


/**
 * Define some heading styles to modify `h1` through `h6` elements
 *
 * Related files:
 * settings/settings.typography.scss
 * elements/elements.typography.scss
 */

/* Headings in the resx with no class names */
.c-heading-resx {

  h1, h2, h3 {
    color: $c-heading-page-color;
  }

  &.otp-header {
    margin-bottom: $global-unit;

    h1, h2, h3 {
        margin-bottom: 0;
    }

  }

}

.c-heading-congrats {

  h2 {
    //margin-bottom: 0;
    line-height: 1.25;
  }

}



//
.c-heading-page {
  color: $c-heading-page-color;
  font-weight: $font-weight-bold;
}


.c-heading-main {
  color: $c-heading-main-color;
  font-weight: $font-weight-bold;
  line-height: 1.15;


}



.c-heading-acc {
  margin-bottom: 0;
  line-height: 1;
  color: $c-heading-main-color;
  font-weight: $font-weight-medium;
  @include font-size(24px);
}



.js-content h2,
.c-heading-sub {
  //margin-bottom: $global-unit-tiny;
  color: $c-heading-sub-color;
  line-height: 1.25;
}

.c-heading-section {
  color: $c-heading-section-color;
  font-weight: $font-weight-medium;
  @include font-size(24px);

  .o-section--tips & {
    margin-bottom: 0;
    text-align: center;

    @include mq($from: tablet) {
      text-align: left;
    }

  }

}



.c-heading-parent {
  margin-bottom: $global-unit-tiny;
  text-transform: uppercase;
  @include font-size(12px);
}


.c-heading-blue {
  color: $primary-blue;
}


.c-heading-icon {
  position: relative;
  margin: 0;
  text-align: center;
  color: $c-heading-page-color;

  .c-svg {
    display: block;
    margin: 0 auto;
    @include font-size(100px);
    color: $color-black;
  }

}


.c-heading-headline {
  @include font-size(36px);
  font-weight: $font-weight-medium;

  @include mq($from: tablet) {
    @include font-size(50px);
  }

}


// Region headings on country selection page
// matches `c-expander--countries` label item styles
.c-heading-region {
  margin-bottom: 0;
  padding: 16px 42px;
  color: $html-text-color;
  @include font-size(22px);
  font-weight: $font-weight-bold;

  @include mq($from: tablet) {
    margin-bottom: $global-unit;
    padding: 0;
    padding-bottom: $global-unit-tiny;
    border-bottom: 1px dotted shade($color-grey5, 3%);
  }


  @include mq($from: wide) {

    html:lang(fi) &,
    html:lang(nb) &,
    html:lang(sv) & {
        @include font-size(18px);
    }
  }

}

.c-heading-location {
  text-align: center;
  @include mq($until: tablet) {
    padding: 0 24px;
    @include font-size(24px);
  }
}



/**
 * Text and label modifiers
 */
.c-text--intro {
  @include font-size(20px, 1.75);

  @include mq($from: desktop) {
    @include font-size(20px, 2.25);
  }

}

.c-text--headline-copy {
  @include font-size(20px);
  color: $neutral-heavy;
  font-weight: $font-weight-medium;
}


.c-text--greyed-out {
  font-style: italic;
  color: $neutral-medium;
}


.c-text--small {
  @include font-size(12px, 2);
}

.c-text--optional {
  @include font-size(14px, inherit);
  color: $neutral-heavy;
}

.c-instructions {
  margin: 0;
  margin-bottom: $global-unit-large;
  @include font-size(18px);
  font-weight: $font-weight-normal;
  text-align: center;
}



// Activation page
.c-heading-sleep-steps {
  line-height: normal;
  text-align: center;
  margin-bottom: $global-unit-large;


  @include mq($from: desktop) {
    margin-bottom: $global-unit-huge;
  }

}

.c-content--sleep-steps {
  margin-bottom: $global-unit-large;

  @include mq($from: tablet) {
    margin: 0;
    padding: 0 $global-unit-small;
  }

  @include mq($from: desktop) {
    padding: 0 $global-unit-large;
  }

}


.c-text--sleep-steps {
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: shade($color-grey10, 5%);

  @include mq($from: tablet) {
    //margin-top: $global-unit-large;
    padding-top: $global-unit-large;
    border-width: 0;
    border-top-width: 1px;
  }

}



// Tips
.c-text--screen-tip {
  position: relative;
  margin-top: $global-unit-small;
  padding-left: $global-unit;
  @include font-size(14px, 1.45);

  .c-text--screen-tip-num {
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    @include font-size(12px, 20px);
    color: $color-white;
    text-align: center;
    text-indent: -1px;
    background-color: shade($color-grey70, 5%);
    border-radius: 50%;
  }

}

/* Links
  ========================================================================== */
// A text link in the UI that should not have a visited state
.auth-footer .link,
.resend-email-btn,
.c-link-ui {
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-weight: $font-weight-medium;
  background: none;
  @include appearance(none);

  &,
  &:link,
  &:visited {
    color: $a-link-color !important;
  }

  &:hover {
    color: $a-hover-color;
    text-decoration: underline;
    cursor: pointer;
  }

  &:active {
    color: $a-active-color;
  }

  &.is-delete {
    &,
    &:link,
    &:visited {
      color: $color-ui-error !important;
    }

    &:hover {
      color: shade($color-ui-error, 6%);
    }

    &:active {
      color: shade($color-ui-error, 12%);
    }
  }

  &:disabled,
  &.is-disabled {
    transition: none;

    @include all-anchor-states() {
      cursor: default;
      background-image: none;
      border: 0;
      opacity: 0.3;
      text-decoration: none;
    }
  }
}
