/* ==========================================================================
   #LISTS
   ========================================================================== */
// List styles


.c-list,
.c-list__sub-list,
.c-list__item {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.c-list__item-heading {
  display: block;
  @include font-size(16px);
  font-weight: $font-weight-medium;
  padding: 20px $global-unit;
}


.c-list--categories {
  margin: $global-unit-large 0;

  .c-list__item {
    color: $primary-blue;

    .c-list__item-heading {
      background-color: $neutral-ulight;
      transition: $bgcolor-hover-trans;

      &:hover {
        text-decoration: none;
        background-color: shade($secondary-blue, 25%);
      }

    }

    .c-list__sub-list {
      margin-bottom: $global-unit-large;

      .c-list__item {
        padding: 20px $global-unit 20px $global-unit-large;
        border-bottom: 1px solid $global-border-color;
      }

    }

  }

}



.c-list__link {
    @include a_block();
}






/* List type modifiers.
========================================================================== */
// Ordered list with numbers in a circle border
.c-list--round-nums {
  counter-reset: li; /* Initiate a counter */
  list-style: none; /* Remove default numbering */
  *list-style: decimal; /* Keep using default numbering for IE6/7 */

  li {
    position: relative;
    padding-left: 1.75em;

    &:before {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      left: 0;
      top: 0.025em;
      background: $color-white;
      width:  1.25em;
      height: 1.25em;
      @include font-size(16px, 1.1em);
      border: 1px solid $html-text-color;
      text-align: center;
      border-radius: 2em;
    }

  }

}

.c-list--countries {

    .c-list__item {

        @include mq($from: tablet) {
            background-color: rgba($color-white, 0.85);
        }

    }

    .c-list__link {
        color: $html-text-color;
        @include font-size(21px);

        span {
            position: relative;
            display: block;
            padding: 8px 42px 8px 80px;

            @include mq($from: tablet) {
              @include font-size(16px);
              padding: 8px 0px 8px 30px;
            }

            &:before {
                top: 50%;
                left: 42px;
                @include translate(0, -50%);
                @include transformsition(250ms ease-out);

                @include mq($from: tablet) {
                    left: 0;
                }
            }
        }

        &:hover {
            text-decoration: none;

            span:before {
                @include translate(3px, -50%);
            }

        }

    }

}




.c-list--small {

    .c-list__item {
        margin-bottom: $global-unit-small;
    }

}



/*

    &.comorbityList {
        list-style-type:none;
        margin:10px;
        padding:0;
        li {
                list-style-type:none;
                margin:0 0 12px;
                padding:0;
                line-height:1.2;
        }
    }

*/


// Form input checklist
.list_input-validation {
  list-style-type: none;

  > li {
    position: relative;
    text-indent: 0px;
    line-height: 1.15;
    margin-bottom: $global-unit-tiny;

    &::before {
      @include position-y-center();
      left: -15px;
      width:  16px;
      height: 16px;
      content: "\2022";
      background-repeat: no-repeat;
      background-size: cover;
      line-height: 16px;
    }

    &.completed {
      color: $color-ui-success;

      &::before {
        @include svg-bg-img($svg-field-success, $global-icon-size, $global-icon-size, $color-ui-success);
        left: -20px;
      }

    }

  }
}



