﻿/* ==========================================================================
   #ACC-PANEL
   ========================================================================== */

.c-acc-panel_col {

  @include mq($from: desktop) {
    width: 680px;
  }

}


.c-acc-panel {
  display: block;
  margin-bottom: $global-unit;
  margin-left:  -$global-unit;
  margin-right: -$global-unit;
  transition: opacity 350ms ease, visibility 350ms ease;

  @include mq($from: tablet) {
    margin-left: 0;
    margin-right: 0;
  }


  .is-active & {
    //display:block;
    position: relative;
    visibility: visible;
    opacity: 1;
  }

  .is-hidden & {
    //display:none;
    position: absolute;
    visibility: hidden;
    margin: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    opacity: 0;
  }

}


.c-acc-panel__item {
  display: block;
  @include clearfix();

  @if ($use-markup-fix == false) {
    font-size: 0;
  }

  .item-has-top-border &,
  &:not(:first-of-type) {
    border-top: 1px solid $color-white;
  }

}


.c-acc-panel__btn,
.c-acc-panel__img,
.c-acc-panel__body,
.c-acc-panel__links,
.c-acc-panel__label,
.c-acc-panel__value,
.c-acc-panel__heading {
  display: inline-block;
  width: 100%;
  padding-top:    $global-unit-micro;
  padding-bottom: $global-unit-micro;
  padding-left:   $global-unit-small;
  padding-right:  $global-unit-small;
  vertical-align: top;
  box-sizing: border-box;

  @if ($use-markup-fix == false) {
    font-size: $global-font-size;
    font-size: 1rem;
  }

}

.c-acc-panel__img,
.c-acc-panel__body {

  @include mq($from: tablet) {
    display: table-cell;
    padding-left:  $global-unit-tiny;
    padding-right: $global-unit-tiny;
  }

}

.c-acc-panel__img {
  vertical-align: middle;
}

.c-acc-panel__heading {
  width: 66.66667%;
  padding: 0;
  padding-left: $global-unit-small;
  flex-grow: 1;

  @include mq($from: tablet) {
    padding-left: 0;
  }


  .no-btn & {
    width: 100%;
  }

  &.has-wide-btn {
    width: 100%;
    margin-bottom: $global-unit;

    @include mq($from: $bp-568) {
      width: 50%;
      margin-bottom: 0;
    }

  }

}


.c-acc-panel__img {
  width: 100px;
  // height: 100px;
  padding:  $global-unit-tiny;
  background-color: $color-white;

  > img {
    max-width: 88px;
  }


  @include mq($from: tablet) {
    width: 160px;
    height: 160px;

    > img {
      max-width: 148px;
    }

  }

}


.c-acc-panel__btn {
  width: 33.333335%;
  text-align: right;
  padding-left: 0;
  // float: right;

  .c-acc-panel--header & {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.no-label {
    width: 100%;

    .c-btn--text {
      height: auto;
      line-height: 1.25;

      &:after {
        bottom: -3px;
      }
    }
  }

  &.wide-btn {
    width: 100%;
    // float: none;

    @include mq($from: $bp-568) {
      width: 50%;
    }

  }

  .c-btn--text {
    display: inline-block;
    margin-bottom: 0;
  }

}



// SMS notification links
.c-acc-panel__links {
  width: 100%;
  text-align: left;

  @include mq($from: tablet) {
    width: 30%;
    text-align: right;
  }

}


.c-acc-panel__label {
  width: 100%;
  font-weight: $font-weight-medium;

  @include mq($until: tablet) {
    padding-bottom: 0;
  }

  @include mq($from: tablet) {
    width: 200px;
    padding-right: 0;
    flex-shrink: 0;

    .label--wide & {
      width: 500px;
    }

    &.no-value {
      width: 578px;
    }

  }

  @include mq($from: desktop) {

    .label--wide & {
      width: 500px;
    }

  }

}


.c-acc-panel__value {
  width: auto;
  flex-grow: 1;
  &.has-links,
  &.full-width {
    width: 100%;
  }

  &.full-width {
    padding: $global-unit-small;
  }


  &,
  &.has-links {
    padding-left: $global-unit;

    @include mq($from: tablet) {
      width: 380px;
      max-width: 380px;
    }

  }

  &.no-btn {
    width: 100%;

    @include mq($from: tablet) {
      width: 480px;
      max-width: 480px;
    }

    > span {

      width: 100%;

      @include mq($from: tablet) {
        width: 438px;
        max-width: 438px;
      }

    }

  }

  .label--wide & {
    @include mq($from: tablet) {
      width: 220px;
      padding-left: $global-unit;
    }

    @include mq($from: desktop) {
      width: 180px;
    }

    &.no-btn {

      @include mq($from: tablet) {
        width: 220px;
        max-width: 220px;
      }

      > span {

        @include mq($from: tablet) {
          width: 196px;
          max-width: 196px;
        }

      }

    }

  }


  > span {
    display: block;
    width: 176px;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mq($from: $bp-414) {
      width: 266px;
    }

    @include mq($from: tablet) {
      width: 440px;
    }

    &,
    .has-links & {

      @include mq($from: tablet) {
        width: 336px;
        max-width: 336px;
      }

    }

    &.is-email {
      width: 245px;

      @include mq($from: tablet) {
        width: 440px;
      }
    }

  }

}



.c-acc-panel__body {
  width: calc(100% - 100px);
  padding: 0;
  border: 2px solid $global-border-color;
  border-left-width: 0;
  vertical-align: top;


  > .c-acc-panel__item {
    display: table;
    width: 100%;

    &:first-child {
      border: none;
    }

  }

}



/* Layout variants
    ========================================================================== */
.c-acc-panel--group {
  margin-bottom: $global-unit;

  .c-acc-panel {
    margin-bottom: 0;
  }

}


.c-acc-panel--group__item {

  &.has-all-attr {

    .c-acc-panel__label {
      @include mq($until: tablet) {
        width: 100%;
      }

      // @include mq($from: tablet) {
      //   width: 35%;
      // }
    }

    .c-acc-panel__value {
      @include mq($until: tablet) {
        width: 75%;
      }
      // @include mq($from: tablet) {
      //   width: 40%;
      // }
    }

    .c-acc-panel__btn {
      @include mq($until: tablet) {
        width: 25%;
      }
    }

  }

}

.c-acc-panel--read,
.c-acc-panel--header {

  @include mq($from: tablet) {
    // display: table;
    display: block;
    width: 100%;
    border-collapse: collapse;
  }

  > .c-acc-panel__item {

      @include mq($from: tablet) {
        // display: table-row;
        @include display-flex();
        flex-direction: row;
      }

  }

}


.c-acc-panel--read {

  > .c-acc-panel__item {
    background-color: $global-border-color;
  }

}


.c-acc-panel--stacked {

  .c-acc-panel__item,
  .c-acc-panel__label,
  .c-acc-panel__value {

    &,
    > span {
      display: block;
      width: 100%;
      max-width: none;
      overflow: unset;
      text-overflow: unset;
    }

  }

  .c-acc-panel__item {
    background-color: $global-border-color;
  }

  .c-acc-panel__label {
    padding-bottom: 0;
  }

  .c-acc-panel__value {
    padding-top: 0;
  }

}


.c-acc-panel--edit {
  padding: $global-unit;
  border-bottom: 4px solid $global-border-color;
  background-color: $color-white;
}



.c-acc-panel--media {

  > .c-acc-panel__item {
    display: table;
    width: 100%;
    background-color: $color-white;
    border: 2px solid $global-border-color;

    .c-acc-panel__img {
      padding-top: $global-unit;

      @include mq($from: tablet) {
        padding-top: 0;
        border: 2px solid $global-border-color;
        border-right: 0;
      }

    }

    .c-acc-panel__label {

      @include mq($from: tablet) {
        width: 200px;
      }

    }

    .c-acc-panel__value {

      &,
      &.has-links {

        @include mq($from: tablet) {
          width: 316px;
          max-width: 316px;
        }

      }


      > span {

        &,
        .has-links & {

          @include mq($from: tablet) {
            width: 270px;
            max-width: 270px;
          }

        }

      }

    }

    .c-acc-panel__body {
      border: none;
      background-color: $global-border-color;
    }

  }

}
