/* ========================================================================
   #GLOBAL
   ======================================================================== */
// The global settings file contains any project-wide variables.


/**
 * Set the site's max-width. This setting applies to the `.o-wrapper` element
 * which should encapsulate any elements that need to be constrained to the
 * page width.
 */
$res-wrapper-width:     1440px !default;
$inuit-wrapper-width:   $res-wrapper-width; // ( inuitcss default: 1200px )
// $inuit-wrapper-padding: $inuit-global-spacing-unit; // (default:  $inuit-global-spacing-unit )



/* Global
 ========================================================================== */

// Standardize some UI treatments.
$global-disabled-text-color:  $neutral-medium !default;
$global-icon-size:            24px !default; // !!Change to 24px when icons get updated!! Size the SVG icons are designed at
$global-icon-size-lg:         32px !default;
$global-radius:               3px !default;
$global-transition:           calc(1/3) + s !default;
$global-border-color:         $neutral-xlight !default;
$global-box-shadow:           0px 2px 5px $neutral-xlight !default;
$global-box-shadow-hover:     0px 4px 15px $neutral-medium !default;

$opacity-hover-trans:         opacity 250ms ease-out !default;
$opacity-hover-amount:        0.8 !default;
$opacity-disabled:            0.3 !default;
$border-hover-trans:          border-color 250ms ease-out, color 250ms ease-out !default;
$bgcolor-hover-trans:         background-color 250ms ease-out, color 250ms ease-out !default;
$color-hover-trans:           color 250ms ease-out !default;

$button-min-width:            186px !default;
$button-pill-radius:          420px !default;
$input-field-height:          38px !default;

// SVG background images - use with svg-bg-img($svg, $width, $height, $color) mixin
$svg-ddlb-arrow:          "M21.192 7.050c0.39 0.39 0.39 1.024 0 1.414l-8.485 8.485c-0.39 0.39-1.024 0.39-1.414 0l-8.485-8.485c-0.39-0.39-0.39-1.024 0-1.414s1.024-0.39 1.414 0l7.778 7.778 7.778-7.778c0.39-0.39 1.024-0.39 1.414 0z" !default;
$svg-close-btn:           "M16.369 6.216l-4.37 4.37-4.37-4.37-1.414 1.414 4.37 4.37-4.37 4.37 1.414 1.414 4.37-4.37 4.37 4.37 1.414-1.414-4.37-4.37 4.37-4.37z" !default;
$svg-delete-item:         "M18.369 4.216l-6.37 6.37-6.37-6.37-1.414 1.414 6.37 6.37-6.37 6.37 1.414 1.414 6.37-6.37 6.37 6.37 1.414-1.414-6.37-6.37 6.37-6.37z" !default;

$svg-chevron-left:        "M16.95 21.192c-0.39 0.39-1.024 0.39-1.414 0l-8.485-8.485c-0.39-0.39-0.39-1.024 0-1.414l8.485-8.485c0.39-0.39 1.024-0.39 1.414 0s0.39 1.024 0 1.414l-7.778 7.778 7.778 7.778c0.39 0.39 0.39 1.024-0 1.414z" !default;
$svg-chevron-right:       "M7.050 2.808c0.39-0.39 1.024-0.39 1.414 0l8.485 8.485c0.39 0.39 0.39 1.024 0 1.414l-8.485 8.485c-0.39 0.39-1.024 0.39-1.414-0s-0.39-1.024 0-1.414l7.778-7.778-7.778-7.778c-0.39-0.39-0.39-1.024 0-1.414z" !default;
$svg-slick-dot:           "M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10z" !default;

$svg-field-error:         "M18.369 4.216l-6.37 6.37-6.37-6.37-1.414 1.414 6.37 6.37-6.37 6.37 1.414 1.414 6.37-6.37 6.37 6.37 1.414-1.414-6.37-6.37 6.37-6.37z" !default;
$svg-field-success:       "M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4-10.6 10.6z" !default;
$svg-field-warning:       "M12 17c1.1 0 2 0.9 2 2s-0.9 2-2 2-2-0.9-2-2 0.9-2 2-2zM10 3h4v12h-4v-12z" !default;

$svg-alert-error:         "M14.828 7.757l-2.828 2.828-2.828-2.828-1.414 1.414 2.828 2.828-2.828 2.828 1.414 1.414 2.828-2.828 2.828 2.828 1.414-1.414-2.828-2.828 2.828-2.828-1.414-1.414zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" !default;
$svg-alert-warning:       "M11 15h2v2h-2zM11 7h2v6h-2zM11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.010-4.48 10.010-10s-4.48-10-10.010-10zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" !default;
$svg-alert-success:       "M16.59 7.58l-6.59 6.59-3.59-3.58-1.41 1.41 5 5 8-8zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" !default;

$svg-alert-info-fill:     "M12 1.992c-5.527 0-10.008 4.481-10.008 10.008s4.481 10.008 10.008 10.008 10.008-4.481 10.008-10.008-4.481-10.008-10.008-10.008zM11.023 7.43c0.247-0.229 0.542-0.343 0.885-0.343s0.637 0.114 0.882 0.343 0.368 0.503 0.368 0.825-0.124 0.596-0.371 0.822c-0.248 0.226-0.541 0.339-0.879 0.339-0.343 0-0.638-0.113-0.885-0.339s-0.372-0.5-0.372-0.822 0.124-0.597 0.372-0.825zM14.085 16.913h-4.012v-0.463c0.11-0.008 0.218-0.019 0.324-0.032s0.197-0.034 0.273-0.063c0.135-0.051 0.23-0.124 0.286-0.219s0.083-0.221 0.083-0.378v-3.694c0-0.148-0.034-0.278-0.102-0.391s-0.152-0.202-0.254-0.27c-0.076-0.051-0.191-0.1-0.346-0.146s-0.295-0.076-0.422-0.089v-0.463l3.11-0.165 0.095 0.095v5.040c0 0.148 0.032 0.274 0.095 0.377s0.154 0.181 0.273 0.232c0.084 0.038 0.178 0.072 0.279 0.102s0.208 0.051 0.317 0.063v0.463z" !default;
$svg-alert-error-fill:    "M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM13 17h-2v-2h2v2zM13 13h-2v-6h2v6z" !default;
$svg-alert-warning-fill:  "M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM13 17h-2v-2h2v2zM13 13h-2v-6h2v6z" !default;
$svg-alert-success-fill:  "M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM10 17l-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42-9 9z" !default;





////////////////////////////////////////////////////////////////////////////////
//                                                                            //
//                               W A R N I N G                                //
//                                                                            //
////////////////////////////////////////////////////////////////////////////////

/**
 * DO NOT EDIT ANYTHING BELOW
 * Private/framework-only reassignment.
 *
 * Reassigning `$inuit-global-spacing-unit` to `$global-unit` to make it
 * easier to type. Also adding a couple of in-between sizes.
 */
$global-unit:           $inuit-global-spacing-unit !default;
$global-unit-micro:     calc($inuit-global-spacing-unit-tiny/2) !default;
$global-unit-tiny:      $inuit-global-spacing-unit-tiny !default;
$global-unit-small:     $inuit-global-spacing-unit-small !default;
$global-unit-medium:    $inuit-global-spacing-unit + $inuit-global-spacing-unit-small !default;
$global-unit-large:     $inuit-global-spacing-unit-large !default;
$global-unit-xlarge:    $inuit-global-spacing-unit*3 !default;
$global-unit-huge:      $inuit-global-spacing-unit-huge !default;

$global-font-size:      $inuit-global-font-size !default;

$use-markup-fix:        false !default;

// An array of our global spacing suffixes used to auto-generate class names.
$global-spacing-sizes: (
  null:      $global-unit,
  '-micro':  $global-unit-micro,
  '-tiny':   $global-unit-tiny,
  '-small':  $global-unit-small,
  '-large':  $global-unit-large,
  '-xlarge': $global-unit-xlarge,
  '-huge':   $global-unit-huge,
  '-flush':  0px
) !default;
