﻿/* ==========================================================================
   #GRID
   ========================================================================== */

$c-form-field__label_color:       $neutral-heavy;

/**
    For when a table won't do.
*/

.c-grid {
    position: relative;
    display: block;

    @include mq($from: tablet) {
        display: table;
        table-layout: auto;
        width: 100%;
        border-collapse: collapse;
    }

}


.c-grid__row {
    display: block;
    @include clearfix();

    @include mq($from: tablet) {
        display: table-row;
        border: none;
    }

}


.c-grid__cell {
    display: inline-block;
    float: left;
    width: 33.333333%;
    margin-bottom: $global-unit-small;
    text-align: center;
    line-height: 1.15;

    @include mq($from: tablet) {
        display: table-cell;
        float: none;
        width: auto;
        margin-bottom: 0;
        vertical-align: middle;
        padding: $global-unit-small;
        border-bottom: 1px solid $global-border-color;
    }

}





/* Modifiers
   ========================================================================== */
.c-grid__row--thead {
    display: none;

    @include mq($from: tablet) {
        display: table-row;
    }

}


.c-grid__row--mobile-header {
    display: block;
    margin-bottom: $global-unit;
    border-bottom: 1px solid $global-border-color;

    @include mq($from: tablet) {
        display: none;
    }

    > .c-grid__cell {
        @include font-size(12px);
    }

}

.c-grid__cell--header {
    @include font-size(14px);
    white-space: nowrap;
}


.c-grid__cell--no-option {
    @include font-size(12px, 1.15);
}


.c-grid__cell--label {
    display: block;
    margin-bottom: $global-unit;
    width: 100%;
    float: none;
    text-align: left;
    color: $c-form-field__label_color;
    font-weight: $font-weight-medium;

    @include mq($from: tablet) {
        display: table-cell;
        width: auto;
        max-width: 360px;
        margin-bottom: 0;
    }

}
