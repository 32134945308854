/* ==========================================================================
   #POSITION
   ========================================================================== */
// Helper object for positioning components on the page
.o-position {
    position: relative;
    @include inuit-clearfix();
}

.o-position__object {
    @include mq($until: tablet) {
        display: block
    }

    @include mq($from: tablet) {
        position: absolute;
    }

}

.o-position--top-left {
    .o-position__object {
        @include mq($until: tablet) {
            text-align: left;
        }

        @include mq($from: tablet) {
            top: 0;
            left: 0;
            right: auto;
            bottom: auto;
        }
    }
}

.o-position--top-right {
    @include mq($until: tablet) {
        text-align: right;
    }

    .o-position__object {
        @include mq($from: tablet) {
            top: 0;
            left: auto;
            right: 0;
            bottom: auto
        }
    }
}